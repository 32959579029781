/* eslint-disable react/jsx-no-useless-fragment */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Provider as StoreProvider } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';

import { RoutesProvider } from '@/core/providers/RoutesProvider';
import { store } from '@/core/store/store';
import { MarkerIO } from '@/core/components/MarkerIO';

import theme from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyles';
import './styles/global.scss'

import reportWebVitals from './reportWebVitals';
import { AppInitialize } from './layouts';

import './core/i18n/config';
// Importing the firebase module will initialize the firebase app client
import './Firebase';
// STYLES
import './themes/fonts.css';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e525ec03fbd4e36568067cca36fa3f35@o4507566563065856.ingest.de.sentry.io/4507622357991504",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const markerIOProjectId = process.env.REACT_APP_MARKER_IO_ID;

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <MuiThemeProvider theme={theme}>
        <ScThemeProvider theme={theme}>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <GlobalStyle />
              <Suspense fallback={<></>}>
                <AppInitialize>
                  <RoutesProvider />
                  {!!markerIOProjectId && <MarkerIO />}
                </AppInitialize>
              </Suspense>
            </LocalizationProvider>
          </BrowserRouter>
        </ScThemeProvider>
      </MuiThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
