import { ObjectValues } from './helpers';

export const SteerCoNotesTag = 'SteerCoNotes' as const;

export type WPTemp = {
  number: string;
  name: string;
};

export type UserTemp = {
  id: string;
  name: string;
};

export type SteerCoNote = {
  id: number;
  projectId: string;
  attachments: string;
  description: string;
  details: string;
  dueDate: string;
  responsibleUserIds?: Array<string>;
  responsibleUsers?: Array<UserTemp>;
  status: string;
  type: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  workPackages: Array<string>;
  workPackagesTemp: Array<WPTemp>;
};

export type ListSteerCoNotesParams = {
  limit?: number;
  offset?: number;
  projectId?: string;
  type?: string;
  open?: boolean;
  orderBy?: keyof SteerCoNote;
  desc?: boolean;
  search?: string;
};

export const STEERCO_ENTRY_TYPE = {
  All: 'all',
  Measure: 'measure',
  Action: 'action',
  Decision: 'decision',
  Information: 'information',
} as const;

export type SteerCoNoteType = ObjectValues<typeof STEERCO_ENTRY_TYPE>;

export const Steerco_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  OFF_TRACK: 'off_track',
  SUPPORT_REQUIRED: 'support_required',
  //  ARCHIVED: 'archived',
} as const;
export type CreateSteerCoNote = {
  projectId: string;
  description: string;
  details: string;
  dueDate?: string;
  responsibleUserIds?: Array<string>;
  responsibleUsers?: string;
  status?: string;
  type: ObjectValues<typeof STEERCO_ENTRY_TYPE> | string;
  workPackages?: Array<string>;
  workPackagesTemp?: Array<WPTemp>;
};

export interface Item {
  id: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  projectId: number;
  attachments: string;
  details: string;
  dueDate: string;
  responsibleUserIds: Array<number>;
  status: string;
  type: string;
}

export interface ItemList {
  items: Array<Item>;
  totalCount: number;
}

export type GetSteerCoNote = Array<SteerCoNote>;

export type GetSteerCoNoteParams = {
  noteId: string;
};

export type DeleteSteerCoNote = {
  noteId: string;
};

export type UpdateSteerCoNote = {
  projectId: string;
  description: string;
  details: string;
  dueDate?: string;
  responsibleUserIds?: Array<string>;
  responsibleUsers?: string;
  status?: string;
  type: ObjectValues<typeof STEERCO_ENTRY_TYPE> | string;
  workPackages?: Array<string>;
  workPackagesTemp?: Array<WPTemp>;
};

export interface GroupedNotes {
  [date: string]: Array<Item>; // Replace YourNoteType with the actual type of your notes
}
