import { differenceInDays, differenceInHours } from 'date-fns';

/// /////////////////////////////////////////////////////////////////////
/// /                           ASYNC UTILS                          ////
/// /////////////////////////////////////////////////////////////////////

export const delayed = async (ms: number, cb: () => void) => {
  const result = await new Promise<void>((resolve) => {
    setTimeout(() => {
      cb();
      resolve();
    }, ms);
  });

  return result;
};

/// /////////////////////////////////////////////////////////////////////
/// /                           DATE UTILS                           ////
/// /////////////////////////////////////////////////////////////////////

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 week numbering.
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 *
 * 1st week contains 4-7 days of the new year
 * @param {Date} [date] - local date
 * @return {number} week number in ISO 8601 format
 * @example
 * weekNumber(new Date(2016, 0, 3, 12)) // Sun
 * //> 53
 * weekNumber(new Date(2016, 0, 4, 12)) // Mon
 * //> 1
 */
export const getWeekNumber = (date = new Date()): number => {
  const MINUTE = 60000;
  const WEEK = 604800000; // = 7 * 24 * 60 * 60 * 1000 = 7 days in ms

  /**
   * Get the difference in milliseconds between the timezone offsets of 2 dates
   */
  const tzDiff = (first: Date, second: Date) => (first.getTimezoneOffset() - second.getTimezoneOffset()) * MINUTE;

  // day 0 is monday
  const day = (date.getDay() + 6) % 7;
  // get thursday of present week
  const thursday = new Date(date);

  thursday.setDate(date.getDate() - day + 3);
  // set 1st january first
  const firstThursday = new Date(thursday.getFullYear(), 0, 1);

  // if Jan 1st is not a thursday...
  if (firstThursday.getDay() !== 4) {
    firstThursday.setMonth(0, 1 + ((11 /* 4 + 7 */ - firstThursday.getDay()) % 7));
  }

  const weekNumber =
    1 + Math.floor((thursday.valueOf() - firstThursday.valueOf() + tzDiff(firstThursday, thursday)) / WEEK);

  return weekNumber;
};

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 calendar weeks in a given year
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 */
export const weeksPerYear = (year: number) => {
  let weeks = getWeekNumber(new Date(year, 11, 31, 12));

  if (weeks === 1) {
    weeks = getWeekNumber(new Date(year, 11, 31 - 3, 12));
  }

  return weeks;
};

/**
 * To calculate the date of the start of a given ISO8601 week
 * (which will always be a Monday)
 *
 * @param week
 * @param year
 * @returns
 */
export const getDateOfISOWeek = (week: number, year: number): Date => {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;

  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

  return ISOweekStart;
};

interface CustomNavigator extends Navigator {
  standalone?: boolean;
}
const myNavigator: CustomNavigator = window.navigator as CustomNavigator;

export const checkIsIOS = () => {
  return (
    typeof myNavigator !== 'undefined' &&
    (/iPad|iPhone|iPod/.test(myNavigator.userAgent) ||
      (myNavigator.platform === 'MacIntel' && typeof myNavigator.standalone !== 'undefined'))
  );
};

export const getViewport = () => {
  return {
    width: typeof window !== 'undefined' ? window.innerWidth : null,
    height: typeof window !== 'undefined' ? window.innerHeight : null,
  };
};

export const getOrientation = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  return window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
};

export const arrayTranspose = <T>(matrix: Array<Array<T>>) => {
  return matrix.length ? matrix[0].map((_col, i) => matrix.map((row) => row[i])) : [];
};

export const EmailRegExp = /^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
export const PasswordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&])[A-Za-z\d.\-@$!%*#?&]{8,64}$/;

export const getUserNameInitials = (fullname: string) => {
  const splittedNames = fullname.split(' ');
  let initials = splittedNames[0].substring(0, 1).toUpperCase();

  if (splittedNames.length > 1) {
    initials += splittedNames[splittedNames.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

// Sets the due date's time to 18:00
export const businessDaysToDueDate = (dueDate: string | number | Date) => {
  return differenceInDays(new Date(dueDate).setHours(18, 0, 0, 0), Date.now());
};

export const businessHoursToDueDate = (dueDate: string | number | Date) => {
  return differenceInHours(new Date(dueDate).setHours(18, 0, 0, 0), Date.now());
};

/// /////////////////////////////////////////////////////////////////////
/// /                           STYLING UTILS                        ////
/// /////////////////////////////////////////////////////////////////////

export const hexToHSL = (hex: string): string => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = Number(`0x${hex[1]}${hex[1]}`);
    g = Number(`0x${hex[2]}${hex[2]}`);
    b = Number(`0x${hex[3]}${hex[3]}`);
  } else if (hex.length === 7) {
    r = Number(`0x${hex[1]}${hex[2]}`);
    g = Number(`0x${hex[3]}${hex[4]}`);
    b = Number(`0x${hex[5]}${hex[6]}`);
  }

  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return `${h},${s}%,${l}%`;
};

export function validateDomain(email: string, domains: string | Array<string>) {
  // If the email is blank, return true
  if (email === '') {
    return true;
  }

  let isValid = false;
  const domainList = Array.isArray(domains) ? domains : [domains];

  for (const domain of domainList) {
    if (email.endsWith(`@${domain}`)) {
      /* eslint-disable no-control-regex */
      isValid =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
          email,
        );
      /* eslint-enable no-control-regex */
      if (isValid) break;
    }
  }

  return isValid;
}

// utils.ts

export function headersToObject(headers: Headers): { [key: string]: string } {
  const obj: { [key: string]: string } = {};
  headers.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

export async function fetchImageWithHeaders(url: string, headers: { [key: string]: string }) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    throw error;
  }
}

export function getInitials(fullName: string): string {
  const words = fullName.replace(/(\r\n|\n|\r)/gm, '').split(' ');

  const initials = words.map((word: string) => word[0]);

  return initials.join('');
}

////////////////////////////////////////////////////////////////////////
////                           Math UTILS                           ////
////////////////////////////////////////////////////////////////////////

export function getLinearInterpolation(y: number, minY: number, maxY: number, minX: number, maxX: number): number {
  if (y <= minY) return minX;
  if (y >= maxY) return maxX;

  return minX + (maxX - minX) * ((y - minY) / (maxY - minY));
}
