import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { camelCase, snakeCase } from 'change-case';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { CompaniesTag } from '@/core/interfaces/Companies';
import { ProjectsTag } from '@/core/interfaces/Projects';
import { KpisTag, KpiMeasureTag } from '@/core/interfaces/Kpis';
import { TargetsTag } from '@/core/interfaces/Targets';
import { WeekProgressTag, WorkPackagesTag } from '@/core/interfaces/WorkPackages';
import { SteerCoNotesTag } from '@/core/interfaces/SteerCoNotes';
import { UserManagementTag } from '@/core/interfaces/UserManagement';
import { caseConverter } from '@/utils/case-converter';
import authContext from '@/core/context/authContext';

import { MeasuresTag } from '../interfaces/Measures';

import { API_URL } from '@/config';

type PrefetchOptions = { ifOlderThan?: false | number } | { force?: boolean };
type Prefetch = (endpointName: string, arg: any, options: PrefetchOptions) => ThunkAction<void, any, any, AnyAction>;

/**
 * @TODO: This needs to be properly typed. The response from `baseQuery` has to take a generic so we can type its response data.
 */
const parseDataResponse = (data: any) => {
  return caseConverter(data, camelCase);
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    const accessToken = authContext.token;

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const customBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const response = await baseQuery(
    {
      ...args,
      body: caseConverter(args.body, snakeCase),
      ...(args.params && { params: caseConverter(args.params, snakeCase) as { [key: string]: string | number } }),
    },
    api,
    extraOptions,
  );

  if (response.data) {
    response.data = parseDataResponse(response.data);
  }

  return response;
};

export const apiSlice = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'api',
  tagTypes: [
    CompaniesTag,
    ProjectsTag,
    KpisTag,
    KpiMeasureTag,
    TargetsTag,
    MeasuresTag,
    WorkPackagesTag,
    WeekProgressTag,
    SteerCoNotesTag,
    UserManagementTag,
  ],
  endpoints: () => ({}),
});

export const prefetch = apiSlice.util.prefetch as Prefetch;

export const {
  util: { getRunningOperationPromises, resetApiState },
} = apiSlice;
