export const paths = {
  signIn: '/login',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  admin: {
    projectsList: '/projects',
    projectCreate: '/projects/create',
    companiesList: '/companies',
    usersList: '/users',
  },
  project: {
    index: '/project/:projectId',
    tabs: {
      overview: '/project/:projectId/overview',
      kanban: '/project/:projectId/kanban',
      rolesAndResponsibilities: '/project/:projectId/roles-and-responsibilities',
      about: '/project/:projectId/about',
      steerco: {
        list: '/project/:projectId/steerco/notes/list',
        create: '/project/:projectId/steerco/notes/create',
        update: '/project/:projectId/steerco/notes/update',
        delete: '/project/:projectId/steerco/notes/delete',
      },
      previousReports: '/project/:projectId/previous-reports',
      deEscalatedWp: '/project/:projectId/de-escalated-wp',
      attachments: '/project/:projectId/attachments',
    },
  },
  workPackages: {
    index: '/project/:projectId/wps/:wpId',
    tabs: {
      overview: '/project/:projectId/wps/:wpId/overview',
      targets: '/project/:projectId/wps/:wpId/targets',
      kpis: {
        list: '/project/:projectId/wps/:wpId/kpis/list',
        create: '/project/:projectId/wps/:wpId/kpis/create/step/:stepNumber',
        edit: '/project/:projectId/wps/:wpId/kpis/:kpiId/edit/step/:stepNumber',
      },
      measures: '/project/:projectId/wps/:wpId/measures',
      about: '/project/:projectId/wps/:wpId/about',
      attachments: '/project/:projectId/wps/:wpId/attachments',
    },
  },
};

export const guestPaths = [paths.signIn, paths.signUp, paths.resetPassword];
