import styled from 'styled-components';

import {
  DESKTOP_DRAWER_EXPANDED_WIDTH,
  DRAWER_TRANSITION_DELAY_MS,
} from '@/core/components/Navigation/Navigation.constants';
import { colors } from '@/themes/colorsV2';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: ${colors.grey.extraLight};
`;

export const ContentWrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex: auto;
  width: calc(100% - ${DESKTOP_DRAWER_EXPANDED_WIDTH}px);
  min-height: calc(100vh - 88px);
  transition: all ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;
