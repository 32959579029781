import { Box } from '@mui/material';
import React, { useState } from 'react';

import { ObjectValues } from '@/core/interfaces/helpers';
import { BackendFileUploadPart } from '@/core/componentsv2/BackendFileUpload/BackendFileUploadPart';

export const UPLOAD_TYPE = {
  LOGO: 'logo',
  WP_ONBOARDING_DOCUMENT: 'wp_onboarding_document',
  MEASURE_SUPPORTING_DOCUMENT: 'measure_supporting_document',
  KPI_STATIC_IMAGE: 'kpi_static_image',
  KPI_MEASURE_SUPPORTING_DOCUMENT: 'kpi_measure_supporting_document',
  PROJECT: 'project_document',
} as const;

export type UploadType = ObjectValues<typeof UPLOAD_TYPE>;

type BackendFileUploadProps = {
  fileTypes?: Array<string>;
  onChange: (downloadUrl: string, file: File) => void;
  maxFileSize?: number;
  uploadType: UploadType;
  error?: boolean;
  location: string;
  id?: string;
};

export const BackendFileUpload = ({
                                    fileTypes = ['PNG', 'SVG'],
                                    maxFileSize = 0.254,
                                    uploadType,
                                    error,
                                    onChange,
                                    location,
                                    id,
                                  }: BackendFileUploadProps) => {
  const [count, setCount] = useState(1);

  const onChangeT = async (downloadUrl: string, file: File) => {
    setCount(count + 1);
    onChange(downloadUrl, file);
  };

  const uploadParts = [...Array(count)].map((_, i) => (
    <BackendFileUploadPart
      key={i}
      fileTypes={fileTypes}
      uploadType={uploadType}
      onChange={onChangeT}
      location={location}
      id={id}
      error={error}
      maxFileSize={maxFileSize}
    />
  ));

  return <Box>{uploadParts.reverse()}</Box>;
};

BackendFileUpload.defaultProps = {
  fileTypes: ['PNG', 'SVG'],
  maxFileSize: 0.254,
  error: undefined,
  id: undefined,
};
