import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { TextField } from '@/core/components/TextField';
import { Select } from '@/core/components/Select';
import { CompanyType, CreateCompany } from '@/core/interfaces/Companies';
import { UPLOAD_TYPE } from '@/core/components/FirebaseFileUpload';
import { useGetCompaniesValuesQuery } from '@/core/store/companiesSlice';

import * as S from './AddCompanyForm.styles';
import { BackendFileUpload } from "@/core/componentsv2/BackendFileUpload";

interface AddCompanyFormProps {
  onSubmit: (data: CreateCompany) => void;
  onCancel: () => void;
}

export const AddCompanyForm = ({ onSubmit, onCancel }: AddCompanyFormProps) => {
  const [showDuplicatesBox, setShowDuplicatesBox] = useState<boolean>(false);
  const { control, handleSubmit, watch } = useForm<CreateCompany>({
    defaultValues: { name: '', type: undefined, isClient: false },
  });
  const { t } = useTranslation('Companies');
  const { t: commonT } = useTranslation('Common');
  const watchName = watch('name');
  const { data: duplicates } = useGetCompaniesValuesQuery(
    { attribute: ['name'], search: watchName },
    { skip: watchName.length < 3 },
  );

  const companyTypes = [
    {
      value: CompanyType.Oem,
      label: t('addForm.oemOption'),
    },
    {
      value: CompanyType.Supplier,
      label: t('addForm.supplierOption'),
    },
  ];

  useEffect(() => {
    if (duplicates && duplicates.length > 0) {
      setShowDuplicatesBox(true);
    } else {
      setShowDuplicatesBox(false);
    }
  }, [duplicates]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.FieldRow>
        <Controller
          name="name"
          rules={{
            required: commonT('form.required') as string,
          }}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label={t('addForm.nameLabel')}
              placeholder={t('addForm.companyNamePlaceholder') as string}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {showDuplicatesBox && (
          <S.DuplicatesBox
            onClose={() => {
              setShowDuplicatesBox(false);
            }}
          >
            <Typography variant="body2-bold">{t('addForm.duplicatesBoxTitle')}</Typography>
            <S.DuplicatesList>
              {duplicates?.map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </S.DuplicatesList>
          </S.DuplicatesBox>
        )}
      </S.FieldRow>
      <S.FieldRow>
        <Controller
          name="type"
          rules={{
            required: commonT('form.required') as string,
          }}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              fullWidth
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              label={t('addForm.companyTypeLabel')}
              displayEmpty
              renderValue={(selected) => {
                const option = companyTypes.find(({ value }) => value === selected);

                if (!option) {
                  return <S.PlaceholderValue>{t('addForm.companyTypePlaceholder')}</S.PlaceholderValue>;
                }

                return option.label;
              }}
            >
              {companyTypes.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </S.FieldRow>
      <S.FieldRow>
        <Controller
          name="isClient"
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox {...field} />} label={t('addForm.isClientLabel')} />
          )}
        />
      </S.FieldRow>
      <S.FieldRow>
        <Box mt={4} mb={1}>
          <Typography variant="subtitle2-bold">{t('addForm.uploadLogoTitle')}</Typography>
        </Box>
        <Controller
          name="logo"
          control={control}
          render={({ field }) => <BackendFileUpload onChange={field.onChange} uploadType={UPLOAD_TYPE.LOGO} location={'COMPANY'} />}
        />
      </S.FieldRow>
      <S.ButtonsRow>
        <Button type="submit">{t('addModal.addButton')}</Button>
        <Button type="button" variant="outlined" onClick={onCancel}>
          {t('addModal.cancelButton')}
        </Button>
      </S.ButtonsRow>
    </form>
  );
};
