import { Nullable, PaginatedData } from '@/core/interfaces/helpers';

export const TargetsTag = 'Targets' as const;

export type TargetForm = {
  comment: Nullable<string>;
  description: string;
  dueDate: Nullable<string>;
  value: Nullable<string>;
  enabledDueDate: boolean;
};

export type CreateTarget = Omit<TargetForm, 'enabledDueDate'> & {
  workPackageId: string;
};

export type UpdateTarget = CreateTarget;

export type GetTargetItem = CreateTarget & {
  id: string;
  status: string;
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
};

export type GetTargetsListParams = {
  limit?: number;
  offset?: number;
  orderBy?: string;
};

export type GetTargetsList = PaginatedData<GetTargetItem>;

export type GetTargetDetails = {
  id: string;
  comment: string;
  description: string;
  done: boolean;
  dueDate: Nullable<string>;
  dueDates: Nullable<Array<string>>;
  value: Nullable<string>;
  createdBy: {
    id: string;
    name: string;
  };
  updatedBy: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type GetTargetsSnapshotParams = {
  limit?: number;
  offset?: number;
  orderBy?: string;
};

export type GetTargetsSnapshot = PaginatedData<GetTargetItem>;

export type MarkTargetAsDone = {
  workPackageId: string;
  id: string;
  done: boolean;
};

export type MarkTargetAsUndone = {
  workPackageId: string;
  id: string;
  done: boolean;
};
