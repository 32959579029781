const PlanningIcon = (props: any) => {
  return (
    <div style={{ width: '18px', height: '16px' }}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 18 16"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m17.3333 14.6667c0 .4602-.3731.8333-.8333.8333h-15.00004c-.46023 0-.833334-.3731-.833334-.8333v-13.33337c0-.46023.373104-.83333.833334-.83333h15.00004c.4602 0 .8333.3731.8333.83333zm-9.16667-4.1667h-5.83334v3.3333h5.83334zm7.49997-3.33333h-5.83331v6.66663h5.83331zm-7.49997-5h-5.83334v6.66666h5.83334zm7.49997 0h-5.83331v3.33333h5.83331z"
          fill="#7f8185"
        />
      </svg>
    </div>
  );
};

export default PlanningIcon;
