export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    desktop: 1920,
    laptop: 1366,
    tabletBig: 1024,
    tabletSmall: 768,
    mobile: 0,
  },
};
