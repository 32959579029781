import { camelCase, snakeCase } from 'change-case';

type CaseConverterPrimitive = string | number | boolean | undefined;

interface CaseConverterObject {
  [key: string]: CaseConverterPrimitive | Array<CaseConverterPrimitive | CaseConverterObject> | CaseConverterObject;
}

type CaseConverterArray = Array<CaseConverterPrimitive | CaseConverterObject | FormData | CaseConverterArray>;

type CaseConverterInput = CaseConverterPrimitive | CaseConverterObject | FormData | CaseConverterArray;

type ToCase = typeof camelCase | typeof snakeCase;

export function caseConverter(input: CaseConverterInput, toCase: ToCase): CaseConverterInput {
  return convert(input, toCase);
}

function convert(input: CaseConverterInput, toCase: ToCase): CaseConverterInput {
  if (
    typeof input === 'string' ||
    typeof input === 'number' ||
    typeof input === 'boolean' ||
    input === null ||
    input === undefined
  ) {
    return input;
  }

  if (input instanceof FormData) {
    const convertedFormData = new FormData();

    for (const [key, value] of input.entries()) {
      convertedFormData.append(toCase(key), value);
    }

    return convertedFormData;
  }

  if (Array.isArray(input)) {
    return input.map((item) => convert(item, toCase)) as CaseConverterArray;
  }

  const convertedObj: CaseConverterObject = {};

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      // Check if the key is a UUID
      const isUUID = key.match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/);
      // If it's a UUID, keep the key unchanged
      const newKey = isUUID ? key : toCase(key);
      // Convert the value recursively
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      convertedObj[newKey] = convert(input[key], toCase);
    }
  }

  return convertedObj;
}
