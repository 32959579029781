const ProjectsIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.00008 5.83333V3.33333C5.00008 2.8731 5.37318 2.5 5.83341 2.5H11.1786L12.8452 4.16667H17.5001C17.9603 4.16667 18.3334 4.53977 18.3334 5V13.3333C18.3334 13.7936 17.9603 14.1667 17.5001 14.1667H15.0001V16.6667C15.0001 17.1269 14.627 17.5 14.1667 17.5H2.50008C2.03985 17.5 1.66675 17.1269 1.66675 16.6667V6.66667C1.66675 6.20643 2.03985 5.83333 2.50008 5.83333H5.00008ZM5.00008 7.5H3.33341V15.8333H13.3334V14.1667H5.00008V7.5ZM6.66675 4.16667V12.5H16.6667V5.83333H12.1549L10.4882 4.16667H6.66675Z"
          fill="#84888E"
        />
      </svg>
    </div>
  );
};

export default ProjectsIcon;
