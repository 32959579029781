import { Alert, AlertProps } from '@mui/material';
import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, UploadTaskSnapshot } from 'firebase/storage';
import uuid4 from 'uuid4';
import { useTranslation } from 'react-i18next';

import { FileUpload } from '@/core/components/FileUpload';
import { ObjectValues } from '@/core/interfaces/helpers';
import { formatBytes } from '@/utils/utils';

import styles from './FirebaseFileUpload.module.scss';

export const UPLOAD_TYPE = {
  LOGO: 'logo',
  WP_ONBOARDING_DOCUMENT: 'wp_onboarding_document',
  MEASURE_SUPPORTING_DOCUMENT: 'measure_supporting_document',
  KPI_STATIC_IMAGE: 'kpi_static_image',
  KPI_MEASURE_SUPPORTING_DOCUMENT: 'kpi_measure_supporting_document',
  PROJECT: 'project_document',
} as const;

export type UploadType = ObjectValues<typeof UPLOAD_TYPE>;

const getFirebaseStorageUrl = (type: UploadType, fileExtension: string) => {
  const id = uuid4();
  const dirMap = {
    [UPLOAD_TYPE.LOGO]: 'Uploads/Company/',
    [UPLOAD_TYPE.WP_ONBOARDING_DOCUMENT]: 'Uploads/WorkPackage/',
    [UPLOAD_TYPE.MEASURE_SUPPORTING_DOCUMENT]: 'Uploads/WorkPackage/Measures/',
    [UPLOAD_TYPE.KPI_STATIC_IMAGE]: 'Uploads/KPI/',
    [UPLOAD_TYPE.KPI_MEASURE_SUPPORTING_DOCUMENT]: 'Uploads/KPI/Measures/',
    [UPLOAD_TYPE.PROJECT]: 'Uploads/Projects',
  } as const;
  const filenameMap = {
    [UPLOAD_TYPE.LOGO]: 'logo',
    [UPLOAD_TYPE.WP_ONBOARDING_DOCUMENT]: 'onboarding_document',
    [UPLOAD_TYPE.MEASURE_SUPPORTING_DOCUMENT]: 'supporting_document',
    [UPLOAD_TYPE.KPI_STATIC_IMAGE]: 'kpi',
    [UPLOAD_TYPE.KPI_MEASURE_SUPPORTING_DOCUMENT]: 'supporting_document',
    [UPLOAD_TYPE.PROJECT]: 'project_document',
  } as const;

  return `${dirMap[type]}[${id}]${filenameMap[type]}.${fileExtension}`;
};

type FirebaseFileUploadProps = {
  fileTypes?: Array<string>;
  onChange: (downloadUrl: string, file: File) => void;
  maxFileSize?: number;
  uploadType: UploadType;
  error?: boolean;
};

export const FirebaseFileUpload = ({
  fileTypes = ['SVG', 'PNG'],
  maxFileSize = 0.254,
  onChange,
  uploadType,
  error,
}: FirebaseFileUploadProps) => {
  const { t } = useTranslation('Common');
  const maxFileSizeBytes = maxFileSize * 1000000;

  const storage = getStorage(undefined, process.env.REACT_APP_FIREBASE_TEMPORARY_STORAGE_URL);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<{ message: string } & Pick<AlertProps, 'severity'>>();

  const uploadToFirebase = (file: File) => {
    const fileExtension = file.name.split('.').reverse()[0];
    const storageUrl = getFirebaseStorageUrl(uploadType, fileExtension);
    const storageRef = ref(storage, storageUrl);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot: UploadTaskSnapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        setUploadProgress(progress);
      },
      (storageError) => {
        setAlertMessage({
          severity: 'error',
          message: storageError.message,
        });
      },
      async () => {
        setAlertMessage({
          severity: 'success',
          message: t('firebaseImageUploader.successMessage', { fileName: file.name }),
        });
        onChange(storageUrl, file);
      },
    );
  };

  const handleChange = async (file: File) => {
    setAlertMessage(undefined);
    uploadToFirebase(file);

    return true;
  };

  return (
    <div className={styles.fileUploadContainer}>
      <FileUpload
        fileTypes={fileTypes}
        maxFileSize={maxFileSize}
        uploadProgress={uploadProgress}
        onChange={handleChange}
        onSizeError={() => {
          setAlertMessage({
            severity: 'warning',
            message: t('firebaseImageUploader.incorrectSizeError', { size: formatBytes(maxFileSizeBytes) }),
          });
        }}
        onTypeError={() => {
          setAlertMessage({
            severity: 'warning',
            message: t('firebaseImageUploader.incorrectFormatError', {
              formats: fileTypes.join(` ${t('firebaseImageUploader.or')} `),
            }),
          });
        }}
        error={error}
      />
      {alertMessage && <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>}
    </div>
  );
};

FirebaseFileUpload.defaultProps = {
  fileTypes: ['SVG', 'PNG'],
  maxFileSize: 0.254,
  error: undefined,
};
