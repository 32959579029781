import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  width: 100%;
  height: 100%;
  margin: 0 auto;

  text-align: center;

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    max-width: 592px;
    align-items: flex-start;
    text-align: left;
  }
`;

export const Row = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
