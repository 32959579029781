import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

import { colors } from '@/themes/colors';

export const Figure = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-svg {
    color: ${colors.primary[500]};
  }
`;

export const Loader = styled(CircularProgress)<{ width?: number | string; height?: number | string }>`
  && {
    color: inherit;
  }
`;
