import React from 'react';
import styled from 'styled-components';

import { SkeletonLoading } from '@/stories/Primitives/SkeletonLoading/SkeletonLoading';

const ListsSkeleton = () => {
  return (
    <ListContainer>
      <TopPart>
        <Header>
          <FrameContainer>
            <TopTags>
              <SkeletonLoading variant="rounded" width={41} height={24} colorVariant="secondary" />
              <Spacer width={8} />
              <SkeletonLoading variant="rounded" width={132} height={24} colorVariant="secondary" />
            </TopTags>
            <Spacer height={8} />
            <AvatarGroup>
              <SkeletonLoading variant="circular" width={32} height={32} colorVariant="secondary" />
              <OverlapAvatar>
                <SkeletonLoading variant="circular" width={32} height={32} colorVariant="secondary" />
              </OverlapAvatar>
            </AvatarGroup>
          </FrameContainer>
          <SkeletonLoading variant="rounded" width={328} height={92} colorVariant="primary" />
        </Header>
        <Spacer height={16} />
        <Divider />
        <Spacer height={16} />
        <TopKPIs>
          <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
          <Spacer height={16} />
          <KPIValues>
            <SkeletonLoading variant="rounded" width={224} height={36} colorVariant="primary" />
            <Spacer width={8} />
            <SkeletonLoading variant="rounded" width={112} height={36} colorVariant="primary" />
            <Spacer width={8} />
            <SkeletonLoading variant="rounded" width={174} height={36} colorVariant="primary" />
          </KPIValues>
        </TopKPIs>
      </TopPart>

      <Spacer height={16} />
      <TimePart>
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
        <Spacer />
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
      </TimePart>
    </ListContainer>
  );
};

// Styled Components
const ListContainer = styled.div`
  width: 100%;
  minwidth: 1144px;
  height: 272px;
  padding: 16px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
`;

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 181px;
  height: 92px;
  justify-content: space-between;
`;

const TopTags = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const AvatarGroup = styled.div`
  width: 64px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const OverlapAvatar = styled.div`
  position: absolute;
  left: 5px;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
`;

const TopKPIs = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: column;
`;

const KPIValues = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const TimePart = styled.div`
  height: 16px;
  display: flex;
  justify-content: space-between;
`;

const Spacer = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

export default ListsSkeleton;
