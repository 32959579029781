const CompanyIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.4999 15.8333H19.1666V17.5H0.833252V15.8333H2.49992V3.33333C2.49992 2.8731 2.87302 2.5 3.33325 2.5H11.6666C12.1268 2.5 12.4999 2.8731 12.4999 3.33333V15.8333H15.8333V9.16667H14.1666V7.5H16.6666C17.1268 7.5 17.4999 7.8731 17.4999 8.33333V15.8333ZM4.16659 4.16667V15.8333H10.8333V4.16667H4.16659ZM5.83325 9.16667H9.16659V10.8333H5.83325V9.16667ZM5.83325 5.83333H9.16659V7.5H5.83325V5.83333Z"
          fill="#7F8185"
        />
      </svg>
    </div>
  );
};

export default CompanyIcon;
