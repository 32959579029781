import React, { FC } from 'react';

import { colors } from '@/themes/colorsV2';

type Props = {
  width?: string;
  height?: string;
  fill?: string;
};

const FigmaSearchIcon: FC<Props> = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M12.0206 11.0778L14.8757 13.9329L13.9329 14.8757L11.0778 12.0206C10.0512 12.8419 8.74925 13.3333 7.33325 13.3333C4.02125 13.3333 1.33325 10.6453 1.33325 7.33325C1.33325 4.02125 4.02125 1.33325 7.33325 1.33325C10.6453 1.33325 13.3333 4.02125 13.3333 7.33325C13.3333 8.74925 12.8419 10.0512 12.0206 11.0778ZM10.6831 10.5831C11.4983 9.74299 11.9999 8.59699 11.9999 7.33325C11.9999 4.75492 9.91159 2.66659 7.33325 2.66659C4.75492 2.66659 2.66659 4.75492 2.66659 7.33325C2.66659 9.91159 4.75492 11.9999 7.33325 11.9999C8.59699 11.9999 9.74299 11.4983 10.5831 10.6831L10.6831 10.5831Z"
        fill={fill}
      />
    </svg>
  );
};

FigmaSearchIcon.defaultProps = {
  width: '18px',
  height: '18px',
  fill: colors.grey.default,
};

export default FigmaSearchIcon;
