import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import React, { ComponentPropsWithoutRef, useEffect, useState } from 'react'

import * as S from './FirebaseImage.styles'

type FirebaseImageProps = ComponentPropsWithoutRef<'img'>

export const FirebaseImage = ({ src, alt, width, height, ...props }: FirebaseImageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState<string>()
  const storage = getStorage()

  useEffect(() => {
    const fetchDownloadURL = async () => {
      const url = await getDownloadURL(ref(storage, src))

      setIsLoading(false)
      setImageUrl(url)
    }

    if (src) {
      fetchDownloadURL().catch(() => setIsLoading(false))
    }
  }, [src, storage])

  if (!src) {
    return null
  }

  return (
    <S.Figure>
      {isLoading ? (
        <S.Loader size={width} />
      ) : (
        <img src={imageUrl} alt={alt} width={width} height={height} {...props} />
      )}
    </S.Figure>
  )
}
