const FolderInactiveIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3452 4.16667H17.5001C17.9603 4.16667 18.3334 4.53977 18.3334 5V16.6667C18.3334 17.1269 17.9603 17.5 17.5001 17.5H2.50008C2.03985 17.5 1.66675 17.1269 1.66675 16.6667V3.33333C1.66675 2.8731 2.03985 2.5 2.50008 2.5H8.67858L10.3452 4.16667ZM3.33341 5.83333V15.8333H16.6667V5.83333H3.33341Z"
          fill="#7F8185"
        />
      </svg>
    </div>
  );
};

export default FolderInactiveIcon;
