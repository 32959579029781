import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

// EN namespaces
import CommonEN from './en/Common.json';
import SignInEN from './en/SignIn.json';
import SignUpEN from './en/SignUp.json';
import ResetPasswordEN from './en/ResetPassword.json';
import AdminEN from './en/Admin.json';
import SettingsEN from './en/Settings.json';
import EmailVerificationEN from './en/EmailVerification.json';
import ProjectsEN from './en/Projects.json';
import WorkPackagesEN from './en/WorkPackages.json';
import KPIEN from './en/KPI.json';
import CompaniesEN from './en/Companies.json';
// DE namespaces
import CommonDE from './de/Common.json';
import SignInDE from './de/SignIn.json';
import SignUpDE from './de/SignUp.json';
import ResetPasswordDE from './de/ResetPassword.json';
import AdminDE from './de/Admin.json';
import SettingsDE from './de/Settings.json';
import EmailVerificationDE from './de/EmailVerification.json';
import ProjectsDE from './de/Projects.json';
import WorkPackagesDE from './de/WorkPackages.json';
import KPIDE from './de/KPI.json';
import CompaniesDE from './de/Companies.json';

export const LANGUAGES = {
  EN: 'en',
  DE: 'de',
};

export const resources = {
  en: {
    Common: CommonEN,
    SignIn: SignInEN,
    SignUp: SignUpEN,
    ResetPassword: ResetPasswordEN,
    Admin: AdminEN,
    Settings: SettingsEN,
    EmailVerification: EmailVerificationEN,
    Projects: ProjectsEN,
    WorkPackages: WorkPackagesEN,
    KPI: KPIEN,
    Companies: CompaniesEN,
  },
  de: {
    Common: CommonDE,
    SignIn: SignInDE,
    SignUp: SignUpDE,
    ResetPassword: ResetPasswordDE,
    Admin: AdminDE,
    Settings: SettingsDE,
    EmailVerification: EmailVerificationDE,
    Projects: ProjectsDE,
    WorkPackages: WorkPackagesDE,
    KPI: KPIDE,
    Companies: CompaniesDE,
  },
};

i18next.use(initReactI18next).init({
  lng: Cookies.get('locale') || LANGUAGES.EN,
  fallbackLng: LANGUAGES.EN,
  debug: true,
  resources,
});

export default i18next;
