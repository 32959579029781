import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';

import { apiSlice } from '@/core/store/apiSlice';
import {
  CreateWorkPackage,
  GetWeekProgress,
  GetWeekProgressParams,
  GetWorkPackage,
  GetWorkPackageParams,
  UpdateWorkPackage,
  GetSnapshot,
  GetSnapshotParams,
  GetWpUsers,
  GetWpUsersParams,
  GetExport,
  GetExportParams,
  WeekProgressTag,
  WorkPackagesTag,
  GetNewWorkPackage,
  CreateNewWorkPackage,
  UpdateNewWorkPackage,
} from '@/core/interfaces/WorkPackages';
import { ProjectsTag } from '@/core/interfaces/Projects';
import { RootState } from '@/core/store/store';
export const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkPackage: builder.query<GetWorkPackage, GetWorkPackageParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/`,
        method: 'GET',
        params,
      }),
      providesTags: (result) => [WorkPackagesTag, { type: WorkPackagesTag, id: result?.id }],
    }),
    getNewWorkPackage: builder.query<GetNewWorkPackage, GetWorkPackageParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/`,
        method: 'GET',
        params,
      }),
      providesTags: (result) => [WorkPackagesTag, { type: WorkPackagesTag, id: result?.id }],
    }),
    createWorkPackage: builder.mutation<GetWorkPackage, CreateWorkPackage>({
      query: (body) => ({
        url: '/v2/work-packages/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
    createNewWorkPackage: builder.mutation<GetNewWorkPackage, CreateNewWorkPackage>({
      query: (body) => ({
        url: '/v2/work-packages/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
    updateWorkPackage: builder.mutation<GetWorkPackage, GetWorkPackageParams & UpdateWorkPackage>({
      query: ({ workPackageId, ...body }) => ({
        url: `/v2/work-packages/${workPackageId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
    updateNewWorkPackage: builder.mutation<GetNewWorkPackage, GetWorkPackageParams & UpdateNewWorkPackage>({
      query: ({ workPackageId, ...body }) => ({
        url: `/v2/work-packages/${workPackageId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
    updateWorkPackageStatus: builder.mutation<GetWorkPackage, GetWorkPackageParams & UpdateWorkPackage>({
      query: ({ workPackageId, ...body }) => ({
        url: `/v2/work-packages/${workPackageId}/statuses`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
    getWeekProgress: builder.query<GetWeekProgress, GetWeekProgressParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/week-progress/`,
        method: 'GET',
        params,
      }),
      providesTags: () => [WeekProgressTag],
    }),
    addWeekProgress: builder.mutation<GetWorkPackage, { workPackageId: string; details: string }>({
      query: ({ workPackageId, ...body }) => ({
        url: `/v2/work-packages/${workPackageId}/week-progress/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag, WeekProgressTag],
    }),
    getSnapshot: builder.query<GetSnapshot, GetSnapshotParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/snapshots/`,
        method: 'GET',
        params,
      }),
      providesTags: (result) => [WorkPackagesTag, { type: WorkPackagesTag, id: result?.id }],
    }),
    archiveWp: builder.mutation<GetWorkPackage, { workPackageId: string }>({
      query: ({ workPackageId }) => ({
        url: `/v2/work-packages/${workPackageId}/archive/`,
        method: 'PATCH',
      }),
      invalidatesTags: [WorkPackagesTag],
    }),
    unarchiveWp: builder.mutation<GetWorkPackage, { workPackageId: string }>({
      query: ({ workPackageId }) => ({
        url: `/v2/work-packages/${workPackageId}/unarchive/`,
        method: 'PATCH',
      }),
      invalidatesTags: [WorkPackagesTag],
    }),
    getWpUsers: builder.query<GetWpUsers, GetWpUsersParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/users/`,
        method: 'GET',
        params,
      }),
      providesTags: () => [WorkPackagesTag],
    }),
    getExport: builder.query<GetExport, GetExportParams>({
      query: ({ workPackageId, ...params }) => ({
        url: `/v2/work-packages/${workPackageId}/export?format=powerpoint/`,
        method: 'GET',
        params,
      }),
    }),
    getExportTemplate: builder.query<GetExport, { workPackageId: string }>({
      query: ({ workPackageId }) => ({
        url: `/v2/work-packages/${workPackageId}/export?format=powerpoint`,
        responseHandler: async (response) => {
          console.log('Response received:', response);

          if (!response.ok) {
            console.error('Error downloading template:', response.statusText);
            // Handle error, show a message, etc.
            return null;
          }

          const hiddenElement = document.createElement('a');
          const file = window.URL.createObjectURL(await response.blob());

          hiddenElement.href = file;
          hiddenElement.target = '_blank';
          hiddenElement.download = `template_${Date.now()}.pptx`;
          hiddenElement.click();

          return null;
        },
        cache: 'no-cache',
      }),
      forceRefetch: () => true,
    }),
    submitStatusReport: builder.mutation<
      void,
      { projectId: string; wpId: number; highlights: string; roadblocks: string; nextSteps: string }
    >({
      query: ({ projectId, wpId, highlights, roadblocks, nextSteps }) => ({
        url: `/v2/projects/${projectId}/report/`,
        method: 'POST',
        body: { wpId, highlights, roadblocks, nextSteps },
      }),
      invalidatesTags: [WorkPackagesTag, ProjectsTag],
    }),
  }),
});

interface WorkPackageAddedState {
  workPackageAdded: boolean;
}

const initialState: WorkPackageAddedState = {
  workPackageAdded: false,
};

export const workPackageAddedSlice = createSlice({
  name: 'workPackageAdded',
  initialState,
  reducers: {
    setWorkPackageAdded: (state, action: PayloadAction<boolean>) => {
      state.workPackageAdded = action.payload;
    },
    resetWorkPackageAdded: (state) => {
      state.workPackageAdded = false;
    },
  },
});

export const {
  useGetWorkPackageQuery,
  useGetNewWorkPackageQuery,
  useCreateWorkPackageMutation,
  useCreateNewWorkPackageMutation,
  useUpdateWorkPackageMutation,
  useUpdateNewWorkPackageMutation,
  useGetWeekProgressQuery,
  useAddWeekProgressMutation,
  useArchiveWpMutation,
  useUnarchiveWpMutation,
  useGetSnapshotQuery,
  useGetExportQuery,
  useGetExportTemplateQuery,
  useGetWpUsersQuery,
  useUpdateWorkPackageStatusMutation,
  useSubmitStatusReportMutation,
} = extendedApi;
export const { getExportTemplate } = extendedApi.endpoints;

export const { setWorkPackageAdded, resetWorkPackageAdded } = workPackageAddedSlice.actions;

const workPackageAddedSelector = (state: RootState) => state[workPackageAddedSlice.name];
export const selectWorkPackageAdded = createSelector(workPackageAddedSelector, (state) => state.workPackageAdded);
