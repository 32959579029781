import * as React from 'react';

const ActiveAdminIcon = (props: any) => {
  return (
    <div
      style={{ paddingTop: '20px', paddingLeft: '20px', display: 'inline-block', zIndex: '10', position: 'absolute' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="3.5" fill="#4ABA6B" stroke="white" />
      </svg>
    </div>
  );
};

export default ActiveAdminIcon;
