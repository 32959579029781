import React from 'react';

import { Wrapper, CenteredContainer, LoadingLogo, LoadingElyvate } from './LoadingOverlay.styles';

export const LoadingOverlay = () => {
  return (
    <Wrapper>
      <CenteredContainer>
        <LoadingLogo />
        <LoadingElyvate />
      </CenteredContainer>
    </Wrapper>
  );
};
