import React from 'react';
import { Avatar } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';

import { useGetMyProfileQuery } from '@/core/store/authSlice';
import { getUserNameInitials } from '@/utils/utils';
import ActiveAdminIcon from '@/assets/svg/figma_active_admin';

import * as S from './Navigation.styles';

export type UserSectionProps = {
  onExpandClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onLogoutClick?: () => void;
};

export const UserSection: React.FC<UserSectionProps> = ({ onLogoutClick, onExpandClick }) => {
  const { data: myProfileData } = useGetMyProfileQuery(undefined);

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #E5E6E7',
        borderRadius: 'var(--radius-s, 4px)',
        padding: '8px',
        paddingLeft: '0px',
      }}
    >
      <S.UserSection onClick={onExpandClick}>
        <div style={{ position: 'relative' }}>
          <Avatar style={{ backgroundColor: '#E7EFFF', color: '#1063FF', fontWeight: '700' }}>
            {getUserNameInitials(myProfileData?.name || '')}
          </Avatar>
          <div
            style={{
              position: 'absolute',
              top: '3px', // Adjust this value to control how much the icon hangs below the Avatar
              left: 2,
              transform: 'translate(-50%, 0)',
            }}
          >
            <ActiveAdminIcon />
          </div>
        </div>

        <S.User>
          <S.UserName>{myProfileData?.name}</S.UserName>
          <S.UserCompany>{myProfileData?.company}</S.UserCompany>
        </S.User>
        {onLogoutClick && (
          <S.LogoutButton onClick={onLogoutClick}>
            <LogoutIcon />
          </S.LogoutButton>
        )}

        {onExpandClick && <S.ExpandIcon />}
      </S.UserSection>
    </div>
  );
};

UserSection.defaultProps = {
  onExpandClick: undefined,
  onLogoutClick: undefined,
};