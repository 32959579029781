import React, { ReactNode } from 'react';

import { Nullable, ObjectValues } from '@/core/interfaces/helpers';
import { WORK_PACKAGE_STATE_STATUS } from '@/core/interfaces/WorkPackages';
import { colors } from '@/themes/colors';

import * as S from './TrafficLight.styles';

const StatusColorMap = {
  [WORK_PACKAGE_STATE_STATUS.GREEN]: {
    background: colors.semantic.trafficLight[500],
    border: colors.semantic.trafficLight[200],
    mask: 'squircle-rotated',
  },
  [WORK_PACKAGE_STATE_STATUS.YELLOW]: {
    background: colors.semantic.warning[500],
    border: colors.semantic.warning[200],
    mask: 'squircle',
  },
  [WORK_PACKAGE_STATE_STATUS.RED]: {
    background: colors.semantic.danger[500],
    border: colors.semantic.danger[200],
    mask: null,
  },
};

type TrafficLightProps = Omit<S.StyledTrafficLightProps, 'backgroundColor' | 'borderColor'> & {
  children?: ReactNode;
  status?: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
};

export const TrafficLight = ({ children, status, mask = null, ...props }: TrafficLightProps) => {
  return (
    <S.TrafficLight
      backgroundColor={status ? StatusColorMap[status].background : colors.neutral[300]}
      borderColor={status ? StatusColorMap[status].border : colors.neutral[100]}
      mask={status && mask ? StatusColorMap[status].mask : null}
      {...props}
    >
      {children}
    </S.TrafficLight>
  );
};
