/* eslint-disable i18next/no-literal-string */
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

interface SkeletonProps {
  height?: number;
  width?: number;
  variant?: 'rectangular' | 'circular' | 'text' | 'rounded';
  style?: React.CSSProperties;
  colorVariant?: 'default' | 'primary' | 'secondary' | 'white';
}

export const SkeletonLoading: React.FC<SkeletonProps> = ({
                                                           height = 100,
                                                           width = 200,
                                                           variant = 'rounded',
                                                           style,
                                                           colorVariant = 'deafult',
                                                         }) => {
  const backgroundColor =
    colorVariant === 'default'
      ? '#808080'
      : colorVariant === 'primary'
        ? '#F8F8F9'
        : colorVariant === 'secondary'
          ? '#E5E6E7'
          : '#fff';
  return (
    <Stack spacing={1}>
      <Skeleton
        animation="wave"
        variant={`${variant}`}
        width={width}
        height={height}
        style={{ backgroundColor, ...style }}
      />
    </Stack>
  );
};
