import * as React from 'react';

const TaskIcon = (props: any) => {
  const iconStyle = {
    marginLeft: '24px', // Adjust the value as needed
    transform: 'scale(1.05) translateY(2px)', // Adjust the value as needed
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      style={iconStyle}
      {...props}
    >
      <path
        d="M10.6 4.16667V15.8333H16.4333V4.16667H10.6ZM3.93331 2.5H17.2666C17.7269 2.5 18.1 2.8731 18.1 3.33333V16.6667C18.1 17.1269 17.7269 17.5 17.2666 17.5H3.93331C3.47308 17.5 3.09998 17.1269 3.09998 16.6667V3.33333C3.09998 2.8731 3.47308 2.5 3.93331 2.5Z"
        fill="#1063FF"
      />
    </svg>
  );
};

export default TaskIcon;
