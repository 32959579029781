import { Alert, AlertProps } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import authContext from '@/core/context/authContext';
import { formatBytes } from '@/utils/utils';
import { FileUpload } from '@/core/componentsv2/FileUpload';
import { UploadType } from '@/core/componentsv2/BackendFileUpload/BackendFileUpload';

import { API_URL } from '@/config';

type BackendFileUploadProps = {
  fileTypes?: Array<string>;
  onChange: (downloadUrl: string, file: File) => void;
  maxFileSize?: number;
  uploadType: UploadType;
  error?: boolean;
  location: string;
  id?: string;
};

export const BackendFileUploadPart = ({
                                        fileTypes = ['PNG'],
                                        maxFileSize = 0.254,
                                        onChange,
                                        uploadType,
                                        error,
                                        location,
                                        id,
                                      }: BackendFileUploadProps) => {
  const { t } = useTranslation('Common');
  const maxFileSizeBytes = maxFileSize * 1000000;

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<{ message: string } & Pick<AlertProps, 'severity'>>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);

  const url = `${API_URL}/v2/files/`;

  async function uploadToBackend(file: File) {
    // Create a FormData instance
    const formData = new FormData();

    // Append the file to the FormData instance
    // 'file' is the field name the server expects
    formData.append('uploaded_file', file);
    // formData.append('location', location);
    // formData.append('id', id);

    // Additional fields can be appended to formData as needed
    // formData.append('key', 'value');

    try {
      // Make the HTTP request using Axios
      const [response] = await Promise.all([
        axios({
          method: 'post',
          url: url,
          data: formData,
          params: {
            location: location,
            id: id,
          },
          headers: {
            // Specify the header so Axios can set the boundary correctly
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${authContext.token}`,
          },

          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
            }
          },
        }),
      ]);

      // Handle the response from the server here
      const downloadUrl = response.data.url;
      onChange(downloadUrl, file);
      setCompleted(true);
    } catch (_error) {
      // Handle any errors here
      console.error('Error uploading file', _error);
    }
  }

  const handleChange = async (file: File) => {
    if (url) {
      onChange(url, file);
      await uploadToBackend(file);
      setCompleted(true);
    }

    return true;
  };

  return (
    <div style={{ display: deleted ? 'none' : 'block' }}>
      <FileUpload
        fileTypes={fileTypes}
        uploadType={uploadType}
        maxFileSize={maxFileSizeBytes}
        uploadProgress={uploadProgress}
        onChange={handleChange}
        completed={completed}
        onDeleted={() => setDeleted(true)}
        onSizeError={() => {
          setAlertMessage({
            severity: 'warning',
            message: t('firebaseImageUploader.incorrectSizeError', { size: formatBytes(maxFileSizeBytes) }),
          });
        }}
        onTypeError={() => {
          setAlertMessage({
            severity: 'warning',
            message: t('firebaseImageUploader.incorrectFormatError', {
              formats: fileTypes.join(` ${t('firebaseImageUploader.or')} `),
            }),
          });
        }}
        error={error}
      />
      {alertMessage && <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>}
    </div>
  );
};

BackendFileUploadPart.defaultProps = {
  fileTypes: ['PNG', 'SVG'],
  maxFileSize: 0.254,
  error: undefined,
  id: undefined,
};
