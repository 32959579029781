/* stylelint-disable no-descending-specificity, selector-type-case, selector-type-no-unknown */
import styled, { css } from 'styled-components';
import {
  Box,
  Button,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { DoubleArrow as DoubleArrowIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import {
  DESKTOP_DRAWER_COLLAPSED_WIDTH,
  DESKTOP_DRAWER_EXPANDED_WIDTH,
  DRAWER_TRANSITION_DELAY_MS,
  TABLET_DRAWER_EXPANDED_WIDTH,
} from '@/core/components/Navigation/Navigation.constants';
import { FirebaseImage } from '@/core/components/FirebaseImage';
import { InfoOutlinedIcon } from '@/pages/ProjectTabs/components/WorkPackageForm/WorkPackageForm.styles';
import FolderIcon from '@/assets/svg/figma_folder';
import FolderInactiveIcon from '@/assets/svg/figma_folder_inactive';
import { colors } from '@/themes/colorsV2';
import GreyArchiveIcon from '@/core/componentsv2/Icon/GreyArchiveIcon';

interface MenuGroupProps {
  hideBorder?: boolean;
}

export const SidebarIconWrapper = styled.div<{ $isDrawerOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-right: 8px;
  cursor: pointer;

  svg {
    transition: transform ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transform: rotate(${({ $isDrawerOpen }) => (!$isDrawerOpen ? '180deg' : '0deg')});
    transform-origin: center;
  }
`;

export const SidebarIcon = styled(IconButton)`
  && {
    z-index: 1210;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    background-color: #f8f9fa;
  }

  &:hover,
  &:focus {
    background-color: #fff;
  }

  && .MuiIconButton-root {
    transform: rotate(-90deg);
    margin: 0;
  }
`;

export const ArrowIcon = styled(DoubleArrowIcon)`
  &&.MuiSvgIcon-root {
    display: flex;
    color: #646e80;
    width: 12px;
    height: 12px;
    font-size: 1.4rem;
  }
`;
export const MenuLinkArrowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  // gap: 10px;
`;
export const Drawer = styled(SwipeableDrawer)`
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 80;

  && .MuiDrawer-paper {
    transition: width ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: ${colors.core.white};
    overflow-x: hidden;
    border-right: 0;
  }
`;

export const DrawerContent = styled.div`
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border: 0;
`;

export const LogoContainer = styled.div`
  height: 64px;
  padding: 0 0 0 26px;
  border-bottom: solid 1px var(--neutral-grey-200, #e4e7ec);
  border-right: solid 1px var(--neutral-grey-200, #e4e7ec);
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    height: 64px;
    align-items: center;
  }
`;

export const BrandContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.h1<{ $isDrawerOpen: boolean; $isDrawerHover: boolean }>`
  overflow: hidden;
  margin: 10px 0 0;
  transition: all ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  svg {
    #elyvate-logo-mark {
      transition: inherit;
      opacity: ${({ $isDrawerOpen, $isDrawerHover }) => (!($isDrawerOpen || $isDrawerHover) ? '0' : `1`)};
    }
  }
`;

export const LogoLink = styled(Link)`
  display: block;
  text-decoration: none;
  height: 100%;
  align-items: center;
`;

export const Navigation = styled.nav`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 16px);
  padding: 0 0 0 16px;
  margin-left: -16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-right: 1px solid ${colors.grey.light};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SetAgendaButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid #e5e6e7;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

export const MenuGroup = styled.div<MenuGroupProps>`
  padding: 16px 16px 8px;
  border-bottom: solid 1px #e4e7ec;

  /* Conditionally hide the border based on the hideBorder prop */
  ${(props) =>
    props.hideBorder &&
    css`
      border-bottom: none;
    `};
`;

export const NavigationLoaderWrapper = styled.div`
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
  position: relative;
`;

export const MenuLinkArrow = styled.div`
  display: flex;
  flex-direction: row; /* Set the flex direction to row */
  position: absolute;
  right: 9px;
  top: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out !important;

  svg,
  svg path {
    fill: ${colors.grey.default};
  }

  & > *:not(:last-child) {
    margin-right: 5px; /* Add spacing between child elements, adjust as needed */
  }

  @media (max-width: 600px) {
    display: none; // Hide the arrow on screens with a width of 600px or less
  }
`;

export const SectionTitle = styled.h3`
  display: none;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;
  text-transform: uppercase;
  color: ${colors.grey.default};
  display: flex;
  padding: 0 8px;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    display: block;
  }
`;

export const MenuLink = styled(Link)<{ $isActive: boolean; $isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 12px 8px;
  color: ${({ $isActive }) => ($isActive ? colors.core.black : '#4B5566')};
  background-color: ${({ $isActive }) => ($isActive ? colors.blue.extraLight : 'transparent')};
  border-radius: 4px;
  cursor: pointer;

  && > svg:nth-child(1) {
    margin-right: 12px;
    font-size: 2rem;
    width: 20px;
  }

  && > svg.custom {
    font-size: 1.6rem;
  }

  && > svg:nth-child(3) {
    transition: transform ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: auto;
    margin-right: 0;
    transform: rotate(${({ $isExpanded }) => ($isExpanded ? '180deg' : '0')});
    font-size: 1.8rem;
  }

  svg,
  svg path {
    transition: all 0.15s ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    &:hover {
      color: ${colors.core.black};
      background-color: ${({ $isActive }) => ($isActive ? colors.blue.extraLight : 'transparent')};

      ${({ $isActive }) =>
        !$isActive &&
        css`
          svg {
            &,
            path {
              fill: ${colors.core.black};
            }
          }
        `}
    }
  }
`;

export const Submenu = styled.ul`
  padding: 0;
  list-style: none;
  padding-left: 36px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 22px;
    top: -8px;
    width: 1px;
    background-color: ${colors.blue.extraLight};
    height: 100%;
  }
`;

export const SubmenuLink = styled(Link)<{ $isActive: boolean; $isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-decoration: none;
  padding: 12px;
  color: ${({ $isActive }) => ($isActive ? colors.core.black : colors.grey.default)};
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};
  background-color: ${({ $isActive }) => ($isActive ? colors.blue.extraLight : 'transparent')};
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  &:visited {
    color: ${({ $isActive }) => ($isActive ? colors.core.black : colors.grey.default)};
  }

  && > svg:nth-child(1) {
    font-size: 0.8rem;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    &:hover {
      color: ${colors.core.black};

      /* background: inherit; */
    }
  }
`;

export const FolderIconWrapper = styled(FolderIcon)`
  margin-right: 10px;
  background: lime;

  &::after {
    content: '';
    width: 10px;
    display: inline-block;
  }
`;

export const FolderInactiveIconWrapper = styled(FolderInactiveIcon)`
  margin-right: 10px;
  background: lime;

  &::after {
    content: '';
    width: 10px;
    display: inline-block;
  }
`;

export const ArchivedIconWrapper = styled(GreyArchiveIcon)`
  margin-right: 10px;
  background: lime;
  color: grey;

  &::after {
    content: '';
    width: 10px;
    display: inline-block;
  }
`;

export const SubmenuText = styled(Typography).attrs({
  variant: 'body2',
})`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: inherit !important;
  color: inherit !important;
  font-weight: 500 !important;
`;

export const SubmenuText2 = styled(Typography).attrs({
  variant: 'body2',
})`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: inherit !important;
  color: grey;
  font-weight: 500 !important;
`;

export const SubmenuTag = styled.span`
  margin-left: auto;
`;

export const ProjectSelectWrapper = styled.div`
  width: 100%;
`;

export const ProjectSelect = styled(Select)`
  && {
    width: 100%;
    margin-bottom: 24px;
    border: 0 !important;
    border-bottom: 1px solid ${colors.grey.light};

    &:focus-visible,
    .MuiSelect-outlined:focus-visible {
      outline: none;
      box-shadow: none;
      border: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;

export const CompanyItem = styled(ListSubheader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 12px 15px;
`;

export const ProjectItem = styled(MenuItem)`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    color: #1f2631;
    background-color: #e4e7ec;
  }
`;

export const ProjectLogo = styled(FirebaseImage)`
  && {
    width: 24px;
  }
`;

export const BottomSection = styled.div`
  display: block;
  align-items: center;
  width: 100%;
`;

export const TopBarWrapper = styled.div<{ isDrawerOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 0;
  top: 0;
  padding: 0 28px;
  transition: padding ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  height: 64px;
  background-color: #fafbfc;
  border-bottom: solid 1px #e4e7ec;
  max-height: 64px;
  background-color: ${colors.core.white};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    height: 64px;
    padding: 0 24px;

    ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
      --padding-left: ${TABLET_DRAWER_EXPANDED_WIDTH}px;
    }

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      --padding-left: ${DESKTOP_DRAWER_EXPANDED_WIDTH}px;
    }

    padding-left: calc(0px + var(--padding-left, 0px));
  }
`;

export const TabBar = styled(Grid)`
  position: sticky !important;
  top: 0 !important;
  min-width: 100% !important;
  z-index: 600 !important;
  padding: 0 24px !important;
  height: 64px;
  display: flex;
  background-color: #fff !important;
  border-bottom: 1px solid #e5e6e7 !important;
`;

export const TabBarPackageNumber = styled(Typography)`
  padding: 0 8px !important;
  border-radius: 4px !important;
  border: 2px solid #e7efff !important;
  color: #1063ff !important;
  font-family: Inter, sans-serif !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  letter-spacing: 0.2px !important;
  text-transform: uppercase !important;
  font-variant-numeric: lining-nums tabular-nums !important;
  font-feature-settings:
    'clig' off,
    'liga' off !important;
`;

export const TabBarTitle = styled(Typography)`
  color: #000 !important;
  font-family: Inter, sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  letter-spacing: -0.24px !important;
  font-variant-numeric: lining-nums tabular-nums !important;
  font-feature-settings:
    'clig' off,
    'liga' off !important;
`;

export const LeftBar = styled.div`
  width: 50%;
  display: flex;
`;

export const RightBar = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SearchBar = styled(TextField)`
  && .MuiInputBase-root {
    display: none;
    max-width: 512px;
    width: 100%;
  }

  && .MuiInputBase-input {
    height: 16px;
    padding: 16px 0;
    border-radius: 4px;
    width: 100%;
    max-width: 512px;
    border: none;
  }

  && .MuiSvgIcon-root {
    font-size: 1.8rem;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    && .MuiInputBase-root {
      display: inline-flex;
    }
  }
`;

export const SelectedWeek = styled.div`
  border: solid 1px #e3e7eb;
  border-radius: 4px;
  padding: 5px 12px;
  color: #7f8898;
  margin-right: 8px;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    display: none;
  }
`;

export const CWSelectWrapper = styled.div`
  max-width: 170px;
  width: 100%;
  background-color: red;
`;

export const CWSelect = styled.div`
  position: relative;
  display: inline-block;
  color: 'black';
  padding: 12px 16px;
  font-size: 1.4rem;
  font-weight: 700 !important;
  border: 1px solid #04044b;
  border-radius: 4px;
  width: 100%;

  && svg {
    color: #0000db;
  }

  && .MuiSelect-select {
    display: flex;
    color: #0000db;
    padding: 6px 32px 6px 14px;
    font-size: 1.4rem;
    font-weight: 700;
  }

  && .MuiOutlinedInput-notchedOutline {
    border-color: #0000db;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    && svg {
      color: rgb(0 0 0 / 54%);
    }

    && .MuiSelect-select {
      color: #000021;
      padding: 14px 32px 14px 14px;
      font-size: 1.6rem;
      font-weight: 400;
    }

    && .MuiOutlinedInput-notchedOutline {
      border-color: #e4e7ec;
    }
  }
`;

export const SelectCalendarBox = styled(Box)`
  display: flex;
  position: absolute;
  top: 140%;
  right: 0;
  flex-direction: column;
  box-shadow:
    0 5px 5px -3px rgba(0 0 0 / 20%),
    0 8px 10px 1px rgba(0 0 0 / 14%),
    0 3px 14px 2px rgba(0 0 0 / 12%);
  background: white;
  padding: 10px;
  width: 420px;
  border-radius: 4px;

  && .css-a1s3zn-MuiPickersCalendarHeader-labelContainer {
    width: 200px;
    font-size: 20px;
  }

  .MuiDayCalendar-weekContainer,
  &&.css-flbe84-MuiDayCalendar-weekContainer:hover {
    background: red;
  }
`;

export const SelectedValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: 8px;
  }
`;

export const Notifications = styled(IconButton)`
  && {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    margin: 0 28px;
  }

  && .MuiIconButton-root {
    margin: 0;
  }

  && .MuiSvgIcon-root {
    color: #090f1d;
    font-size: 2rem;
  }
`;

export const UserSectionWrapper = styled.div`
  padding: 18px 32px;
  border: 1px solid #e5e6e7;
`;

export const UserSection = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const User = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserName = styled.h5`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  color: #090f1d;
  padding-bottom: 5px;
  padding-right: 32px;
`;
export const UserCompany = styled.h6`
  color: var(--primary-grey-default, #7f8185);
  font-variant-numeric: slashed-zero;

  /* Body/XS */
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding-right: 32px;
`;

export const ExpandIcon = styled(ExpandMoreIcon)`
  && {
    font-size: 1.6rem;
    margin-left: 32px;
  }
`;

export const LogoutButton = styled(Button)`
  && {
    margin-left: auto;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #0000b6;
  }

  && .MuiSvgIcon-root {
    color: #0000b6;
  }
`;

export const UserMenuItem = styled(MenuItem)`
  && {
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 192px;

    &&.Mui-selected {
      background-color: transparent;
      color: inherit;
    }
  }
`;

export const UserMenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #4b5566;
`;

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000021;

  & > svg {
    font-size: 2rem;
  }
`;

export const HamburgerWrapper = styled.div`
  display: block;
  box-sizing: content-box;
  margin: auto 0 auto -16px;

  && .hamburger-react {
    color: #646e80;
  }
`;

export const BarIconWrapper = styled.div<{ $isActive: boolean }>`
  margin-right: 6px;

  svg path {
    fill: ${({ $isActive }) => ($isActive ? colors.blue.default : colors.grey.default)};
  }
`;

export const TopBarContent = styled.div<{ $isDrawerOpen: boolean; $isDrawerHover: boolean }>`
  display: flex;
  padding: 8px 16px 8px 0;
  align-items: center;
  gap: 8px;
  transition: transform ${DRAWER_TRANSITION_DELAY_MS}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transform: ${({ $isDrawerOpen, $isDrawerHover }) =>
    $isDrawerOpen || $isDrawerHover ? 'translateX(0)' : `translateX(-248px)`};
`;

export const TodayBtn = styled(Button)`
  &&,
  &&:hover {
    color: #04044b;
    background-color: #fff;
    border: 2px solid #04044b;
    margin: 0;
  }
`;

export const CancelBtn = styled(Button)`
  &&,
  &&:hover {
    color: #04044b;
    background-color: #fff;
    margin: 0;
  }
`;

export const ChangeCWBtn = styled(Button)`
  &&,
  &&:hover {
    color: #fff;
    background-color: #04044b;
    margin: 0;
    width: 96px;
    max-width: 96px;
  }
`;

const openStyling = css`
  && .MuiDrawer-paper {
    width: 100%;
    max-width: 100%;

    ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
      width: ${TABLET_DRAWER_EXPANDED_WIDTH}px;
    }

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      width: ${DESKTOP_DRAWER_EXPANDED_WIDTH}px;
    }
  }

  & ${ArrowIcon} {
    transform: rotate(-180deg);
  }

  & ${MenuLink} > span {
    opacity: 1;
  }

  & ${Submenu} {
    display: block;
  }

  & ${LogoLink} {
    visibility: visible;
    padding: 0;

    &::first-letter {
      visibility: visible;
    }
  }

  & + main {
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      width: calc(100% - ${DESKTOP_DRAWER_EXPANDED_WIDTH}px);
      margin-left: ${DESKTOP_DRAWER_EXPANDED_WIDTH}px;
    }
  }
`;

const closeStyling = css`
  && .MuiDrawer-paper {
    width: 0;

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      width: ${DESKTOP_DRAWER_COLLAPSED_WIDTH}px;
    }
  }

  & ${ArrowIcon} {
    transform: rotate(0deg);
  }

  & ${MenuLink} {
    padding: 10px;
  }

  & ${MenuLink} > span {
    opacity: 0;
  }

  & ${ProjectSelectWrapper} {
    display: none;
  }

  & ${SectionTitle} {
    display: none;
  }

  & ${Submenu} {
    display: none;
  }

  & ${LogoLink} {
    visibility: hidden;
    padding: 0 4px;

    &::first-letter {
      visibility: visible;
    }
  }

  & + main {
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      width: calc(100% - ${DESKTOP_DRAWER_COLLAPSED_WIDTH}px);
      margin-left: ${DESKTOP_DRAWER_COLLAPSED_WIDTH}px;
    }
  }
`;

export const SidebarWrapper = styled.div<{ isOpen: boolean; isHover: boolean }>`
  ${({ isOpen, isHover }) =>
    !(isOpen || isHover) &&
    css`
      & ${MenuLinkArrow} {
        display: none;
      }
    `}

  ${({ isOpen, isHover }) => (isOpen || isHover ? openStyling : closeStyling)}

  & ${SidebarIconWrapper} {
    left: calc(
      ${({ isOpen, isHover }) => (isOpen || isHover ? DESKTOP_DRAWER_EXPANDED_WIDTH : DESKTOP_DRAWER_COLLAPSED_WIDTH)}px -
        36px
    );
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    && .MuiDrawer-paper:hover {
      width: ${DESKTOP_DRAWER_EXPANDED_WIDTH}px;

      & ${ArrowIcon} {
        transform: rotate(-180deg);
      }

      & ${MenuLink} > span {
        opacity: 1;
      }

      & ${LogoLink} {
        visibility: visible;
        padding: 0;
      }

      & ${Submenu} {
        display: block;
      }
    }
  }
`;
export const InfoIcon = styled(InfoOutlinedIcon)`
  width: 24px;
  height: 24px;
  fill: white; /* Change the default color to white */
  margin-top: 2px;
  transition:
    fill 0.3s,
    background-color 0.3s;
  color: #7f8185 !important;

  &:hover {
    fill: blue; /* Set the fill for the hover state */
    // background-color: #0066cc;
    color: #06c;
  }
`;

export const SidebarTitle = styled.span`
  display: inline-block;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 50px);
`;

export const CurrentCompanyWrapper = styled.button<{ $backgroundColor: string }>`
  display: flex;
  padding: 0 26px;
  align-items: center;
  border: 0;
  border-bottom: 1px solid ${colors.grey.light};
  height: 64px;
  // background: transparent;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: pointer;

  > svg:first-of-type {
    width: 22px;
    height: 22px;
    margin-right: 14px;
  }

  > div > svg {
    margin-left: 1px;
  }
`;

export const CurrentCompanyName = styled.div`
  color: ${colors.core.black};
  font-size: 2rem;
  margin-right: auto;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 65px);
`;

interface CurrentCompanyImageProps {
  $isExpanded: boolean;
}

export const CurrentCompanyImage = styled.img<CurrentCompanyImageProps>`
  display: inline-block;
  width: ${({ $isExpanded }) => ($isExpanded ? '40px' : '20px')};
  height: ${({ $isExpanded }) => ($isExpanded ? '40px' : '20px')};
  margin-right: 16px;
  background-color: ${colors.core.white};
  border-radius: 50%;
`;

export const CompanyModalWrapper = styled.div`
  @keyframes inAnimationWrapper {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes outAnimationWrapper {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  background-color: ${colors.grey.mediumLight};
  width: 304px;
  height: calc(100vh - 64px);
`;

export const CompanyModalContainer = styled.div<{ $isDrawerOpen: boolean }>`
  @keyframes inAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  display: block;
  position: fixed;
  top: 64px;
  height: calc(100vh - 64px);
  overflow: hidden;
  transition: left 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    left: ${({ $isDrawerOpen }) => (!$isDrawerOpen ? '72px' : `${TABLET_DRAWER_EXPANDED_WIDTH}px`)};
    width: calc(100vw - ${TABLET_DRAWER_EXPANDED_WIDTH}px);
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    left: ${({ $isDrawerOpen }) => (!$isDrawerOpen ? '72px' : `${DESKTOP_DRAWER_EXPANDED_WIDTH}px`)};
    width: calc(100vw - ${DESKTOP_DRAWER_EXPANDED_WIDTH}px);
  }
  background-color: rgba(0 0 0 / 15%);
`;

export const CompanyModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
`;

export const CompanyModalCompanies = styled.div`
  padding: 12px 16px;
`;

export const TabBarButtonText = styled.div<{ $isActive: boolean; $isDrawerOpen: boolean }>`
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  transition: transform 0.3s ease;
  transform: ${({ $isDrawerOpen }) => ($isDrawerOpen ? 'translateX(0)' : 'translateX(0)')};
  color: ${({ $isActive }) => ($isActive ? 'var(--essentials-black, #000)' : 'var(--primary-grey-default, #7F8185)')};
`;

export const ReportWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  flex-wrap: wrap;
  width: auto;
  display: flex;
  height: 52px;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 8px 14px;
    width: auto;
  }
`;

export const ReportBarWrapper = styled.div`
  display: flex;
  height: 72px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--primary-grey-light, #e5e6e7);
  padding: 40px 24px;
`;
