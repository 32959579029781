import React from 'react';
import styled from 'styled-components';

import { FullScreenLayout } from '@/layouts/FullScreenLayout';
import { ReactComponent as Logo } from '@/assets/logo.svg';

import { CardsGrid } from '../components/CardsSkeleton'; // Import the CardsGrid component to display the cards, DO NOT REMOVE
import ListsSkeleton from '../components/ListsSkeleton'; // Import the ListsSkeleton component to display the lists, DO NOT REMOVE

import { SkeletonLoading } from '@/stories/Primitives/SkeletonLoading/SkeletonLoading';

// Define the props for the DashboardSkeleton component to be reused in other components
type DashboardSkeletonProps = {
  children?: React.ReactNode;
};

const listStyle = localStorage.getItem('listStyle');

const DashboardSkeleton: React.FC<DashboardSkeletonProps> = ({ children }) => {
  return (
    <FullScreenLayout
      renderTopBar={() => (
        // Topbar
        <TopBar>
          {/* Left side of the header */}
          <LeftContainer>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Spacer width={32} />
            <Divider />
            <Spacer width={32} />
            <AppsSkeleton>
              <SkeletonLoading variant="text" width={16} height={24} colorVariant="secondary" />
              <Spacer width={8} />
              <SkeletonLoading variant="text" width={100} height={24} colorVariant="secondary" />
              <Spacer width={16} />
              <SkeletonLoading variant="text" width={16} height={24} colorVariant="secondary" />
              <Spacer width={8} />
              <SkeletonLoading variant="text" width={100} height={24} colorVariant="secondary" />
            </AppsSkeleton>
          </LeftContainer>
          {/* Space between the left and right */}
          <Spacer />
          {/* Rightside of header */}
          <RightContainer>
            <UserPanel>
              <AvatarSkeletonContainer>
                <SkeletonLoading variant="circular" width={32} height={32} colorVariant="secondary" />
              </AvatarSkeletonContainer>
              <UserInfo>
                <SkeletonLoading
                  variant="text"
                  width={110}
                  height={20}
                  colorVariant="secondary"
                  style={{ marginBottom: '4px' }}
                />
                <SkeletonLoading variant="text" width={101} height={20} colorVariant="primary" />
              </UserInfo>
            </UserPanel>
          </RightContainer>
        </TopBar>
      )}
    >
      <MainContainer>
        {/* Sidebar */}
        <SidebarContainer>
          {/* Client Box */}
          <ClientBox>
            <TagContainer>
              <SkeletonLoading variant="rounded" width={18} height={18} colorVariant="secondary" />
              <Spacer width={8} />
              <SkeletonLoading variant="rounded" width={100} height={18} colorVariant="secondary" />
            </TagContainer>
          </ClientBox>

          {/* Projects Box */}
          <ProjectsBox>
            <Header>
              <SkeletonLoading
                variant="rounded"
                width={114}
                height={16}
                style={{ marginLeft: '8px' }}
                colorVariant="secondary"
              />
            </Header>
            <Spacer height={16} />
            <ListItem>
              <ListItemContent>
                <SkeletonLoading variant="rounded" width={18} height={18} colorVariant="secondary" />
                <Spacer width={8} />
                <SkeletonLoading variant="rounded" width={187} height={18} colorVariant="primary" />
              </ListItemContent>
            </ListItem>
            <Spacer height={16} />
            <ListItem>
              <ListItemContent>
                <SkeletonLoading variant="rounded" width={18} height={18} colorVariant="secondary" />
                <Spacer width={8} />
                <SkeletonLoading variant="rounded" width={187} height={18} colorVariant="primary" />
              </ListItemContent>
            </ListItem>
            <Spacer height={16} />
            <ListItem>
              <ListItemContent>
                <SkeletonLoading variant="rounded" width={18} height={18} colorVariant="secondary" />
                <Spacer width={8} />
                <SkeletonLoading variant="rounded" width={187} height={18} colorVariant="primary" />
              </ListItemContent>
            </ListItem>
          </ProjectsBox>

          {/* Bottom Line */}
          <BottomLine />
        </SidebarContainer>

        {/* Tabbars */}
        <ContentArea>
          <HeaderContainer>
            {/* First Tab Bar */}
            <WhiteTabBar>
              <LeftSide>
                <SkeletonLoading variant="text" width={203} height={26} colorVariant="secondary" />
              </LeftSide>
              <RightSide>
                <SkeletonLoading variant="rounded" width={200} height={40} colorVariant="primary" />
              </RightSide>
            </WhiteTabBar>

            {/* Second Tab Bar */}
            <TabBar>
              <LeftSide>
                <ChoiceSwitcher>
                  <SkeletonLoading
                    variant="text"
                    width={100}
                    height={24}
                    style={{ marginRight: '16px' }}
                    colorVariant="secondary"
                  />
                  <SkeletonLoading
                    variant="text"
                    width={100}
                    height={24}
                    style={{ margin: '0 10px' }}
                    colorVariant="secondary"
                  />
                  <SkeletonLoading variant="text" width={100} height={24} colorVariant="secondary" />
                </ChoiceSwitcher>
              </LeftSide>
              <RightSide>
                <SkeletonLoading variant="rounded" width={99} height={40} colorVariant="white" />
              </RightSide>
            </TabBar>

            {/* Third Tab Bar */}
            <TabBar>
              <LeftSide>
                <DoubleTag>
                  <SkeletonLoading variant="text" width={100} height={24} colorVariant="secondary" />
                  <Spacer width={10} />
                  <SkeletonLoading
                    variant="text"
                    width={80}
                    height={24}
                    style={{ marginLeft: '10px' }}
                    colorVariant="secondary"
                  />
                </DoubleTag>
              </LeftSide>
              <RightSide>
                <RightTab>
                  <LeftTag>
                    <SkeletonLoading variant="rounded" width={16} height={16} colorVariant="secondary" />
                    <Spacer width={2} />
                    <SkeletonLoading variant="text" width={46} height={26} colorVariant="secondary" />
                  </LeftTag>
                  <SkeletonLoading
                    variant="rectangular"
                    width={94}
                    height={40}
                    style={{ borderRadius: '8px', backgroundColor: 'white' }}
                    colorVariant="white"
                  />
                </RightTab>
              </RightSide>
            </TabBar>
          </HeaderContainer>
          {listStyle === 'list' ? (
            <CardsWrapper>
              <CardsGrid />
            </CardsWrapper>
          ) : (
            <ListsWrapper>
              {[...Array(4)].map((_, index) => (
                <React.Fragment key={index}>
                  <ListsSkeleton />
                  {index < 3 && <Spacer height={16} />}
                </React.Fragment>
              ))}
            </ListsWrapper>
          )}
        </ContentArea>
      </MainContainer>
    </FullScreenLayout>
  );
};

// Styled components defined within the same file

const TopBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 24px; /* The 24px space from the edge for the logo */
`;
//Topbar-> left side
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Spacer = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  background-color: #e0e0e0;
`;

const AppsSkeleton = styled.div`
  display: flex;
  align-items: center;
`;

//Topbar-> right side
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
  flex-grow: 1;
  background-color: white;
`;

const UserPanel = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 48px;
  background-color: white;
  border: 1px solid #f8f8f9;
  border-radius: 8px;
  padding: 8px;
`;

const AvatarSkeletonContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border: 1px solid white;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

//Sidebar
const SidebarContainer = styled.div`
  width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
`;

const ClientBox = styled.div`
  width: 320px;
  height: 72px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectsBox = styled.div`
  width: 320px;
  height: 216px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
`;

const Header = styled.div`
  width: 288px;
  height: 16px;
  display: flex;
  align-items: center;
`;

const ListItem = styled.div`
  width: 288px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: #ffffff;
`;

const ListItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLine = styled.div`
  margin-top: auto;
  width: 100%;
  height: 72px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
`;

//Tabbars

const HeaderContainer = styled.div`
  width: 100%;
  maxwidth: 100%;
  height: 222px;
  display: flex;
  flex-direction: column;
  gap: 1px; /* Gap between each tab bar */
`;

const TabBar = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
`;

const WhiteTabBar = styled(TabBar)`
  background-color: #ffffff;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

const ChoiceSwitcher = styled.div`
  width: 416px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DoubleTag = styled.div`
  width: 192px;
  height: 72px;
  display: flex;
  align-items: center;
`;

const RightTab = styled.div`
  width: 188px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  gap: 8px;
`;

const LeftTag = styled.div`
  width: 92px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

//Layout
const MainContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentArea = styled.div`
  flex: 1;
  /*UNdo to here is needed */
  width: 100%;
`;

// Define the CardsWrapper component to display the cards, DO NOT REMOVE
const CardsWrapper = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const ListsWrapper = styled.div`
  padding: 16px; /* Add padding around the lists */
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculations */
`;

export default DashboardSkeleton;
