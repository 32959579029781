export const colors = {
  core: {
    white: '#FFFFFF',
    black: '#000000',
  },
  blue: {
    dark: '#0031CD',
    default: '#1063FF',
    medium: '#4C8AFF',
    light: '#87B1FF',
    extraLight: '#E7EFFF',
    extraLightII: '#ECF3FF',
  },
  grey: {
    default: '#7F8185',
    medium: '#D4D5D7',
    light: '#E5E6E7',
    mediumLight: '#F7F7F7',
    lighter: '#EEEEEF',
    extraLight: '#F8F8F9',
  },
  green: {
    dark: '#188839',
    default: '#4ABA6B',
    medium: '#A4DDB5',
    light: '#D2EEDA',
    extraLight: '#E6F5F3',
  },
  red: {
    dark: '#C50B16',
    default: '#F73D48',
    medium: '#FB9EA3',
    light: '#FFE2E5',
    extraLight: '#FEF0F1',
    superLight: '#FFF3F5',
  },
  yellow: {
    dark: '#E8CC3A',
    default: '#E8CC3A',
    medium: '#F4E69D',
    light: '#F9F2CE',
    extraLight: '#FCF8E3',
  },
};
