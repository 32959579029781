import { InputAdornment, TextField } from '@mui/material';
import React, { ChangeEventHandler, FC } from 'react';

import FigmaSearchIcon from '@/assets/svg/figma_search';
import { colors } from '@/themes/colorsV2';
import { TypographyV2 } from '@/themes/typographyV2';

type Props = {
  placeholder?: string;
  value?: unknown;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  paddingInline?: string;
  paddingTop?: string;
};

const ShortSearchBar: FC<Props> = ({ value, onChange, placeholder, paddingInline, paddingTop }) => {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete="off" // This can be turned on, but must be styled correctly.
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ marginLeft: '8px !important' }}>
            <FigmaSearchIcon width="16px" height="16px" fill={value ? colors.core.black : colors.grey.default} />
          </InputAdornment>
        ),
        sx: {
          borderRadius: '8px',
          border: `1px solid ${colors.grey.light}`,
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
          ...TypographyV2['bodyS-bold'],
          color: colors.core.black,
          height: '32px',
          padding: 0,
          margin: 0,
          '& .MuiOutlinedInput-input': {
            padding: '8px',
          },
        },
      }}
      sx={{
        width: '100%',
        paddingInline: { paddingInline },
        paddingTop: { paddingTop },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none',
          },
        },
      }}
    />
  );
};

ShortSearchBar.defaultProps = {
  placeholder: undefined,
  value: undefined,
  onChange: undefined,
  paddingInline: '8px',
  paddingTop: '10px',
};

export default ShortSearchBar;
