/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, browserSessionPersistence, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

import { firebaseConfig, AUTH_EMULATOR_URL, SERVICES_EMULATOR } from './config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

auth.setPersistence(browserSessionPersistence).catch((e) => {
  // console.log(e);
});

const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

if (AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, AUTH_EMULATOR_URL, { disableWarnings: true });
}

if (SERVICES_EMULATOR === '1') {
  connectDatabaseEmulator(database, 'localhost', 9000);
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const getAccessToken = async (forceRefresh = true) => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken(forceRefresh);

    return token;
  }

  return undefined;
};

const headerConfig = async (forceRefresh = true): Promise<{ [key: string]: any }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken(forceRefresh);

    console.log('hello')

    return { headers: { Authorization: `Bearer ${token}` } };
  }

  return {};
};

const uploadFileToStorage = async (
  fileName: string,
  storagePath: string,
  file: File,
  onLoadingHandler?: (progress: boolean) => void,
  onProgressHandler?: (progress: number) => void,
) => {
  if (onLoadingHandler) onLoadingHandler(true);

  const destPath = `${storagePath}/${fileName}`;

  const storageRef = ref(storage, destPath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      if (onProgressHandler) onProgressHandler(progress);
    },
    (error: any) => {
      console.log(error);

      if (onLoadingHandler) onLoadingHandler(false);

      if (onProgressHandler) onProgressHandler(-1);
    },
    async () => {
      if (onLoadingHandler) onLoadingHandler(false);
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    },
  );
};

const getDownloadURLFromStorage = async (fullPath: string) => {
  const url = await getDownloadURL(ref(storage, fullPath));

  return url;
};

export { app, auth, database, firestore, storage, headerConfig, uploadFileToStorage, getDownloadURLFromStorage };
