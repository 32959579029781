const ContractIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.0713 4.92871L16.4856 6.34292L11.8287 10.9998L21.0006 10.9999L21.0005 12.9999L11.8287 12.9998L16.4856 17.6566L15.0714 19.0708L8.00028 11.9998L15.0713 4.92871ZM4.00049 18.9997V4.99972H6.00049V18.9997H4.00049Z"
          fill="#7F8185"
        />
      </svg>
    </div>
  );
};

export default ContractIcon;
