import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';

type ShouldDisplay = {
  up: (breakpoint: Breakpoint) => boolean;
  down: (breakpoint: Breakpoint) => boolean;
  between: (breakpointBelow: Breakpoint, breakpointAbove: Breakpoint) => boolean;
};

type UseResponsive = [ShouldDisplay];

export const useResponsive = () => {
  const theme = useTheme();

  const shouldDisplay = {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    up: (breakpointUp: Breakpoint) => useMediaQuery(theme.breakpoints.up(breakpointUp), { noSsr: true }),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    down: (breakpointDown: Breakpoint) => useMediaQuery(theme.breakpoints.down(breakpointDown), { noSsr: true }),
    between: (breakpointAbove: Breakpoint, breakpointBelow: Breakpoint) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMediaQuery(theme.breakpoints.between(breakpointAbove, breakpointBelow), { noSsr: true }),
  };

  return [shouldDisplay] as UseResponsive;
};
