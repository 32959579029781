import { createGlobalStyle } from 'styled-components';

import { hexToHSL } from '@/utils/utils';
import { colors } from '@/themes/colors';
import { colors as colorsV2 } from '@/themes/colorsV2';

let cssColors = '';

Object.entries(colorsV2).forEach(([name, color]) => {
  Object.entries(color).forEach(([shadeName, shadeColor]) => {
    // Setting global CSS variables (custom properties) for HEX color codes and HSL (converted from HEX) color codes
    cssColors += `--color-${name}-${shadeName}: ${shadeColor};`;
    cssColors += `--color-${name}-${shadeName}--hsl: ${hexToHSL(shadeColor)};`;
  });
});

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    font-family: 'Inter', 'Inter', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }

  :root {
    font-size: 62.5%;
    font-family: 'Inter', sans-serif;
    ${cssColors}
  }

  body {
    margin: 0;
    color: ${colors.neutral[900]};

    // Quick fix to disable scrolling when the company modal is open
    &:has(#sidebar-company-modal) {
      overflow: hidden;
    }
  }

  input, textarea {
    user-select: text;
  }

  #root {
    background-color: ${colors.neutral[100]};
  }

  a {
    &:active,
    &:hover  {
      color :${colors.primary[500]};
    }
  }

  @keyframes moveLeft {
    0% { transform: translateX(0); }
    50% { transform: translateX(-50px); }
    100% { transform: translateX(0); }
  }

  @keyframes moveRight {
    0% { transform: translateX(0); }
    50% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }
`;
