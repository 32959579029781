import styled from 'styled-components';

import { colors } from '@/themes/colors';

export const Container = styled.div`
  z-index: 1211;
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${colors.neutral[100]};
`;

const TopBarHeight = `64px`;

export const TopBar = styled.header`
  display: flex;
  align-items: center;

  padding: 0 24px;
  min-height: ${TopBarHeight};
  background-color: ${colors.neutral.white};
  border-bottom: 1px solid #e0e0e0;
`;

export const Content = styled.div`
  max-height: calc(100% - ${TopBarHeight});
  overflow: auto;
`;
