// ListSelectElement.tsx
import React from 'react';

import { ListElement } from './ListSelect.styles';

type ListSelectProps = {
  children: React.ReactNode;
};

const ListSelect: React.FC<ListSelectProps> = ({ children }) => {
  return <ListElement>{children}</ListElement>;
};

export default ListSelect;
