import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { Nullable, ObjectValues } from '@/core/interfaces/helpers';
import { WORK_PACKAGE_STATE_STATUS } from '@/core/interfaces/WorkPackages';
import { colors } from '@/themes/colorsV2';

import { TrafficLight } from '../TrafficLight/TrafficLight';
import * as S from './TrafficLightTag.styles';

type TrafficLightTagProps = {
  children?: ReactNode;
  status?: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  size?: 'small' | 'big' | undefined;
  color?: string;
};

export const TrafficLightTag = ({ children, status, size, color, ...props }: TrafficLightTagProps) => {
  return (
    <S.TagContainer $size={size} $color={color}>
      <TrafficLight size={10} status={status} mask={true} />
      <Typography variant="tag1-capital" color={colors.core.black}>
        {children}
      </Typography>
    </S.TagContainer>
  );
};
