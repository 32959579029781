import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { RootState } from '@/core/store/store';
import { apiSlice } from '@/core/store/apiSlice';
import { GetPermissionsList, MyUserProfile } from '@/core/interfaces/Auth';
import authContext from '@/core/context/authContext';

import { API_URL } from '@/config';

type LoginData = { email: string; password: string };

interface AuthState {
  isLoggedIn: boolean;
  id?: string;
  user_id?: string;
}

interface ParsedToken {
  aud: Array<string>;
  exp: number;
  sub: string;
}

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPermissionsList: builder.query<GetPermissionsList, void>({
      query: () => ({
        url: '/v2/users/me/permissions/',
      }),
    }),
    getMyProfile: builder.query<MyUserProfile, void>({
      query: () => ({
        url: '/v2/users/me/',
      }),
    }),
    login: builder.mutation<{ id: string; user_id: string; isLoggedIn: boolean }, LoginData>({
      async queryFn(arg) {
        const loginResponse = await axios.post<{
          access_token: string;
          token_type: string;
        }>(`${API_URL}/v2/auth/jwt/login`, `username=${arg.email}&password=${arg.password}`);

        authContext.token = loginResponse.data.access_token;
        localStorage.setItem('token', authContext.token);

        const data = JSON.parse(atob(authContext.token.split('.')[1]));
        data.id = data.sub;
        data.isLoggedIn = true;

        return { data };
      },
    }),
  }),
});

export const { useGetPermissionsListQuery, useGetMyProfileQuery, useLoginMutation } = extendedApi;
export const { getPermissionsList, getMyProfile } = extendedApi.endpoints;

const token = localStorage.getItem('token');
const data: AuthState = { isLoggedIn: false };

if (token) {
  const tokenParsed: ParsedToken = JSON.parse(atob(token.split('.')[1]));
  data.id = tokenParsed.sub;
  data.isLoggedIn = true;
}

const initialState: AuthState = {
  ...data,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setUser(state, action: PayloadAction<AuthState>) {
      state.id = action.payload.id;
      state.user_id = action.payload.user_id;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
  },
});

export const { setIsLoggedIn, setUser } = authSlice.actions;

const authSelector = (state: RootState): AuthState => state.auth;

export const selectIsLoggedIn = createSelector(authSelector, (state) => !!state.isLoggedIn);

export const selectUser = createSelector(authSelector, (state) => state);

export const handleUnauthorizedResponse = () => (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    // Redirect to login page
    window.location.href = '/login';
  }

  return Promise.reject(error);
};

// Attach interceptor to handle unauthorized response
axios.interceptors.response.use(undefined, handleUnauthorizedResponse);

export default authSlice.reducer;
