import { ReactNode } from 'react';

import { UseAccessControlProps, useAccessControl } from './useAccessControl';

type AccessControlProps = {
  children: ReactNode;
} & UseAccessControlProps;

export const AccessControl = ({ children, ...props }: AccessControlProps) => {
  const { isPermitted } = useAccessControl(props);

  if (isPermitted) {
    return children as JSX.Element;
  }

  return null;
};
