import * as React from 'react';

const GreyArchiveIcon = (props?: { style: React.CSSProperties }) => {
  return (
    <div {...props} style={{ width: '20px', height: '20px', marginLeft: '4px', marginRight: '0px', marginTop: '1px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          id="Vector"
          d="M2.50008 8.33333H1.66675V3.33578C1.66675 2.87419 2.04617 2.5 2.49325 2.5H17.5069C17.9634 2.5 18.3334 2.8649 18.3334 3.33578V8.33333H17.5001V16.6679C17.5001 17.1275 17.1293 17.5 16.6722 17.5H3.32791C2.87071 17.5 2.50008 17.1271 2.50008 16.6679V8.33333ZM15.8334 8.33333H4.16675V15.8333H15.8334V8.33333ZM3.33341 4.16667V6.66667H16.6667V4.16667H3.33341ZM7.50008 10H12.5001V11.6667H7.50008V10Z"
          fill="grey"
        />
      </svg>
    </div>
  );
};

export default GreyArchiveIcon;
