import { ReactNode } from 'react';

import * as S from './FullScreenLayout.styles';

type FullScreenLayoutProps = {
  children: ReactNode;
  renderTopBar?: () => ReactNode;
};

export const FullScreenLayout = ({ children, renderTopBar }: FullScreenLayoutProps) => {
  return (
    <S.Container>
      <S.TopBar>{renderTopBar?.()}</S.TopBar>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};
