import styled from 'styled-components';
import { FileUploadOutlined, ImageOutlined } from '@mui/icons-material';

import { colors } from '@/themes/colors';

export const Wrapper = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => theme.spacing(4)};

  border: ${({ error }) => (error ? `2px dashed ${colors.semantic.danger[500]}` : '2px dashed #bcc3cf')};
  border-radius: 4px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => theme.spacing(4)};

  border-radius: 4px;
  background: #f2f3f5;
`;

export const FileInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const BlueStrong = styled.strong`
  color: #04044b;
`;

export const FileNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileMetadata = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageOutlinedIcon = styled(ImageOutlined)`
  color: ${colors.neutral[500]};
  font-size: 4.8rem;
`;

export const FileUploadOutlinedIcon = styled(FileUploadOutlined)`
  color: #04044b;
`;
