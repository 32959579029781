const FolderIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.3334 6.66667V16.6667C18.3334 17.1269 17.9603 17.5 17.5001 17.5H2.50008C2.03985 17.5 1.66675 17.1269 1.66675 16.6667V5.83333H17.5001C17.9603 5.83333 18.3334 6.20643 18.3334 6.66667ZM10.3452 4.16667H1.66675V3.33333C1.66675 2.8731 2.03985 2.5 2.50008 2.5H8.67858L10.3452 4.16667Z"
          fill="#1063FF"
        />
      </svg>
    </div>
  );
};

export default FolderIcon;
