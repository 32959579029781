import { Nullable, ObjectValues, PaginatedData } from '@/core/interfaces/helpers';
import { UserTemp } from '@/core/interfaces/Measures';

import {
  CreateWorkPackage,
  WORK_PACKAGE_STATE_STATUS,
  WorkPackageInviteUser,
  WorkPackageNewUserData,
  WorkPackageType,
} from './WorkPackages';

export const PROJECT_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
} as const;

export const ProjectsTag = 'Projects' as const;

export type ProjectStatus = ObjectValues<typeof PROJECT_STATUS>;

export type ProjectWorkPackage = {
  id: string;
  name: string;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  state: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  number: string;
  archived: boolean;
};

export type ProjectSummary = {
  details: string;
  id: number;
  project_id: number;
};

export type GetProjectsParams = {
  limit?: number;
  offset?: number;
  status?: ProjectStatus;
  name?: string;
  companyId?: string;
  leadId?: string;
  createdAt?: string;
  archivedAt?: string;
  orderBy?: string;
  desc?: boolean;
};

export type ProjectCompany = {
  createdAt: string;
  id: string;
  isClient: false;
  logo: string;
  name: string;
  type: string;
  domains?: Array<string>;
  abbreviation?: string;
  projectsCount: number;
};

export type Project = {
  dueDate: string | number | Date;
  id: string;
  name: string;
  company: ProjectCompany;
  contributingParties: Array<ProjectCompany>;
  lead?: {
    id: string;
    name: string;
  };
  status: ProjectStatus;
  createdAt: string;
  archivedAt: string;
  workPackages: Array<ProjectWorkPackage>;
  nextReportingDate: string;
  overrideNextReportingDate: Nullable<string>;
  projectSummary: ProjectSummary;
};

export type GetProjectsList = PaginatedData<Project>;

export type ProjectLanguages = 'english' | 'german';

export type ReportingFrequency = 'every_week' | 'every_two_weeks' | 'every_month';

export const ReportingWeekDays = {
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
  Sunday: 'sunday',
} as const;

export const ProjectDateFormats = { 'dd.mm.yyyy': 'dd.mm.yyyy', 'mm/dd/yyyy': 'mm/dd/yyyy' } as const;

export const MonthWeek = { First: 'first', Second: 'second', Third: 'third', Fourth: 'fourth', Last: 'last' } as const;

export type ReportingMonthDay = {
  monthWeek: ObjectValues<typeof MonthWeek>;
  day: ObjectValues<typeof ReportingWeekDays> | string;
};

export type CreateProjectWorkPackage = Pick<CreateWorkPackage, 'number' | 'type' | 'name' | 'leaders' | 'contributors'>;

export type CreateProject = {
  name: string;
  description?: string;
  projectId?: string;
  client: string;
  clientLeaders: Array<WorkPackageInviteUser>;
  contributingParties?: Array<string>;
  contributingLeaders: Array<WorkPackageInviteUser>;
  consultantLeaders: Array<WorkPackageInviteUser>;
  releaseManager?: string;
  language: ProjectLanguages | string;
  timezone: string;
  dateFormat: ObjectValues<typeof ProjectDateFormats> | string;
  reportingFrequency: ReportingFrequency | string;
  reportingWeekDays: Array<ObjectValues<typeof ReportingWeekDays>> | Array<string>;
  reportingMonthDay: ReportingMonthDay;
  workPackages: Array<CreateProjectWorkPackage>;
  userPersonalization: Array<WorkPackageNewUserData>;
};

export type UpdateProject = Partial<CreateProject & { id: string }>;

export type SidebarProject = {
  companyId: string;
  companyLogo: Nullable<string>;
  companyName: string;
  id: string;
  name: string;
};

export type GetSidebarProjects = Array<SidebarProject>;

export type SidebarCompanyWithProjects = {
  id: string;
  logo: Nullable<string>;
  name: string;
  projects: Array<{
    id: string;
    name: string;
  }>;
};

type WorkPackageKpiStatus = 'active' | 'deactivated' | 'GREEN' | 'YELLOW' | 'RED';

type WorkPackageKpiState = 'GREEN' | 'YELLOW' | 'RED';

type WorkPackageKpi = {
  id: string;
  name: string;
  status: WorkPackageKpiStatus;
  state: WorkPackageKpiState;
};

type UserRelation = 'assignment' | 'invitation';

export type WorkPackageReport = {
  projectId: string;
  wpId: string;
  highlights: string;
  roadblocks: string;
  nextSteps: string;
};

export type GetProjectWorkPackage = {
  id: string;
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<{
    id: string;
    email: string;
    name: string;
    relationType: UserRelation;
    logo: string;
  }>;
  contributors: Array<WorkPackageInviteUser>;
  userPersonalization: Array<WorkPackageNewUserData>;
  onboardingDocument: string;
  kpis: Array<WorkPackageKpi>;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  state: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  updatedAt: string;
  report: WorkPackageReport | null;
};

export type GetProjectWorkPackages = PaginatedData<GetProjectWorkPackage>;

export type newWorkPackage = {
  archived: boolean;
  system_status: string;
  contributors: Array<string>;
  order_index: number;
  all_statuses: any;
  updated_at?: string;
  number: number;
  archived_date?: string;
  deleted_at?: string;
  onboarding_document?: string;
  id: number;
  status: string;
  state: string;
  leaders: Array<string>;
  parent_id?: number;
  created_at: string;
  name: string;
  project_id: number;
  internalUserMappings: any;
};

export type newMeasure = {
  measureFakeId?: number;
  measureId: number;
  measureStatus: string;
  measureMeasure: string;
  measureProblem: string;
  measuresPinned: boolean;
  measuresDueDate: string;
  measuresArchivedDate: string;
  measuresEscalationCount: number;
  measureNumber: number;
  responsibleUsers: Array<UserTemp>;
};

export type GetAllProjectWorkPackages = {
  wp: newWorkPackage;
  measuresArray: Array<newMeasure>;
};

export type GetProjectWorkPackagesParams = {
  projectId?: string;
  limit?: number;
  offset?: number;
  archived?: boolean;
  name?: string;
  orderBy?: string;
  desc?: boolean;
};

export type GetCompanyProjects = Array<Project>;

export type GetCompanyProjectsParams = {
  companyId: string;
};
export interface responsibleUserData {
  id: string;
  name: string;
}

export interface Topic {
  id: number;
  topic: string;
  orderId: number;
  duration: string;
  responsibleUser: Array<responsibleUserData>;
  responsibleUsers: Array<responsibleUserData>;
  urlLink?: string;
}

export interface ProjectData {
  projectId: number;
  orderId: number;

  date: string;
  topics: Array<Topic>;
  urlLink?: string;
}
