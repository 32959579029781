const ChevronIcon = ({ direction, className = undefined }: { direction: string; className?: string | undefined }) => {
  const deg: { [key: string]: string } = {
    up: '180deg',
    right: '-90deg',
    down: '0deg',
    left: '90deg',
  };

  return (
    <div
      style={{
        width: '24px',
        height: '24px',
        transform: `translateY(1px) rotate(${deg[direction ?? 'down']})`,
        transition: 'all 0.2s ease-in-out',
      }}
      className={className}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ChevronIcon;
