export const UserManagementTag = 'UserManagement' as const;

export type UserManage = {
  id: string;
  name: string;
  email: string;
  company: string;
  role: number;
  projects: [];
  work_packages: [];
};
