import styled, { css } from 'styled-components';

import SignUpBackground from '@/assets/svg/sign-in-background.svg';
import { colors } from '@/themes/colorsV2';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
  background-color: white;
`;

export const IllustrationWrapper = styled.div`
  svg {
    width: 100%;
    max-width: 100%;
    height: fit-content;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: flex-start;
    // padding-top: ${({ theme }) => theme.spacing(0)};
    // padding-bottom: ${({ theme }) => theme.spacing(8)};
  padding-left: 16px; // Ensure alignment with the rest of the content

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center; // Align the logo to the left
  padding-left: 16px; // Adjust this to match the text's starting point
  margin-top: 48px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;

    // ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
  //   max-width: 462px;
  // }

    // ${({ theme }) => theme.breakpoints.up('laptop')} {
  //   max-width: 592px;
  // }
`;

export const LogoColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 16px;

  svg {
    width: 125px;
    height: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    max-width: 76%;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    svg {
      width: 120px;
      height: 24px;
    }

    padding: ${({ theme }) => theme.spacing(0)};
  }
`;

export const Split = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
  // overflow-y: hidden;
  height: 100vh;

  ${({ theme }) => theme.breakpoints.down('tabletBig')} {
    grid-template-columns: repeat(1, 1fr);
    overflow-x: visible;
    overflow-y: visible;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // object-fit: contain;
  padding: 0 24px;
  background-color: white;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('tabletBig')} {
    padding: 0;
  }
`;

export const ContainerRight = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: ${colors.blue.default};
  z-index: 3;
  position: fixed;
  right: 0;
  pointer-events: none;

  ${({ theme }) => theme.breakpoints.down('tabletBig')} {
    display: none;
  }
`;

export const Copy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: white;
  word-wrap: break-word;
  padding: 10% 5rem 0 5rem;
  z-index: 2;
  align-self:
  position: absolute;
`;

export const Bottom = styled.div`
  display: flex;
  overflow-y: hidden;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
`;

export const ElyvateContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-inline: 24px;
  width: 100%;
`;

export const ElyvateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 452px;
  margin-left: 34px;
  margin-bottom: 24px;
`;

export const BeryllsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 24px;
  padding-bottom: 8px;
  background-color: ${colors.blue.default};
`;

export const BackgroundColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
`;

export const BackgroundRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface ColumnProps {
  withBackground?: boolean;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(2)}`};

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(4)}`};
  }

  ${({ withBackground }) =>
  withBackground &&
  css`
      display: none;
      background-image: url(${SignUpBackground});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;

      ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
        display: flex;
        max-width: 50%;
      }

      ${({ theme }) => theme.breakpoints.up('laptop')} {
        padding: ${({ theme }) => theme.spacing(0)};
      }
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; // Align content to the left
  width: 100%;
  margin: 0 auto;
  padding: 12px 24px; // Same padding as LogoWrapper to align properly

  // ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
  //   max-width: 462px;
  // }

  // ${({ theme }) => theme.breakpoints.up('laptop')} {
  //   max-width: 592px;
  // }
`;
