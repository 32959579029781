import React from 'react';

import { SignInLayout } from '@/layouts/SignInLayout';
import * as S from '@/pages/SignInPage/SignInPage.styles'; // Reusing styles from SignInPage.styles.ts

import { SkeletonLoading } from '@/stories/Primitives/SkeletonLoading/SkeletonLoading';

const SignInSkeleton: React.FC = () => {
  return (
    <SignInLayout>
      <S.ContentWrapper
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '0 24px',
        }}
      >
        <S.Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '452px', // Match width with the text fields
            marginBottom: '24px',
            // paddingLeft: '16px', // Align with labels
          }}
        >
          {/* Simulate the title with a skeleton */}
          <SkeletonLoading variant="text" width={200} height={32} style={{ marginBottom: '20px' }} />

          {/* Simulate the subtitle with a skeleton */}
          <SkeletonLoading height={48} width={300} style={{ marginBottom: '24px' }} />
        </S.Row>
        <S.Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ width: '452px' }}>
            {/* <SkeletonLoading variant="rectangular" width={452} height={48} style={{ marginBottom: '16px' }} />
            <SkeletonLoading variant="rectangular" width={452} height={48} style={{ marginBottom: '16px' }} /> */}
            <SkeletonLoading variant="rectangular" width={480} height={300} style={{ marginBottom: '18px' }} />
          </div>
        </S.Row>
      </S.ContentWrapper>
    </SignInLayout>
  );
};

export default SignInSkeleton;
