import styled from 'styled-components';

import { colors } from '@/themes/colors';
import SignUpBackground from '@/assets/svg/sign-up-background.svg';
import loadingLogo from '@/assets/svg/loadingLogo.svg';
import loadingelyvate from '@/assets/svg/loadingelyvate.svg';

export const Wrapper = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.primary[700]};
  background-image: url(${SignUpBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    background-position: right;
    background-size: 71%;
  }
`;

export const CenteredContainer = styled.div`
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoadingLogo = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${loadingLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LoadingElyvate = styled.div`
  width: 150px;
  height: 40px;
  background-image: url(${loadingelyvate});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;