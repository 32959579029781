import * as React from 'react';

const FigmaFilledDeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M14.6665 5.00008H18.8332V6.66675H17.1665V17.5001C17.1665 17.9603 16.7934 18.3334 16.3332 18.3334H4.6665C4.20627 18.3334 3.83317 17.9603 3.83317 17.5001V6.66675H2.1665V5.00008H6.33317V2.50008C6.33317 2.03985 6.70627 1.66675 7.1665 1.66675H13.8332C14.2934 1.66675 14.6665 2.03985 14.6665 2.50008V5.00008ZM7.99984 9.16675V14.1667H9.6665V9.16675H7.99984ZM11.3332 9.16675V14.1667H12.9998V9.16675H11.3332ZM7.99984 3.33341V5.00008H12.9998V3.33341H7.99984Z"
        fill="#7F8185"
      />
    </svg>
  );
};

export default FigmaFilledDeleteIcon;
