import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCase } from 'change-case';

import { apiSlice } from '@/core/store/apiSlice';
import {
  GetProjectsParams,
  ProjectsTag,
  GetProjectsList,
  Project,
  CreateProject,
  GetSidebarProjects,
  SidebarCompanyWithProjects,
  GetProjectWorkPackagesParams,
  GetProjectWorkPackages,
  UpdateProject,
  GetCompanyProjectsParams,
  GetCompanyProjects,
  GetAllProjectWorkPackages,
} from '@/core/interfaces/Projects';

import { RootState } from './store';

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectWorkPackages: builder.query<GetProjectWorkPackages, GetProjectWorkPackagesParams>({
      query: ({ projectId, ...params }) => ({
        url: `/v2/projects/${projectId}/work-packages/`,
        params: {
          ...params,
          ...(params?.orderBy && { orderBy: snakeCase(params.orderBy) }),
        },
      }),
    }),
    getSidebarItems: builder.query<Array<SidebarCompanyWithProjects>, void>({
      query: () => ({
        url: '/v2/projects/values/?order_by=name&status=active&attribute=name&attribute=company_id&attribute=company_name&attribute=company_logo',
      }),
      transformResponse: (response: any) => {
        const companies = (response as GetSidebarProjects).reduce<{ [key: string]: SidebarCompanyWithProjects }>(
          (acc, { companyId, companyName, companyLogo, ...project }) => ({
            ...acc,
            [companyId]: {
              id: companyId,
              name: companyName,
              logo: companyLogo,
              projects: [...(acc[companyId]?.projects || []), project],
            },
          }),
          {},
        );

        return (Object.values(companies) as Array<SidebarCompanyWithProjects>).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      },
    }),
    getActiveProject: builder.query<Project, string | void>({
      async queryFn(projectId, { getState }, _extraOptions, fetchWithBQ) {
        // Use a new variable to store the project ID
        let resolvedProjectId = projectId;

        // If projectId is not provided, use the activeProjectId from the state
        if (!resolvedProjectId) {
          const { activeProjectId } = (getState() as RootState).projects;

          if (!activeProjectId) {
            return { error: { status: 'CUSTOM_ERROR', error: 'Active project ID is not set' } };
          }

          resolvedProjectId = activeProjectId;
        }

        const activeProject = await fetchWithBQ({ url: `/v2/projects/${resolvedProjectId}/` });

        if (activeProject.error) {
          return { error: activeProject.error };
        }

        return { data: activeProject.data as Project };
      },
      providesTags: [ProjectsTag],
    }),
    getProjectsList: builder.query<GetProjectsList, GetProjectsParams | void>({
      query: (params = {}) => ({
        url: `/v2/projects/`,
        params: {
          ...params,
          ...(params?.orderBy && { orderBy: snakeCase(params.orderBy) }),
        },
      }),
      providesTags: (result) =>
        result?.items ? [...result.items.map(({ id }) => ({ type: ProjectsTag, id })), ProjectsTag] : [ProjectsTag],
    }),
    createProject: builder.mutation<Project, CreateProject>({
      query: (body) => ({
        url: `/v2/projects/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ProjectsTag],
    }),
    getProject: builder.query<Project, string>({
      query: (projectId) => ({
        url: `/v2/projects/${projectId}/`,
      }),
      providesTags: (result) => [{ type: ProjectsTag, id: result?.id }, ProjectsTag],
    }),
    editProject: builder.mutation<Project, UpdateProject>({
      query: ({ id: projectId, ...body }) => ({
        // url: `/v2/projects/${projectId}/`,
        url: `/v2/projects/${projectId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [ProjectsTag],
    }),
    deleteProject: builder.mutation<void, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `/v2/projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ProjectsTag],
    }),
    getCompanyProjects: builder.query<GetCompanyProjects, GetCompanyProjectsParams>({
      query: (companyId) => ({
        url: `/v2/projects/company/${companyId}/`,
      }),
    }),
    getAllWorkPackages: builder.query<Array<GetAllProjectWorkPackages>, GetProjectWorkPackagesParams>({
      query: ({ projectId }) => ({
        url: `/v2/projects/${projectId}/work-packages-info/?measure=true`,
      }),
    }),
    escalateMeasure: builder.mutation<void, { wpId: string; measureId: string }>({
      query: ({ wpId, measureId }) => ({
        url: `/v2/work-packages/${wpId}/measures/${measureId}/escalate`,
        method: 'PATCH',
      }),
    }),
    getDummyExport: builder.query<void, { projectId: string }>({
      query: ({ projectId }) => ({
        //url: `/v2/projects/${projectId}/download_dummy_report/`,
        url: `/v2/projects/%7Bproject_id%7D/download_dummy_report/`,
      }),
    }),
  }),
});

export const {
  useGetSidebarItemsQuery,
  useGetActiveProjectQuery,
  useGetProjectsListQuery,
  useCreateProjectMutation,
  useGetProjectWorkPackagesQuery,
  useGetProjectQuery,
  useEditProjectMutation,
  useGetCompanyProjectsQuery,
  useGetAllWorkPackagesQuery,
  useEscalateMeasureMutation,
  useDeleteProjectMutation,
  useGetDummyExportQuery,
} = extendedApi;
export const { getProjectsList, getSidebarItems, getActiveProject } = extendedApi.endpoints;

interface ProjectsState {
  activeProjectId?: string;
}

const initialState: ProjectsState = {
  activeProjectId: undefined,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setActiveProjectId(state, action: PayloadAction<string | undefined>) {
      state.activeProjectId = action.payload;
    },
  },
});

export const { setActiveProjectId } = projectsSlice.actions;

const projectsSelector = (state: RootState): ProjectsState => state.projects;

export const selectActiveProjectId = createSelector(projectsSelector, (state) => state.activeProjectId);

export default projectsSlice.reducer;
