import { Box, Dialog, DialogProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCreateCompanyMutation } from '@/core/store/companiesSlice';
import { CreateCompany } from '@/core/interfaces/Companies';

import * as S from './AddCompanyModal.styles';
import { AddCompanyForm } from './AddCompanyForm';

export type AddCompanyModalProps = Pick<DialogProps, 'open'> & {
  onCancel: () => void;
  onSubmitSuccess: (company: CreateCompany) => void;
};

export const AddCompanyModal = ({ open, onCancel, onSubmitSuccess }: AddCompanyModalProps) => {
  const { t } = useTranslation('Companies');
  const [createCompany] = useCreateCompanyMutation();

  const handleSubmit = async (data: CreateCompany) => {
    await createCompany(data);

    onSubmitSuccess(data);
  };

  return (
    <Dialog open={open} PaperComponent={S.DialogContainer}>
      <Box mb={4}>
        <Typography variant="subtitle1-bold">{t('addModal.title')}</Typography>
      </Box>
      <AddCompanyForm onSubmit={handleSubmit} onCancel={onCancel} />
    </Dialog>
  );
};
