export const typography = {
  fontFamily: ['"Inter"', '"Faktum"', 'sans-serif'].join(','),
  fontSize: 16,
  h1: {
    fontFamily: 'Faktum',
    fontWeight: 300,
    fontSize: '4.8rem',
    lineHeight: '6.4rem',
  },
  'h1-bold': {
    fontFamily: 'Faktum',
    fontWeight: 600,
    fontSize: '4.8rem',
    lineHeight: '6.4rem',
  },
  h3: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '3.2rem',
    lineHeight: '4.8rem',
  },
  'h3-bold': {
    fontFamily: 'Faktum',
    fontWeight: 500,
    fontSize: '3.2rem',
    lineHeight: '4.8rem',
  },
  subtitle1: {
    fontFamily: 'inter',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  'subtitle1-bold': {
    fontFamily: 'Faktum',
    fontWeight: 600,
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
  },
  subtitle2: {
    fontFamily: 'Faktum',
    fontWeight: 300,
    fontSize: '2.0rem',
    lineHeight: '2.6rem',
  },
  'subtitle2-bold': {
    fontFamily: 'Faktum',
    fontWeight: 600,
    fontSize: '2.0rem',
    lineHeight: '2.6rem',
  },
  body1: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  'body1-bold': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  body2: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '20px',
  },
  'body2-bold': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '1.4rem',
    lineHeight: '2.0rem',
  },
  caption1: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
  'caption1-bold': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
  'caption1-capital': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.0rem',
    lineHeight: '1.4rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase' as const,
  },
  'tag1-capital': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0.0125rem',
    textTransform: 'uppercase' as const,
  },
};
