import styled from 'styled-components';
import { FileUploadOutlined, ImageOutlined } from '@mui/icons-material';
import { LinearProgress, linearProgressClasses } from '@mui/material';

import { colors } from '@/themes/colors';
import { colors as colorsv2 } from '@/themes/colorsV2';

export const Wrapper = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 96px;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: 8px;

  padding: 24px;

  border: ${({ error }) =>
    error ? `2px dashed ${colors.semantic.danger[500]}` : `1px dashed ${colorsv2.grey.medium}`};
  border-radius: var(--radius-m);

  &:hover {
    cursor: pointer;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => theme.spacing(4)};
  background: var(--color-core-white);
  border: 1px dashed var(--color-grey-medium);
  border-radius: var(--radius-m);
`;

export const FileInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const BlueStrong = styled.strong`
  color: #04044b;
`;

export const FileNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileMetadata = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageOutlinedIcon = styled(ImageOutlined)`
  color: ${colors.neutral[500]};
  font-size: 4.8rem;
`;

export const FileUploadOutlinedIcon = styled(FileUploadOutlined)`
  color: #04044b;
`;

export const FileDropStyle = {
  backgroundColor: '#E7EFFF',
  opacity: '1',
  color: '#1063FF',
  fontSize: '24px',
};

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D2EEDA',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#188839',
  },
}));

export const SupportingDocument = styled.div`
  height: 48px;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: ${colorsv2.grey.extraLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
