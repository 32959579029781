import { Nullable, ObjectValues, PaginatedData } from '@/core/interfaces/helpers';

export const MeasuresTag = 'Measures' as const;

export const MEASURE_STATUS = {
  not_started: 'not_started',
  in_progress: 'in_progress',
  completed: 'completed',
  off_track: 'off_track',
  support_required: 'support_required',
  archived: 'archived',
  escalated: 'escalated',
} as const;

export const MEASURE_TYPE = {
  MEASURE: 'measure',
  KPI_MEASURE: 'kpi_measure',
  ARCHIVED: 'archived',
} as const;

export type MeasureStatus = ObjectValues<typeof MEASURE_STATUS>;
export type MeasureType = ObjectValues<typeof MEASURE_TYPE>;

export type UserTemp = {
  id: string;
  name: string;
};

export type MeasureForm = {
  problem: string;
  measure: string;
  status: MeasureStatus;
  dueDate: string;
  filePathId?: string;
  file_urls?: Array<string>;
  responsibleUserIds?: Array<string>;
  responsibleUsers?: Array<UserTemp>;
  pinned: boolean;
  supportingDocument: Nullable<string>;
};

export type CreateMeasure = MeasureForm & {
  workPackageId: string;
};

export type UpdateMeasure = CreateMeasure;

export type MeasureItem = CreateMeasure & {
  id: string;
  createdAt: string;
  dueDates: Array<string>;
  leaders: Array<{
    id: string;
    name: string;
  }>;
};

export type GetMeasureItem = Omit<MeasureItem, 'responsibleUserIds'> & {
  responsibleUsers: Array<{
    name: string;
    id: string;
  }>;
};

export type GetMeasuresList = PaginatedData<GetMeasureItem>;

export type GetMeasuresParams = {
  workPackageId: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
  desc?: boolean;
  status?: MeasureStatus;
  search?: string;
};

// src/core/interfaces/Measures.ts
export type GetMeasuresListParams = Omit<GetMeasuresParams, 'orderBy'> & {
  orderBy?: string | Array<string>;
};

export type GetMeasureDetails = {
  id: string;
  workPackageId: string;
  problem: string;
  measure: string;
  status: MeasureStatus;
  dueDate: string;
  filePathId?: string;
  file_urls?: Array<string>;
  dueDates: Nullable<Array<string>>;
  responsibleUserIds: Array<string>;
  responsibleUsers: Array<UserTemp>;
  supportingDocument: string;
  pinned: boolean;
  createdBy: {
    id: string;
    name: string;
  };
  updatedBy: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type GetMeasuresSnapshot = PaginatedData<GetMeasureItem>;

export type GetMeasuresSnapshotParams = {
  workPackageId: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
  desc?: boolean;
  status?: MeasureStatus;
  search?: string;
};
