// ListSelectElement.tsx
import React from 'react';
import { Typography } from '@mui/material';

import { colors } from '@/themes/colorsV2';

import { ListElement, ListImage } from './ListSelectElement.styles';
import { ImageOutlinedIcon } from '../FileUpload/FileUpload.styles';

interface ListSelectElementProps {
  text?: string;
  icon?: string;
  active?: boolean | 'undefined';
  onClick?: () => void;
}

const ListSelectElement: React.FC<ListSelectElementProps> = ({ text, icon, active, onClick }) => {
  return (
    <ListElement hasIcon={false} onClick={onClick} active={active}>
      <div
        style={{
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: colors.core.white,
        }}
      >
        {icon ? <ListImage src={icon} alt={text} /> : <ImageOutlinedIcon />}
      </div>

      <Typography color={active ? colors.core.black : colors.grey.default} fontWeight={600} fontSize={14}>
        {text}
      </Typography>
    </ListElement>
  );
};

ListSelectElement.defaultProps = {
  text: undefined,
  icon: undefined,
  active: undefined,
  onClick: undefined,
};

export default ListSelectElement;
