import React, { useEffect, useState } from 'react';
import { generatePath, matchPath, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/core/store/store';
import { selectUser, setIsLoggedIn } from '@/core/store/authSlice';
import {
  getActiveProject,
  getSidebarItems,
  selectActiveProjectId,
  setActiveProjectId,
} from '@/core/store/projectsSlice';
import { LoadingOverlay } from '@/core/components/LoadingOverlay/LoadingOverlay';
import { guestPaths, paths } from '@/core/router/paths';
import { resetApiState } from '@/core/store/apiSlice';
import { allRoutes } from '@/core/router/routes';
import { useAccessControl } from '@/core/components/AccessControl';
import { RoutePaths } from '@/core/providers/RoutesProvider';
import { UnauthorizedPage } from '@/pages';

export type AppInitializeProps = {
  children: React.ReactNode;
};

export const AppInitialize: React.FC<AppInitializeProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { verifyPermissions } = useAccessControl();

  const activeProjectId = useAppSelector(selectActiveProjectId);

  const user = useAppSelector(selectUser);

  const [isInitialized, setIsInitialized] = useState(false);

  const projectIdRegex = /project\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
  const [_, activeProjectIdFromUrl] = (location.pathname.match(projectIdRegex) || []) as Array<string>;

  const isGuestPath = guestPaths.some((path) => location.pathname.startsWith(path));
  const currentRoute = allRoutes.find(({ path = '' }) => matchPath(path, location.pathname));
  const hasPermission = verifyPermissions({ permissions: currentRoute?.routePermissions });

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const checkAuthAndRedirect = () => {
      const token = localStorage.getItem('token');

      if (token) {
        const decodedJwt = parseJwt(token);

        if (decodedJwt.exp * 1000 < Date.now()) {
          // Token is expired
          dispatch(setIsLoggedIn(false));
          localStorage.removeItem('token'); // Clear the expired token
        } else {
          // Token is valid
          dispatch(setIsLoggedIn(true));
        }
      } else {
        dispatch(setIsLoggedIn(false));
      }

      setIsInitialized(true);
    };

    checkAuthAndRedirect();
  }, [dispatch]); // Removed other dependencies to prevent unnecessary effect invocations

  // Separate useEffect for navigation to decouple it from the authentication check logic
  useEffect(() => {
    if (isInitialized && !user.isLoggedIn && !isGuestPath) {
      navigate(paths.signIn, { state: { from: location }, replace: true });
    }
  }, [isInitialized, user.isLoggedIn, isGuestPath, navigate, location]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const { data: companiesSidebarData } = await dispatch(getSidebarItems.initiate());

      const newActiveProjectId = activeProjectIdFromUrl || companiesSidebarData?.[0]?.projects?.[0].id;

      dispatch(setActiveProjectId(newActiveProjectId));

      await dispatch(getActiveProject.initiate());

      setIsInitialized(true);
    };

    if (!isInitialized) {
      if (user.id) {
        dispatch(setIsLoggedIn(true));
        fetchInitialData();
      } else {
        dispatch(setIsLoggedIn(false));
        setIsInitialized(true);
      }
    }
  }, [dispatch, user, isInitialized]);

  useEffect(() => {
    const refetchActiveProject = async () => {
      await dispatch(getActiveProject.initiate(undefined, { forceRefetch: true }));
    };

    if (activeProjectId) {
      refetchActiveProject();
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (isInitialized) {
      if (user) {
        setIsInitialized(false);
      } else {
        dispatch(resetApiState());
        setIsInitialized(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname === '/' && activeProjectId) {
      navigate(generatePath(RoutePaths.project.tabs.overview, { projectId: activeProjectId }));
    }
  }, [location, activeProjectId]);

  if (!isInitialized) {
    return <LoadingOverlay />;
  }

  if (isInitialized && !user.isLoggedIn && !isGuestPath) {
    return <Navigate to={paths.signIn} state={{ from: location }} replace />;
  }

  if (isInitialized && !!user.isLoggedIn && !hasPermission) {
    return <UnauthorizedPage />;
  }

  return <div>{children}</div>;
};
