import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  AlertProps,
  Box,
  CircularProgress,
  Collapse,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ImageOutlined as ImageOutlinedIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@mui/system';

import { Company } from '@/core/interfaces/Companies';
import { useResponsive } from '@/core/hooks/useResponsive';
import {
  selectActiveProjectId,
  setActiveProjectId,
  useGetActiveProjectQuery,
  useGetSidebarItemsQuery,
} from '@/core/store/projectsSlice';
import { RoutePaths } from '@/core/providers/RoutesProvider';
import { SUBMENUS } from '@/core/components/Navigation/Navigation.constants';
import { useAppDispatch, useAppSelector } from '@/core/store/store';
import { selectIsLoggedIn, setUser, useGetMyProfileQuery } from '@/core/store/authSlice';
import { paths } from '@/core/router/paths';
import { AccessControl, useAccessControl } from '@/core/components/AccessControl';
import { USER_PERMISSION } from '@/core/interfaces/Auth';
import { useGetCompaniesValuesQuery } from '@/core/store/companiesSlice';
import ListSelect from '@/core/componentsv2/ListSelect/ListSelect';
import ListSelectElement from '@/core/componentsv2/ListSelectElement/ListSelectElement';
import { AddCompanyModal } from '@/pages/CompaniesListPage/components/AddCompanyModal/AddCompanyModal';
import { colors, colors as colorsV2 } from '@/themes/colorsV2';
import UserIcon from '@/assets/svg/figma_user';
import UserActiveIcon from '@/assets/svg/figma_user_active';
import CompanyIcon from '@/assets/svg/figma_company';
import CompanyActiveIcon from '@/assets/svg/figma_company_active';
import ProjectsIcon from '@/assets/svg/figma_projects';
import ProjectsActiveIcon from '@/assets/svg/figma_projects_active';
import ChevronIcon from '@/assets/svg/figma_chevron';
import authContext from '@/core/context/authContext';
import { Project } from '@/core/interfaces/Projects';
import { selectWorkPackageAdded, setWorkPackageAdded } from '@/core/store/workPackagesSlice';
import { fetchImageWithHeaders, headersToObject } from '@/utils/utils';
import FolderIcon from '@/assets/svg/figma_folder_inactive';
import FigmaSearchIcon from '@/assets/svg/figma_search';
import { TypographyV2 } from '@/themes/typographyV2';
import ShortSearchBar from '@/core/componentsv2/ShortSearchBar/ShortSearchBar';

import { TrafficLightTag } from '../TrafficLightTag';
import * as S from './Navigation.styles';

export type SidebarItemsProps = {
  isDrawerOpen: boolean;
  onDrawerToggle: (toggled: boolean) => void;
  isDrawerHover: boolean;
  showCompanyMenu: boolean;
  toggleCompanyMenu: () => void;
  isCompanyMenuExpanded: boolean;
};

export type companyData = {
  id: number;
  logo: string;
  name: string;
  projects: object;
};

export const SidebarItems: React.FC<SidebarItemsProps> = ({
  isDrawerOpen,
  onDrawerToggle,
  isDrawerHover,
  showCompanyMenu,
  toggleCompanyMenu,
  isCompanyMenuExpanded,
}) => {
  const { t } = useTranslation('Common');
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = authContext.token;
  const [shouldDisplay] = useResponsive();
  const isMobileNav = !shouldDisplay.up('laptop');

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const activeProjectId = useAppSelector(selectActiveProjectId);
  const workPackageAdded = useAppSelector(selectWorkPackageAdded);
  const [currentRoute, setCurrentRoute] = useState(RoutePaths);
  const [errorPath, setErrorPath] = useState<boolean>(false);

  const { isPermitted: canCreateCompanyPermission } = useAccessControl({
    permissions: [USER_PERMISSION.COMPANY_CREATE],
  });

  const getActiveCompanyById = (id: number, companies: any) => {
    return companies.filter((company: any) => Number(company.id) === Number(id));
  };

  interface ActiveCompany {
    companyId: number;
  }

  useEffect(() => {
    // Load active company and project from localStorage on component mount
    const activeCompany = localStorage.getItem('activeCompany');
    const activeProject = localStorage.getItem('activeProject');

    // If active company and project are found, set them in the state
    if (activeCompany && activeProject) {
      dispatch(setActiveProjectId(activeProject));
    }
  }, [dispatch]);

  const handleChangeActiveCompany = (companyId: ActiveCompany | null): void => {
    const data = getActiveCompanyById(companyId?.companyId as number, processedSidebarCompanies);
    setActiveCompanyData(data[0]);

    // Store active company in localStorage
    localStorage.setItem('activeCompany', String(companyId?.companyId));

    // Expand the active project's submenu
    const activeProjectIdFromStorage = localStorage.getItem('activeProject');

    if (activeProjectIdFromStorage) {
      expandProjectSubmenu(activeProjectIdFromStorage);
    }

    // Change active project to first project in the company
    changeActiveProject(data[0].projects[0].id);
  };

  // Function to toggle the expansion state of a project's submenu
  const toggleProjectSubmenu = (projectId: string) => {
    setExpandedProject((prevExpanded) => (prevExpanded === projectId ? null : projectId));
  };

  const expandProjectSubmenu = (projectId: string) => {
    setExpandedProject(projectId);
  };

  const isProjectSubmenuExpanded = (projectId: string) => expandedProject === projectId;

  const [showAddCompanyModal, setShowCompanyModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{ message: string } & Pick<AlertProps, 'severity'>>();
  const [companyNameSearch, setCompanyNameSearch] = useState('');

  const { data: companySearch } = useGetCompaniesValuesQuery(
    { attribute: ['name', 'logo'], search: companyNameSearch },
    { skip: companyNameSearch.length < 3 },
  );

  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

  const initialState = {
    wps: true, // Set it to true to toggle on by default
  };

  const {
    data: sidebarCompaniesData,
    refetch: refetchSidebarCompaniesData,
    isSuccess,
    isFetching,
  } = useGetSidebarItemsQuery(undefined, {
    skip: !isLoggedIn || !activeProjectId,
  });

  const [activeCompanyData, setActiveCompanyData] = useState<companyData | null>(null);

  useEffect(() => {
    if (workPackageAdded) {
      refetchSidebarCompaniesData();
      refetchSidebarCompaniesData().then(() => {
        dispatch(setWorkPackageAdded(false));
      });
    }
  }, [workPackageAdded, dispatch, refetchSidebarCompaniesData]);

  const [processedSidebarCompanies, setProcessedSidebarCompanies] = useState<Array<companyData>>([]);

  useEffect(() => {
    if (sidebarCompaniesData && sidebarCompaniesData.length > 0) {
      // Obtain valid logos
      Promise.all(
        sidebarCompaniesData?.map((company) => {
          if (company.logo) {
            return getImageUrl(company.logo)
              .then((imageUrl) => ({
                ...company,
                id: parseInt(company.id, 0),
                logo: imageUrl,
              }))
              .catch((error) => {
                console.error(error);
                return {
                  ...company,
                  id: parseInt(company.id, 0),
                  logo: '',
                };
              });
          } else {
            return {
              ...company,
              id: parseInt(company.id, 0),
              logo: '',
            };
          }
        }),
      ).then((updatedCompanies) => {
        setProcessedSidebarCompanies(updatedCompanies);

        // Set active company
        const storedCompanyId = localStorage.getItem('activeCompany');

        if (storedCompanyId) {
          const storedCompany = updatedCompanies.find((company) => String(company.id) === storedCompanyId);

          if (storedCompany) {
            setActiveCompanyData({
              id: storedCompany.id as unknown as number,
              logo: storedCompany.logo as string,
              name: storedCompany.name,
              projects: storedCompany.projects,
            });

            const storedProjectId = localStorage.getItem('activeProject');

            if (storedProjectId && storedCompany.projects.some((proj: any) => String(proj.id) === storedProjectId)) {
              expandProjectSubmenu(storedProjectId);
            }

            return;
          }
        }

        const defaultCompany = updatedCompanies[0];
        setActiveCompanyData({
          id: defaultCompany.id as unknown as number,
          logo: defaultCompany.logo as string,
          name: defaultCompany.name,
          projects: defaultCompany.projects,
        });
      });
    }
  }, [sidebarCompaniesData]);

  const { data: activeProjectData, isFetching: isActiveProjectFetching } = useGetActiveProjectQuery();

  // const { data: companyProjectsData } = useGetCompanyProjectsQuery(
  //   { companyId: activeCompanyData.id as unknown as string },
  //   { skip: !activeCompanyData.id },
  // );

  const toggleSubmenu = (submenu: string) => {
    setActiveSubmenu((prevSubmenu) => (prevSubmenu === submenu ? null : submenu));
  };

  const checkIsActive = (check: string, exact = true) => {
    const match = matchPath(
      {
        path: check,
        end: exact,
      },
      pathname,
    );

    return !!match;
  };

  const checkIsExpanded = (check: string) => {
    return activeSubmenu === SUBMENUS.wps;
  };

  const handleSignOut = async () => {
    localStorage.clear();
    authContext.token = '';
    dispatch(setUser({ id: '', user_id: '', isLoggedIn: false }));
  };

  const changeActiveProject = (projectId: string) => {
    dispatch(setActiveProjectId(projectId));

    // Only navigate if the user is not already on the project overview page
    const projectOverviewPath = generatePath(RoutePaths.project.tabs.overview, { projectId });
    navigate(projectOverviewPath);

    // Store active project in localStorage
    localStorage.setItem('activeProject', projectId);

    // Expand the active project
    expandProjectSubmenu(projectId);

    if (isCompanyMenuExpanded) {
      toggleCompanyMenu();
    }
  };

  // Attempt to only close company menu after fetching. Unfortunately it is not consistant.
  // useEffect(() => {
  //   if (isCompanyMenuExpanded && !isActiveProjectFetching) {
  //     toggleCompanyMenu();
  //   }
  // }, [isActiveProjectFetching]);

  const [isWpsExpanded, setIsWpsExpanded] = useState(true); // Start expanded by default

  const toggleWps = () => {
    setIsWpsExpanded((prevIsWpsExpanded) => !prevIsWpsExpanded);
  };

  useEffect(() => {
    if (!isActiveProjectFetching) {
      setActiveSubmenu(null);
    }
  }, [isActiveProjectFetching]);

  // Define state and function for toggling the submenu
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);

  // Function to toggle the submenu
  const toggleOverview = () => {
    setIsOverviewExpanded(!isOverviewExpanded);
  };

  const containerMountedStyle = { animation: 'inAnimation 150ms ease-in' };
  const containerUnmountedStyle = {
    animation: 'outAnimation 170ms ease-out',
    animationFillMode: 'forwards',
  };

  const wrapperMountedStyle = { animation: 'inAnimationWrapper 150ms ease-in' };
  const wrapperUnmountedStyle = {
    animation: 'outAnimationWrapper 170ms ease-out',
    animationFillMode: 'both',
  };

  const { data: myProfileData } = useGetMyProfileQuery(undefined);

  const handleCompanyEdit = (company: Company) => {
    setAlertMessage({
      severity: 'success',
      message: `Changes for company “${company.name}” successfully saved`,
    });
  };

  // State to manage the expansion of project submenus
  const [expandedProject, setExpandedProject] = useState<string | null>(activeProjectId ?? null);

  const getImageUrl = async (logo: string) => {
    // Regular expression to check if logo is a valid URL
    const urlRegex = /^https?:\/\/[^\/]+\/v2\/files\/\?key=.+$/;

    if (urlRegex.test(logo)) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      const headersObj = headersToObject(headers);
      const imageUrl = await fetchImageWithHeaders(logo, headersObj);
      return imageUrl;
    }

    return '';
  };

  // Function to flatten the paths object and return an array of paths
  const flattenPaths = (obj: any, prefix = ''): Array<string> => {
    return Object.keys(obj).reduce<Array<string>>((acc, key) => {
      const value = obj[key];
      const path = prefix + (key === 'index' ? '' : '/' + key);

      if (typeof value === 'string') {
        acc.push(value);
      } else {
        acc.push(...flattenPaths(value, path));
      }

      return acc;
    }, []);
  };

  const allPaths = flattenPaths(RoutePaths);

  // Check if the current path matches any of the paths in the RoutePaths object
  useEffect(() => {
    const isPathMatched = allPaths.some((pattern) => matchPath(pattern, pathname));
    setErrorPath(isPathMatched);
  }, [pathname]);

  //used for 404 loading page sidebar
  const opacities = [1, 0.5, 0.25];

  const shimmer = keyframes`
  0% {
    background-position: -40px 0;
  }
  100% {
    background-position: 40px 0;
  }
`;

  const [search, setSearch] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      {!errorPath ? (
        <S.Navigation>
          <S.MenuList>
            <S.CurrentCompanyWrapper
              $backgroundColor={isCompanyMenuExpanded ? colors.grey.extraLight : colors.core.white}
            >
              {activeCompanyData?.logo?.trim() ? (
                <S.CurrentCompanyImage
                  src={activeCompanyData.logo}
                  $isExpanded={isDrawerOpen || isDrawerHover}
                  alt={activeCompanyData?.name}
                />
              ) : (
                <ImageOutlinedIcon />
              )}
              {(isDrawerOpen || isDrawerHover) && (
                <>
                  <S.CurrentCompanyName>{activeCompanyData?.name}</S.CurrentCompanyName>
                </>
              )}
            </S.CurrentCompanyWrapper>
          </S.MenuList>
          {(isDrawerOpen || isDrawerHover) && (
            <S.MenuGroup>
              <S.SectionTitle>{t('navigation.itemProjects')}</S.SectionTitle>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {[1, 2, 3].map((index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '16px',
                        bgcolor: '#F8F8F9',
                        borderRadius: '8px',
                        opacity: opacities[index - 1],
                      }}
                    >
                      <FolderIcon
                      // sx={{
                      //   marginRight: '',
                      //   color: 'transparent',
                      //   background: 'linear-gradient(90deg, #e0e0e0, #f0f0f0)',
                      //   backgroundSize: '200% 100%',
                      //   animation: `${shimmer} 1.5s infinite linear`,
                      // }}
                      />
                      <Skeleton
                        variant="rectangular"
                        height="2px"
                        width="91px"
                        sx={{ background: 'linear-gradient(90deg, #e0e0e0, #f0f0f0)' }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </S.MenuGroup>
          )}
        </S.Navigation>
      ) : (
        <S.Navigation>
          <S.MenuList>
            {isActiveProjectFetching && (
              <S.NavigationLoaderWrapper>
                <CircularProgress color="primary" />
              </S.NavigationLoaderWrapper>
            )}
            <S.CurrentCompanyWrapper
              $backgroundColor={isCompanyMenuExpanded ? colors.grey.extraLight : colors.core.white}
              onClick={() => {
                if (myProfileData?.role === 1 || myProfileData?.projects.some((project) => project.role === 1)) {
                  toggleCompanyMenu();
                  if (!isDrawerOpen) onDrawerToggle(true);
                }
              }}
            >
              {activeCompanyData?.logo?.trim() ? (
                <S.CurrentCompanyImage
                  src={activeCompanyData.logo}
                  $isExpanded={isDrawerOpen || isDrawerHover}
                  alt={activeCompanyData?.name}
                />
              ) : (
                <ImageOutlinedIcon />
              )}
              {(isDrawerOpen || isDrawerHover) && (
                <>
                  <S.CurrentCompanyName>{activeCompanyData?.name}</S.CurrentCompanyName>
                  {(myProfileData?.role === 1 || myProfileData?.projects.some((project) => project.role === 1)) && (
                    <ChevronIcon direction={isCompanyMenuExpanded ? 'left' : 'right'} />
                  )}
                </>
              )}
            </S.CurrentCompanyWrapper>

            <S.MenuGroup>
              <S.SectionTitle>{t('navigation.itemProjects')}</S.SectionTitle>
              {Array.isArray(activeCompanyData?.projects) &&
                activeCompanyData?.projects.map((project: Project) => (
                  <React.Fragment key={project.id}>
                    <S.ListItem>
                      <S.MenuLink
                        onClick={() => changeActiveProject(project.id)}
                        to={`/project/${project.id}/overview`}
                        $isActive={checkIsActive(
                          generatePath(paths.project.tabs.overview, { projectId: project.id }),
                          false,
                        )}
                      >
                        {checkIsActive(generatePath(RoutePaths.project.index, { projectId: project.id }), false) ? (
                          <S.FolderIconWrapper />
                        ) : (
                          <S.FolderInactiveIconWrapper />
                        )}
                        <S.SidebarTitle>{project.name}</S.SidebarTitle>
                      </S.MenuLink>
                      <S.MenuLinkArrow
                        onClick={() => {
                          // Toggle the chevron icon as before
                          toggleProjectSubmenu(project.id);
                        }}
                      >
                        <ChevronIcon direction={isProjectSubmenuExpanded(project.id) ? 'down' : 'right'} />
                      </S.MenuLinkArrow>
                    </S.ListItem>
                    <Collapse in={isProjectSubmenuExpanded(project.id)} timeout="auto" unmountOnExit>
                      <S.Submenu>
                        {([...project.workPackages] || [])
                          .filter((wp) => !wp.archived)
                          .sort((wp1, wp2) => parseInt(wp1.number || '0', 10) - parseInt(wp2.number || '0', 10))
                          .map((wp) => (
                            <S.ListItem key={wp.id}>
                              <S.SubmenuLink
                                to={generatePath(RoutePaths.workPackages.tabs.overview, {
                                  projectId: project.id,
                                  wpId: wp.id,
                                })}
                                $isActive={checkIsActive(
                                  generatePath(RoutePaths.workPackages.index, { projectId: project.id, wpId: wp.id }),
                                  false,
                                )}
                              >
                                <S.SubmenuText color={colorsV2.grey.default}>{wp.name}</S.SubmenuText>
                                <TrafficLightTag status={wp.status?.current}>
                                  {t('navigation.wpItem', { name: wp.number })}
                                </TrafficLightTag>
                              </S.SubmenuLink>
                            </S.ListItem>
                          ))}
                      </S.Submenu>
                    </Collapse>
                  </React.Fragment>
                ))}
            </S.MenuGroup>
            {myProfileData?.role === 1 && (
              <AccessControl permissions={[USER_PERMISSION.COMPANY_LIST, USER_PERMISSION.PROJECT_LIST_VIEW]}>
                <S.MenuGroup hideBorder={true}>
                  <S.SectionTitle>{t('navigation.sectionTitleAdminOptions')}</S.SectionTitle>
                  <S.ListItem>
                    <AccessControl permissions={[USER_PERMISSION.USER_LIST_VALUES]}>
                      <S.MenuLink
                        to={generatePath(paths.admin.usersList)}
                        $isActive={checkIsActive(paths.admin.usersList)}
                      >
                        {checkIsActive(paths.admin.usersList) ? <UserActiveIcon /> : <UserIcon />}
                        <S.SidebarTitle>{t('Users')}</S.SidebarTitle>
                      </S.MenuLink>
                    </AccessControl>
                    <AccessControl permissions={[USER_PERMISSION.COMPANY_LIST]}>
                      <S.MenuLink
                        to={generatePath(paths.admin.companiesList)}
                        $isActive={checkIsActive(paths.admin.companiesList)}
                      >
                        {checkIsActive(paths.admin.companiesList) ? <CompanyActiveIcon /> : <CompanyIcon />}
                        <S.SidebarTitle>{t('navigation.itemCompanies')}</S.SidebarTitle>
                      </S.MenuLink>
                    </AccessControl>
                    <AccessControl permissions={[USER_PERMISSION.PROJECT_LIST_VIEW]}>
                      <S.MenuLink
                        to={generatePath(paths.admin.projectsList)}
                        $isActive={checkIsActive(paths.admin.projectsList)}
                      >
                        {checkIsActive(paths.admin.projectsList) ? <ProjectsActiveIcon /> : <ProjectsIcon />}
                        <S.SidebarTitle>{t('navigation.itemProjects')}</S.SidebarTitle>
                      </S.MenuLink>
                    </AccessControl>
                  </S.ListItem>
                </S.MenuGroup>
              </AccessControl>
            )}
          </S.MenuList>
        </S.Navigation>
      )}

      {showCompanyMenu && (
        <S.CompanyModalContainer
          $isDrawerOpen={isDrawerOpen}
          style={isCompanyMenuExpanded ? containerMountedStyle : containerUnmountedStyle}
          id="sidebar-company-modal"
          onClick={(e) => {
            if ((e.target as HTMLElement)?.id === 'sidebar-company-modal') {
              toggleCompanyMenu();
            }
          }}
        >
          <S.CompanyModalWrapper style={isCompanyMenuExpanded ? wrapperMountedStyle : wrapperUnmountedStyle}>
            <S.CompanyModalHeader>
              <Typography variant="body2" color={colorsV2.core.black} style={{ fontWeight: 600 }}>
                {t('navigation.switchCompany')}
              </Typography>
              {/* <AccessControl permissions={[USER_PERMISSION.COMPANY_CREATE]}>
                <Button
                  onClick={() => setShowCompanyModal(true)}
                  size="smaller"
                  variant="secondary"
                  icon={<PlusIcon />}
                >
                  {t('navigation.addNew')}
                </Button>
              </AccessControl> */}
            </S.CompanyModalHeader>

            <ShortSearchBar placeholder="Search files" value={search} onChange={handleSearchChange} />

            <S.CompanyModalCompanies>
              <ListSelect>
                {processedSidebarCompanies?.map((company) => {
                  if (company.name.toLowerCase().includes(search.toLowerCase())) {
                    const companyId = Number(company.id);

                    return (
                      <ListSelectElement
                        key={company.id}
                        text={company.name}
                        active={companyId === activeCompanyData?.id}
                        icon={company.logo ? company.logo : undefined}
                        onClick={() => handleChangeActiveCompany({ companyId })}
                      />
                    );
                  }

                  return null;
                })}
              </ListSelect>
            </S.CompanyModalCompanies>
          </S.CompanyModalWrapper>
        </S.CompanyModalContainer>
      )}
      <AddCompanyModal
        open={showAddCompanyModal}
        onCancel={() => setShowCompanyModal(false)}
        onSubmitSuccess={(company) => {
          setShowCompanyModal(false);
          setAlertMessage({
            severity: 'success',
            message: t('list.companyHasBeenAdded', { companyName: company.name }),
          });
        }}
      />
    </>
  );
};
