import { ObjectValues } from './helpers';

export const CompaniesTag = 'Companies' as const;

export type Company = {
  createdAt: string;
  id: string;
  isClient: false;
  logo: string;
  name: string;
  type: string;
  domains?: Array<string>;
  abbreviation?: string;
  projectsCount: number;
};

export type GetCompaniesParams = {
  limit?: number;
  offset?: number;
  isClient?: boolean;
  orderBy?: keyof Company;
  desc?: boolean;
  projectsCountGt?: number;
};

export const CompanyType = {
  Oem: 'oem',
  Supplier: 'supplier',
} as const;

export type CreateCompany = {
  name: string;
  type: ObjectValues<typeof CompanyType>;
  isClient: boolean;
  logo?: string;
  domains?: Array<string>;
  abbreviation?: string;
};

export type CompanyValues = { id: string; name: string; logo?: string };

export type GetCompaniesValues = Array<CompanyValues>;

export type GetCompaniesValuesParams = {
  attribute: Array<'name' | 'logo'>;
  search: string;
  projectsCountGt?: number;
  type?: 'oem' | 'supplier' | 'consultants';
};

export type CompanyUser = { id: string; name: string; email: string };

export type GetCompanyUsers = {
  totalCount: number;
  user: Array<CompanyUser>;
};

export type GetCompanyUsersParams = {
  company_id: string;
  name: string;
  limit: number;
};

export type CompanyIcon = { id: string; name: string; email: string };

export type GetCompanyIcon = CompanyIcon;

export type GetCompanyIconParams = string;
