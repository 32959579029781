import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { selectIsLoggedIn, useGetMyProfileQuery, useGetPermissionsListQuery } from '@/core/store/authSlice';
import { UserPermission } from '@/core/interfaces/Auth';
import { useAppSelector } from '@/core/store/store';
import { selectActiveProjectId } from '@/core/store/projectsSlice';

export type UseAccessControlProps = {
  permissions?: Array<UserPermission>;
};

export type PagePermissions = {
  permissions: Array<UserPermission>;
  type: 'PROJECT' | 'WORK_PACKAGE';
};

export const useAccessControl = (props: UseAccessControlProps | void = {}) => {
  const { pathname } = useLocation();
  const pageParams = pathname.split('/');

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const activeProjectId = useAppSelector(selectActiveProjectId);

  const { data: permissionsData } = useGetPermissionsListQuery(undefined, { skip: !isLoggedIn });
  const { data: profileData } = useGetMyProfileQuery(undefined, { skip: !isLoggedIn });

  const { globalPermissions, pagePermissions } = useMemo(() => {
    if (permissionsData && profileData) {
      const projectPermissions = profileData.projects.reduce<{ [key: string]: PagePermissions }>(
        (acc, { id, role }) => {
          const permissions =
            (permissionsData.projectRolePermissions || []).find((project) => project.projectRole === role)
              ?.permissions || [];

          return { ...acc, [id]: { permissions, type: 'PROJECT' } };
        },
        {},
      );
      const wpPermissions = profileData.workPackages.reduce<{ [key: string]: PagePermissions }>((acc, { id, role }) => {
        const permissions =
          (permissionsData.workPackageRolePermissions || []).find((project) => project.workPackageRole === role)
            ?.permissions || [];

        return { ...acc, [id]: { permissions, type: 'WORK_PACKAGE' } };
      }, {});

      return {
        globalPermissions: permissionsData.userRolePermissions.permissions,
        pagePermissions: {
          ...projectPermissions,
          ...wpPermissions,
        },
      };
    }

    return { globalPermissions: [], pagePermissions: {} };
  }, [permissionsData, profileData]);

  const verifyPermissions = ({ permissions }: UseAccessControlProps) => {
    const pageIds = Object.values(pageParams) as Array<string>;

    const activeProjectPermissions = pagePermissions[activeProjectId as string]?.permissions || [];
    const activePagePermissions = pageIds ? pageIds.map((pageId) => pagePermissions[pageId]?.permissions || []) : [];
    const allPermissionsForCurrentPage = [
      ...globalPermissions,
      ...activeProjectPermissions,
      ...activePagePermissions.flat(),
    ];

    return permissions
      ? permissions.some((routePermission) => allPermissionsForCurrentPage.indexOf(routePermission) >= 0)
      : true;
  };

  return {
    isPermitted: verifyPermissions(props || {}),
    verifyPermissions,
  };
};
