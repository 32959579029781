import { Datum } from 'plotly.js';

import { Modify, Nullable, ObjectValues, PaginatedData } from '@/core/interfaces/helpers';
import { MeasureStatus } from '@/core/interfaces/Measures';

export const KpisTag = 'KPIs' as const;
export const KpiMeasureTag = 'KPI_MEASURE' as const;

export const KPI_STATE = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
} as const;

export const KPI_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
} as const;

export const KPI_USER_STATUS = {
  TRACK: 'On Track',
  DEVIATEDCM: 'Deviated CM',
  DEVIATEDNCM: 'Deviated NCM',
} as const;

export const CHART_TYPE = {
  BAR: 'bar',
  LINE: 'line',
  COMBINED_BAR_LINE: 'combined_bar_line',
  GLIDEPATH: 'glidepath',
  TABLE: 'table',
  WATERFALL: 'waterfall',
  STATIC_IMAGE: 'static_image',
} as const;

export const BAR_CHART_SUBTYPE = {
  CLUSTERED: 'clustered_bar',
  STACKED: 'stacked_bar',
  SCALED_STACKED: 'scaled_stacked_bar',
} as const;

export const CHART_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
} as const;

export const WIZARD_TYPE = {
  SCRATCH: 'scratch',
  TEMPLATE: 'template',
  IMAGE: 'static_image',
} as const;

export const FONT_STYLE = {
  LIGHT: 'light',
  REGULAR: 'regular',
  BOLD: 'bold',
} as const;

export const ELEMENT_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

export const LINE_STYLE = {
  SOLID: 'solid',
  DOT: 'dot',
  DASH: 'dash',
  DASHDOT: 'dashdot',
} as const;

export const LEGEND_POSITION = {
  BELOW: 'below',
  ABOVE: 'above',
} as const;

export const AXIS_KEY_TYPE = {
  MANUAL: 'manual',
  DATE: 'date',
  CALENDARWEEK: 'calendarweek',
} as const;

export const UPLOAD_METHOD = {
  MANUAL: 'manual',
  FILE: 'file',
} as const;

export type KpiUserStatus = ObjectValues<typeof KPI_USER_STATUS>;
export type KpiState = ObjectValues<typeof KPI_STATE>;
export type KpiStatus = ObjectValues<typeof KPI_STATUS>;
export type ChartType = ObjectValues<typeof CHART_TYPE>;
export type BarChartSubType = ObjectValues<typeof BAR_CHART_SUBTYPE>;
export type ChartOrientation = ObjectValues<typeof CHART_ORIENTATION>;
export type WizardType = ObjectValues<typeof WIZARD_TYPE>;
export type FontStyle = ObjectValues<typeof FONT_STYLE>;
export type ElementSize = ObjectValues<typeof ELEMENT_SIZE>;
export type LineStyle = ObjectValues<typeof LINE_STYLE>;
export type LegendPosition = ObjectValues<typeof LEGEND_POSITION>;
export type AxisKeyType = ObjectValues<typeof AXIS_KEY_TYPE>;
export type UploadMethod = ObjectValues<typeof UPLOAD_METHOD>;

export type Series = {
  id?: string;
  name: string;
  visible?: boolean;
  isPercentageUnit?: boolean;
  enabled: boolean;
  colorPaletteIndex?: number;
  seriesChartType: 'bar' | 'line';
};

export type LineStyles = {
  width: ElementSize;
  style: LineStyle;
  colorPaletteIndex: number;
};

export type Line = {
  enabled: boolean;
  allSeries: boolean;
  allSeriesStyling: LineStyles;
  selectedSeries: Array<
    LineStyles & {
    enabled: boolean;
    id: string;
  }
  >;
};

export type CustomLine = LineStyles & {
  label: Nullable<string>;
  value: Nullable<number>;
  isPercentageUnit: boolean;
};

export type Legend = {
  enabled: boolean;
  fontSize: ElementSize;
  position: LegendPosition;
};

export type TimePeriod = {
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  label: string;
  colorPaletteIndex: number;
};

export type GlidepathMeasureItem = {
  id?: string;
  createdAt?: string;
  dueDates?: Array<string>;
  problem: string;
  measure: string;
  startingPoint: string;
  dueDate: string;
  effectiveIn: string;
  status: MeasureStatus;
  impact: number;
  impactDataSeriesId: string;
  colorPaletteIndex: number;
  supportingDocument?: Nullable<string>;
  uploadedFile?: Nullable<File>;
  responsibleUserIds: Array<string>;
  responsibleUsers?: Array<{
    id: string;
    name: string;
  }>;
};

export type ChartSettings = {
  chartSubtype: BarChartSubType;
  orientation: ChartOrientation;
  averageLine: Line;
  trendLine: Line;
  additionalLine: {
    enabled: boolean;
    lines: Array<CustomLine>;
  };
  xAxis: {
    label: string;
    fontStyle: FontStyle;
  };
  yAxis: {
    isPrimary: boolean;
    label: string;
    fontStyle: FontStyle;
  };
  additionalAxis: {
    accessible: boolean;
    isPrimary: boolean;
    label: string;
    fontStyle: FontStyle;
  };
  sumValues: boolean;
  rotateValuesWithinBar: boolean;
  seriesReversed: boolean;
  legend: Legend;
  showLastEntriesNumber: number;
  showLastEntriesPlaceholder: string;
  timePeriod: {
    enabled: boolean;
    times: Array<TimePeriod>;
  };
};

export type GetKpi = {
  id: string;
  name: string;
  pinned: boolean;
  status: KpiStatus;
  userStatus: KpiUserStatus;
  workPackageId: string;
  chartType: ChartType;
  series: Array<Series>;
  settings: ChartSettings;
  measures?: Array<GlidepathMeasureItem>;
  staticImage?: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  state: string;
};

export type CreateKpi = {
  id?: number;
  name: string;
  workPackageId: string;
  chartType: ChartType;
  series: Array<Series>;
  settings: {
    chartSubtype: BarChartSubType;
    orientation: ChartOrientation;
  };
};

export type UpdateKpi = Partial<Omit<GetKpi, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>>;

export type KpiWizardForm = Modify<
  UpdateKpi,
  {
    meta?: {
      wizardFormType: Nullable<WizardType>;
      isWizardTypeAccepted: boolean;
      dataUploadMethod: UploadMethod;
    };
    workPackageId: string;
    chartType: Nullable<ChartType>;
  }
>;

export type GetKpiValues = {
  axisKeyType: AxisKeyType;
  rows: Array<{
    axisKey: string;
    values: Array<{ value: number }>;
  }>;
};

export type UpdateKpiValues = GetKpiValues;

export type KpiValuesForm = {
  axisKeyType: AxisKeyType;
  rows: Array<{
    axisKeyName: string;
    axisKeyDate: Nullable<Date>;
    axisKeyCalendarweek: Nullable<Date> | number;
    values: Array<{ value: string }>;
  }>;
};

export type KpiValuesChartPreview = {
  values: Array<Array<Datum>>;
  keys: Array<Datum>;
  axisKeyType: AxisKeyType;
};

export type GetKpiListItem = GetKpi & {
  data: KpiValuesChartPreview;
};

export type GetKpisListParams = {
  limit?: number;
  offset?: number;
  status?: KpiStatus;
  userStatus?: KpiUserStatus;
  name?: string;
  updatedAt?: string;
  orderBy?: string;
  desc?: boolean;
  pinned?: boolean;
  chartType?: ChartType;
  kpiId?: string;
};

export type GetKpisList = PaginatedData<GetKpiListItem>;

export type GetKpisSnapshotItem = GetKpi & {
  data: KpiValuesChartPreview;
};

export type GetKpisSnapshotParams = {
  limit?: number;
  offset?: number;
  status?: KpiStatus;
  userStatus?: KpiUserStatus;
  name?: string;
  updatedAt?: string;
  orderBy?: string;
  desc?: boolean;
  pinned?: boolean;
  chartType?: ChartType;
  kpiId?: string;
};

export type GetKpisSnapshot = PaginatedData<GetKpisSnapshotItem>;

export type DuplicateKpi = {
  id: string;
};

export type UpdateKpiStatus = {
  status: string;
};
