import React from 'react';
import styled from 'styled-components';

import { SkeletonLoading } from '@/stories/Primitives/SkeletonLoading/SkeletonLoading';

const CardSkeleton = () => {
  return (
    <CardContainer>
      {/* Header */}
      <CardHeader>
        <LeftHeader>
          <SkeletonLoading variant="rounded" width={41} height={24} colorVariant="secondary" />
          <Spacer width={8} />
          <SkeletonLoading variant="rounded" width={132} height={24} colorVariant="secondary" />
        </LeftHeader>
        <AvatarGroup>
          <SkeletonLoading variant="circular" width={32} height={32} colorVariant="secondary" />
          <OverlapAvatar>
            <SkeletonLoading variant="circular" width={32} height={32} colorVariant="secondary" />
          </OverlapAvatar>
        </AvatarGroup>
      </CardHeader>

      {/* List/Workpackages */}
      <Spacer height={16} />
      <ListWorkpackages>
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
        <Spacer height={16} />
        <StatusBar>
          <SkeletonLoading variant="rectangular" width={328} height={92} colorVariant="primary" />
        </StatusBar>
      </ListWorkpackages>

      {/* Top KPIs */}
      <Spacer height={16} />
      <TopKPI>
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
        <Spacer height={16} />
        <KPIValues>
          <KPIContainer>
            <SkeletonLoading variant="rounded" width={224} height={36} colorVariant="primary" />
            <Spacer width={8} />
          </KPIContainer>
          <KPIContainer style={{ flexDirection: 'row', gap: '8px' }}>
            <SkeletonLoading variant="rounded" width={112} height={36} colorVariant="primary" />
            <SkeletonLoading variant="rounded" width={116} height={36} colorVariant="primary" />
          </KPIContainer>
          <KPIContainer>
            <Spacer width={8} />
            <SkeletonLoading variant="rounded" width={190} height={36} colorVariant="primary" />
          </KPIContainer>
        </KPIValues>
      </TopKPI>

      {/* Footer */}
      <Spacer height={16} />
      <Footer>
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
        <Spacer />
        <SkeletonLoading variant="rounded" width={82} height={16} colorVariant="secondary" />
      </Footer>
    </CardContainer>
  );
};

// Styled Components
const CardContainer = styled.div`
  height: 480px;
  padding: 16px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  maxwidth: 380px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 328px;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarGroup = styled.div`
  width: 64px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const OverlapAvatar = styled.div`
  position: absolute;
  left: 16px;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
`;

const ListWorkpackages = styled.div`
  width: 328px;
  height: 124px;
  display: flex;
  flex-direction: column;
`;

const StatusBar = styled.div`
  width: 328px;
  height: 92px;
`;

const TopKPI = styled.div`
  width: 328px;
  height: 160px;
  display: flex;
  flex-direction: column;
`;

const KPIValues = styled.div`
  width: 328px;
  height: 128px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  display: block;
`;

type KPIContainerProps = {
  flexDirection?: string;
};

const KPIContainer = styled.div<KPIContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 328px;
  height: 16px;
`;

const Spacer = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 16px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 32px;
`;

export const CardsGrid = () => {
  return (
    <CardsContainer>
      {[...Array(12)].map((_, index) => (
        <CardSkeleton key={index} />
      ))}
    </CardsContainer>
  );
};

export default CardSkeleton;
