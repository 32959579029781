import React, { useEffect, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { useResponsive } from '@/core/hooks/useResponsive';
import { useDelayedUnmount } from '@/core/hooks/useDelayedUnmount';
import { TopBar } from '@/core/components/Navigation/TopBar';
import { useAppDispatch } from '@/core/store/store';
import { setIsDrawerOpen as setIsDrawerOpenAction } from '@/core/store/metaSlice';
import { AFTER_DRAWER_TRANSITION_DELAY_MS } from '@/core/components/Navigation/Navigation.constants';
import { SidebarItems } from '@/core/components/Navigation/SidebarItems';
import ContractIcon from '@/assets/svg/figma_contract';
import { checkIsIOS } from '@/utils/utils';
import { ReactComponent as Logo } from '@/assets/logo.svg';

import * as S from './Navigation.styles';

export const PageNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [shouldDisplay] = useResponsive();
  const isMobileNav = !shouldDisplay.up('laptop');

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(!isMobileNav);
  const [isDrawerHover, setIsDrawerHover] = useState<boolean>(false);

  // Define state and function for toggling the submenu
  const [isCompanyMenuExpanded, setIsCompanyMenuExpanded] = useState(false);
  const showCompanyMenu = useDelayedUnmount(isCompanyMenuExpanded, 250);

  // Function to toggle the submenu
  const toggleCompanyMenu = () => {
    setIsCompanyMenuExpanded((prevIsCompanyMenuExpanded) => !prevIsCompanyMenuExpanded);
  };

  useEffect(() => {
    if (isMobileNav) {
      setIsDrawerOpen(false);
    }
  }, [isMobileNav, pathname]);

  useEffect(() => {
    setTimeout(() => dispatch(setIsDrawerOpenAction(isDrawerOpen)), AFTER_DRAWER_TRANSITION_DELAY_MS);
  }, [isDrawerOpen]);

  return (
    <>
      <TopBar isDrawerOpen={isDrawerOpen} onDrawerToggle={setIsDrawerOpen} isDrawerHover={isDrawerHover} />
      <S.SidebarWrapper data-testid="Sidebar-Wrapper" isOpen={isDrawerOpen} isHover={isDrawerHover}>
        <S.Drawer
          variant="permanent"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onOpen={() => setIsDrawerOpen(true)}
          disableBackdropTransition={!checkIsIOS()}
          disableDiscovery={checkIsIOS()}
          onMouseEnter={() => setIsDrawerHover(true)}
          onMouseLeave={() => setIsDrawerHover(false)}
        >
          <S.DrawerContent>
            <S.LogoContainer>
              <S.BrandContainer>
                <S.Logo $isDrawerOpen={isDrawerOpen} $isDrawerHover={isDrawerHover}>
                  <Link to="/">
                    <Logo />
                  </Link>
                </S.Logo>
                {!isMobileNav && (isDrawerOpen || isDrawerHover) && (
                  <S.SidebarIconWrapper
                    $isDrawerOpen={isDrawerOpen}
                    onClick={() => {
                      if (isCompanyMenuExpanded && isDrawerOpen) toggleCompanyMenu();
                      setIsDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    <ContractIcon />
                  </S.SidebarIconWrapper>
                )}
              </S.BrandContainer>
              {isMobileNav && (
                <S.HamburgerWrapper>
                  <Hamburger toggled={isDrawerOpen} onToggle={setIsDrawerOpen} size={18} distance="sm" />
                </S.HamburgerWrapper>
              )}
              <Divider />
            </S.LogoContainer>
            <SidebarItems
              isDrawerOpen={isDrawerOpen}
              onDrawerToggle={setIsDrawerOpen}
              isDrawerHover={isDrawerHover}
              showCompanyMenu={showCompanyMenu}
              toggleCompanyMenu={toggleCompanyMenu}
              isCompanyMenuExpanded={isCompanyMenuExpanded}
            />
          </S.DrawerContent>
        </S.Drawer>
      </S.SidebarWrapper>
    </>
  );
};