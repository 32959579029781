import styled from 'styled-components';
import { Alert } from '@mui/material';

import { colors } from '@/themes/colors';
import { breakpoints } from '@/themes/breakpoints';

export const FieldRow = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(4)};

  @media (min-width: ${breakpoints.values.tabletSmall}px) {
    flex-direction: row-reverse;
  }
`;

export const PlaceholderValue = styled.span`
  color: ${colors.neutral[500]};
`;

export const DuplicatesBox = styled(Alert)`
  && {
    background-color: ${colors.neutral[100]};
    padding: 16px;
  }

  .MuiAlert-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }

  .MuiAlert-icon {
    display: none;
  }
`;

export const DuplicatesList = styled.ul`
  list-style: none;
  margin-top: 16px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;

  padding: 0;
`;
