import { createTheme } from '@mui/material/styles';

import { colors } from './colors';
import { typography } from './typography';
import { TypographyV2 as typographyv2 } from './typographyV2';
import {
  MuiAlert,
  MuiAvatar,
  MuiButton,
  MuiCardContent,
  MuiCheckbox,
  MuiDateCalendar,
  MuiChip,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormHelperText,
  MuiFormLabel,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiOutlinedInput,
  MuiPickersCalendarHeader,
  MuiPickersPopper,
  MuiPaginationItem,
  MuiRadio,
  MuiSvgIcon,
  MuiSwitch,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiTab,
  MuiTooltip,
  MuiTypography,
  MuiAutocomplete,
  MuiStep,
  MuiAccordion,
} from './muiComponents';
import { breakpoints } from './breakpoints';

const MuiXComponents = {
  MuiPickersPopper,
  MuiDateCalendar,
  MuiPickersCalendarHeader,
};

const theme = createTheme({
  breakpoints,
  spacing: [0, 9, 18, 36, 44, 50, 60, 70, 80, 90, 100],
  palette: {
    primary: {
      main: '#0D1116',
      light: '#878686',
      dark: '#000000',
      contrastText: colors.neutral.white,
    },
    secondary: {
      main: '#FAFAFA',
      light: '#C2BEBE',
      dark: '#e2e2e2',
      contrastText: '#565656',
    },
    info: {
      main: '#181F28',
      light: '#2f353d',
      dark: '#181818',
      contrastText: colors.neutral.white,
    },
    common: {
      black: colors.neutral.black,
      white: colors.neutral.white,
    },
  },
  typography: {
    ...typography,
    ...typographyv2,
  },
  components: {
    ...MuiXComponents,
    MuiAlert,
    MuiAvatar,
    MuiButton,
    MuiCardContent,
    MuiCheckbox,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogContentText,
    MuiDialogTitle,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiFormLabel,
    MuiFormGroup,
    MuiInputBase,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiRadio,
    MuiSwitch,
    MuiTypography,
    MuiSvgIcon,
    MuiTooltip,
    MuiTableRow,
    MuiTableCell,
    MuiChip,
    MuiPaginationItem,
    MuiTabs,
    MuiTab,
    MuiLinearProgress,
    MuiAutocomplete,
    MuiStep,
    MuiAccordion,
  },
});

export default theme;
