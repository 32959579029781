import { ObjectValues } from '@/core/interfaces/helpers';

export const USER_ROLE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
} as const;

export const USER_PROJECT_ROLE = {
  CONSULTANT: 1,
  LEADER: 2,
  MEMBER: 3,
  UPPER_MANAGEMENT: 5,
} as const;

export const USER_WP_ROLE = {
  LEADER: 1,
  CONTRIBUTOR: 2,
} as const;

export const USER_PERMISSION = {
  COMPANY_CREATE: 'COMPANY_CREATE',
  COMPANY_GET: 'COMPANY_GET',
  COMPANY_LIST: 'COMPANY_LIST',
  COMPANY_UPDATE: 'COMPANY_UPDATE',
  INVITATION_CONFIRM: 'INVITATION_CONFIRM',
  INVITATION_CREATE: 'INVITATION_CREATE',
  INVITATION_DENY: 'INVITATION_DENY',
  KPI_CREATE: 'KPI_CREATE',
  KPI_GET: 'KPI_GET',
  KPI_LIST: 'KPI_LIST',
  KPI_UPDATE: 'KPI_UPDATE',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_GET: 'PROJECT_GET',
  PROJECT_LIST: 'PROJECT_LIST',
  PROJECT_LIST_VALUES: 'PROJECT_LIST_VALUES',
  PROJECT_LIST_VIEW: 'PROJECT_LIST_VIEW',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  USER_LIST_VALUES: 'USER_LIST_VALUES',
  WORK_PACKAGE_CREATE: 'WORK_PACKAGE_CREATE',
  WORK_PACKAGE_GET: 'WORK_PACKAGE_GET',
  WORK_PACKAGE_LIST: 'WORK_PACKAGE_LIST',
  WORK_PACKAGE_MEASURE_CREATE: 'WORK_PACKAGE_MEASURE_CREATE',
  WORK_PACKAGE_MEASURE_DELETE: 'WORK_PACKAGE_MEASURE_DELETE',
  WORK_PACKAGE_MEASURE_GET: 'WORK_PACKAGE_MEASURE_GET',
  WORK_PACKAGE_MEASURE_LIST: 'WORK_PACKAGE_MEASURE_LIST',
  WORK_PACKAGE_MEASURE_UPDATE: 'WORK_PACKAGE_MEASURE_UPDATE',
  WORK_PACKAGE_TARGET_ACHIEVE: 'WORK_PACKAGE_TARGET_ACHIEVE',
  WORK_PACKAGE_TARGET_ACHIEVE_CONFIRM: 'WORK_PACKAGE_TARGET_ACHIEVE_CONFIRM',
  WORK_PACKAGE_TARGET_CREATE: 'WORK_PACKAGE_TARGET_CREATE',
  WORK_PACKAGE_TARGET_DELETE: 'WORK_PACKAGE_TARGET_DELETE',
  WORK_PACKAGE_TARGET_GET: 'WORK_PACKAGE_TARGET_GET',
  WORK_PACKAGE_TARGET_LIST: 'WORK_PACKAGE_TARGET_LIST',
  WORK_PACKAGE_TARGET_UPDATE: 'WORK_PACKAGE_TARGET_UPDATE',
  WORK_PACKAGE_UPDATE: 'WORK_PACKAGE_UPDATE',
  WORK_PACKAGE_WEEK_PROGRESS_CREATE: 'WORK_PACKAGE_WEEK_PROGRESS_CREATE',
  WORK_PACKAGE_WEEK_PROGRESS_GET: 'WORK_PACKAGE_WEEK_PROGRESS_GET',
} as const;

export type UserRole = ObjectValues<typeof USER_ROLE>;
export type UserProjectRole = ObjectValues<typeof USER_PROJECT_ROLE>;
export type UserWpRole = ObjectValues<typeof USER_WP_ROLE>;
export type UserPermission = ObjectValues<typeof USER_PERMISSION>;

export type GetPermissionsList = {
  id: string;
  userRolePermissions: {
    userRole: UserRole;
    permissions: Array<UserPermission>;
  };
  projectRolePermissions: Array<{
    projectRole: UserProjectRole;
    permissions: Array<UserPermission>;
  }>;
  workPackageRolePermissions: Array<{
    workPackageRole: UserWpRole;
    permissions: Array<UserPermission>;
  }>;
};

export type MyUserProfile = {
  id: string;
  name: string;
  email: string;
  company: string;
  role: UserRole;
  projects: Array<{
    id: string;
    role: UserProjectRole;
  }>;
  workPackages: Array<{
    id: string;
    role: UserWpRole;
  }>;
};
