import { snakeCase } from 'change-case';
import { stringify } from 'qs';

import { apiSlice } from '@/core/store/apiSlice';
import { caseConverter } from '@/utils/case-converter';

import {
  CompaniesTag,
  Company,
  CreateCompany,
  GetCompaniesParams,
  GetCompaniesValues,
  GetCompaniesValuesParams,
  GetCompanyUsers,
  GetCompanyUsersParams,
  GetCompanyIcon,
  GetCompanyIconParams,
} from '../interfaces/Companies';
import { PaginatedData } from '../interfaces/helpers';

export const extendedApi = apiSlice.enhanceEndpoints({ addTagTypes: [CompaniesTag] }).injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<PaginatedData<Company>, GetCompaniesParams>({
      query: (params) => ({
        url: `/v2/companies/`,
        params: {
          ...params,
          ...(params.orderBy && { orderBy: snakeCase(params.orderBy) }),
        },
      }),
      providesTags: (result) =>
        result?.items ? [...result.items.map(({ id }) => ({ type: CompaniesTag, id })), CompaniesTag] : [CompaniesTag],
    }),
    createCompany: builder.mutation<Company, CreateCompany>({
      query: (body) => ({
        url: `/v2/companies/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [CompaniesTag],
    }),
    getCompaniesValues: builder.query<GetCompaniesValues, GetCompaniesValuesParams>({
      query: (params) => ({
        url: `/v2/companies/values/?${stringify(
          caseConverter(
            params as unknown as { [key: keyof GetCompaniesValuesParams | string]: string | Array<string> },
            snakeCase,
          ),
          { arrayFormat: 'repeat' },
        )}`,
      }),
    }),
    getCompanyUsers: builder.query<GetCompanyUsers, GetCompanyUsersParams>({
      query: (params) => ({
        url: `/v2/companies/users/?${stringify(
          caseConverter(
            params as unknown as { [key: keyof GetCompanyUsersParams | string]: string | Array<string> },
            snakeCase,
          ),
          { arrayFormat: 'repeat' },
        )}`,
      }),
    }),
    getCompanyIcon: builder.query<GetCompanyIcon, GetCompanyIconParams>({
      query: (params) => ({
        url: `/v2/companies/logo/${params}`,
      }),
    }),
    deleteCompany: builder.mutation<void, { companyId: string }>({
      query: ({ companyId }) => {
        const sanitizedCompanyId = companyId.replace(/\/$/, ''); // Remove trailing slash if exists
        return {
          url: `/v2/companies/${sanitizedCompanyId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [CompaniesTag],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useGetCompaniesValuesQuery,
  useGetCompanyUsersQuery,
  useGetCompanyIconQuery,
  useDeleteCompanyMutation,
} = extendedApi;
export const { getCompanies } = extendedApi.endpoints;
