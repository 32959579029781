import { useEffect, useState } from 'react';

export function useDelayedUnmount(isActive: boolean, delay: number) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let currentTimeout: ReturnType<typeof setTimeout>;

    if (isActive && !isVisible) {
      setIsVisible(true);
    } else if (!isActive && isVisible) {
      currentTimeout = setTimeout(() => setIsVisible(false), delay);
    }

    return () => clearTimeout(currentTimeout);
  }, [isActive, delay, isVisible]);

  return isVisible;
}
