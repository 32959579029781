import styled from 'styled-components';
import { InfoOutlined } from '@mui/icons-material';

import { colors } from '@/themes/colors';

export const FormRow = styled.div`
  margin-top: 16px;
`;

export const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    flex-direction: row;
  }
`;

export const PlaceholderValue = styled.span`
  color: ${colors.neutral[500]};
`;

export const OnboardingDocument = styled.div`
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: ${colors.neutral[100]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfoOutlinedIcon = styled(InfoOutlined)`
  && {
    color: ${colors.neutral[500]};
  }
`;
