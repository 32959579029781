import { ReactNode } from 'react';

import { PageNavigation } from '@/core/components/Navigation';
import { selectIsLoggedIn } from '@/core/store/authSlice';
import { useAppSelector } from '@/core/store/store';

import * as S from './PageLayout.styles';

interface PageLayoutProps {
  children: ReactNode;
}

export const pageHeaderId = 'page-header';

export const PageLayout = ({ children }: PageLayoutProps) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return (
      <S.PageWrapper>
        <PageNavigation />
        <S.ContentWrapper>
          <div id={pageHeaderId} />
          {children}
        </S.ContentWrapper>
      </S.PageWrapper>
    );
  }

  return <S.PageWrapper>{children}</S.PageWrapper>;
};
