export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APP_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_APP_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_PERMANENT_STORAGE_URL || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID || '',
  databaseURL: process.env.REACT_APP_FIREBASE_APP_DATABASE_URL || '',
};

export const PROXY_API_URL = process.env.REACT_APP_PROXY_BACKEND_API_URL;
export const API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const AUTH_EMULATOR_URL = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL;
export const SERVICES_EMULATOR = process.env.REACT_APP_FIREBASE_SERVICES_EMULATOR;
export const DEBUG = process.env.REACT_APP_DEBUG;
