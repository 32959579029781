import React from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, FormControl, Menu } from '@mui/material';
import { generatePath, useNavigate, useParams, useMatch } from 'react-router-dom';

import { useResponsive } from '@/core/hooks/useResponsive';
import { UserSection } from '@/core/components/Navigation/UserSection';
import TaskIcon from '@/assets/svg/figma_task_icon';
import PlanningIcon from '@/assets/svg/figma_planning';
import authContext from '@/core/context/authContext';
import { setUser } from '@/core/store/authSlice';
import { useAppDispatch } from '@/core/store/store';
import { RoutePaths } from '@/core/providers/RoutesProvider';

import * as S from './Navigation.styles';

export type TopBarProps = {
  isDrawerOpen: boolean;
  onDrawerToggle: (toggled: boolean) => void;
  isDrawerHover: boolean;
};

export const TopBar: React.FC<TopBarProps> = ({ isDrawerOpen, onDrawerToggle, isDrawerHover }) => {
  const { t } = useTranslation('Common');
  const dispatch = useAppDispatch();
  // const isOverviewTab = useMatch(RoutePaths.project.tabs.overview);

  const [shouldDisplay] = useResponsive();
  const isMobileNav = !shouldDisplay.up('laptop');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { projectId } = useParams();
  const navigate = useNavigate();

  // Convert the result of useMatch to a boolean
  const isKanbanTab = !!useMatch(`${RoutePaths.project.tabs.kanban}`);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    localStorage.removeItem('token');
    sessionStorage.clear();
    authContext.token = '';
    dispatch(setUser({ id: '', user_id: '', isLoggedIn: false }));
  };

  const handleUserSettings = () => {
    // TODO: Implement user settings handling logic
    console.log('User settings handler is not implemented yet.');
  };

  const handleButtonClick = () => {
    if (projectId) {
      const path = generatePath(RoutePaths.project.tabs.kanban, { projectId });
      navigate(path);
    } else {
      const activeProject = localStorage.getItem('activeProject');
      const path = generatePath(RoutePaths.project.tabs.kanban, { projectId: activeProject });
      navigate(path);
    }
  };

  const navigateToDashboard = () => {
    if (projectId) {
      const path = generatePath(RoutePaths.project.tabs.overview, { projectId });
      navigate(path);
    } else {
      const activeProject = localStorage.getItem('activeProject');
      const path = generatePath(RoutePaths.project.tabs.overview, { projectId: activeProject });
      navigate(path);
    }
  };

  return (
    <div>
      <S.TopBarWrapper data-testid="TopBar-Wrapper" isDrawerOpen={isDrawerOpen}>
        <S.LeftBar>
          {isMobileNav && (
            <S.HamburgerWrapper>
              <Hamburger toggled={isDrawerOpen} onToggle={onDrawerToggle} size={18} distance="sm" />
            </S.HamburgerWrapper>
          )}
          {!isMobileNav && (
            <FormControl fullWidth>
              <S.TopBarContent $isDrawerOpen={isDrawerOpen} $isDrawerHover={isDrawerHover}>
                <ButtonBase onClick={() => navigateToDashboard()}>
                  <S.BarIconWrapper $isActive={!isKanbanTab}>
                    <TaskIcon />
                  </S.BarIconWrapper>
                  <S.TabBarButtonText $isActive={!isKanbanTab} $isDrawerOpen={isDrawerOpen}>
                    {t('Task Forces')}
                  </S.TabBarButtonText>
                </ButtonBase>

                <ButtonBase onClick={() => handleButtonClick()} style={{ marginLeft: 20 }}>
                  <S.BarIconWrapper $isActive={isKanbanTab}>
                    <PlanningIcon />
                  </S.BarIconWrapper>
                  <S.TabBarButtonText $isActive={isKanbanTab} $isDrawerOpen={isDrawerOpen}>
                    {t('Planning')}
                  </S.TabBarButtonText>
                </ButtonBase>
              </S.TopBarContent>
            </FormControl>
          )}
        </S.LeftBar>
        <S.RightBar>
          {!isMobileNav && (
            <>
              <UserSection onExpandClick={handleClick} />
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                style={{ marginTop: 15 }}
                disableAutoFocusItem={true}
              >
                <S.UserMenuItem onClick={handleUserSettings}>{t('User Settings')}</S.UserMenuItem>
                <S.UserMenuItem onClick={handleSignOut}>{t('navigation.barItemLogout')}</S.UserMenuItem>
              </Menu>
            </>
          )}
        </S.RightBar>
      </S.TopBarWrapper>
    </div>
  );
};
