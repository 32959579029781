import { PathRouteProps } from 'react-router-dom';
import React, { Suspense, ReactElement } from 'react';

import {
  CreateKPIPage,
  EditKPIPage,
  ResetPassword,
  SignInPage,
  SignUpPage,
  CompaniesListPage,
  ProjectOverviewPage,
  ProjectRolesAndResponsibilitiesPage,
  ProjectAboutPage,
  ProjectPreviousReportsPage,
  ProjectDeEscalatedWpPage,
  ProjectAttachmentsPage,
  WpOverviewPage,
  WpTargetsPage,
  WpKpisPage,
  WpMeasuresPage,
  WpAboutPage,
  WpAttachmentsPage,
  CreateProjectPage,
  ProjectsListPage,
  UsersListPage,
  Kanban,
} from '@/pages';
import { USER_PERMISSION, UserPermission } from '@/core/interfaces/Auth';

import { paths } from './paths';

import { SkeletonLoading } from '@/stories/Primitives/SkeletonLoading/SkeletonLoading';
import SignInSkeleton from '@/stories/Primitives/SkeletonLoading/pages/SignInSkeleton';
import DashboardSkeleton from '@/stories/Primitives/SkeletonLoading/pages/DashboardSkeleton';

export interface Routes extends PathRouteProps {
  routePermissions?: Array<UserPermission>;
}

// Create a wrapper component for routes with Suspense
interface SuspendedRouteProps {
  element: ReactElement;
  fallback: ReactElement;
}
//const listStyle = localStorage.getItem('listStyle');
const SuspendedRoute: React.FC<SuspendedRouteProps> = ({ element, fallback }) => (
  <Suspense fallback={fallback}>{element}</Suspense>
);

export default SuspendedRoute;

export const mainRoutes: Array<Routes> = [
  {
    path: paths.signIn,
    element: <SuspendedRoute element={<SignInPage />} fallback={<SignInSkeleton />} />,
  },
  {
    path: paths.signUp,
    element: <SuspendedRoute element={<SignUpPage />} fallback={<SkeletonLoading />} />,
  },
  {
    path: paths.resetPassword,
    element: <SuspendedRoute element={<ResetPassword />} fallback={<SkeletonLoading />} />,
  },
  {
    path: paths.workPackages.tabs.kpis.create,
    element: <SuspendedRoute element={<CreateKPIPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.KPI_CREATE],
  },
  {
    path: paths.workPackages.tabs.kpis.edit,
    element: <SuspendedRoute element={<EditKPIPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.KPI_UPDATE],
  },
  {
    path: paths.admin.companiesList,
    element: <SuspendedRoute element={<CompaniesListPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.COMPANY_LIST],
  },
  {
    path: paths.admin.projectsList,
    element: <SuspendedRoute element={<ProjectsListPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_LIST_VIEW],
  },
  {
    path: paths.admin.usersList,
    element: <SuspendedRoute element={<UsersListPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_LIST_VIEW],
  },
  {
    path: paths.admin.projectCreate,
    element: <SuspendedRoute element={<CreateProjectPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_CREATE],
  },
  {
    path: paths.project.tabs.kanban,
    element: <SuspendedRoute element={<Kanban />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
];

export const projectTabsRoutes: Array<Routes> = [
  {
    path: paths.project.tabs.overview,
    element: <SuspendedRoute element={<ProjectOverviewPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.rolesAndResponsibilities,
    element: <SuspendedRoute element={<ProjectRolesAndResponsibilitiesPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.about,
    element: <SuspendedRoute element={<ProjectAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.steerco.list,
    element: <SuspendedRoute element={<ProjectAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.steerco.create,
    element: <SuspendedRoute element={<ProjectAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.steerco.delete,
    element: <SuspendedRoute element={<ProjectAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.steerco.update,
    element: <SuspendedRoute element={<ProjectAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.previousReports,
    element: <SuspendedRoute element={<ProjectPreviousReportsPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.deEscalatedWp,
    element: <SuspendedRoute element={<ProjectDeEscalatedWpPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
  {
    path: paths.project.tabs.attachments,
    element: <SuspendedRoute element={<ProjectAttachmentsPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.PROJECT_GET],
  },
];

export const wpTabsRoutes: Array<Routes> = [
  {
    path: paths.workPackages.tabs.overview,
    element: <SuspendedRoute element={<WpOverviewPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET],
  },
  {
    path: paths.workPackages.tabs.targets,
    element: <SuspendedRoute element={<WpTargetsPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET, USER_PERMISSION.WORK_PACKAGE_TARGET_LIST],
  },
  {
    path: paths.workPackages.tabs.kpis.list,
    element: <SuspendedRoute element={<WpKpisPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET, USER_PERMISSION.KPI_LIST],
  },
  {
    path: paths.workPackages.tabs.measures,
    element: <SuspendedRoute element={<WpMeasuresPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET, USER_PERMISSION.WORK_PACKAGE_MEASURE_LIST],
  },
  {
    path: paths.workPackages.tabs.about,
    element: <SuspendedRoute element={<WpAboutPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET],
  },
  {
    path: paths.workPackages.tabs.attachments,
    element: <SuspendedRoute element={<WpAttachmentsPage />} fallback={<DashboardSkeleton />} />,
    routePermissions: [USER_PERMISSION.WORK_PACKAGE_GET],
  },
];

export const allRoutes = [...mainRoutes, ...projectTabsRoutes, ...wpTabsRoutes];
