import { Nullable, ObjectValues, SelectWithEmptyValue } from '@/core/interfaces/helpers';
import { UserOptionType } from '@/core/components/InviteUserField';

export const WorkPackagesTag = 'WorkPackages' as const;

export const WeekProgressTag = 'WeekProgressTag' as const;

export const WORK_PACKAGE_STATE_STATUS = {
  GREEN: 'GREEN',
  RED: 'RED',
  YELLOW: 'YELLOW',
} as const;

export const WORK_PACKAGE_TYPE = {
  HR: 'hr',
  PRODUCTION: 'production',
  SUPPLY_AND_DEMAND: 'supply_and_demand',
  QUALITY: 'quality',
  OTHER: 'other',
} as const;

export const WORK_PACKAGE_TYPES = [
  {
    label: 'HR',
    value: WORK_PACKAGE_TYPE.HR,
  },
  {
    label: 'Production',
    value: WORK_PACKAGE_TYPE.PRODUCTION,
  },
  {
    label: 'Supply and demand',
    value: WORK_PACKAGE_TYPE.SUPPLY_AND_DEMAND,
  },
  {
    label: 'Quality',
    value: WORK_PACKAGE_TYPE.QUALITY,
  },
  {
    label: 'Other',
    value: WORK_PACKAGE_TYPE.OTHER,
  },
];

export type WorkPackageType = ObjectValues<typeof WORK_PACKAGE_TYPE> | string;

export type WorkPackageInviteUser = {
  userId: string;
  inviteUser: string;
};

export type WorkPackageUser = {
  userId: string;
};

export type WorkPackageNewUserDataForm = {
  email: string;
  firstName: string;
  lastName: string;
  isLeader: boolean;
  isContributor: boolean;
  displayPersonalDataInputs: boolean;
  companyId: string;
};

export type WorkSubPackageForm = {
  name: string;
  number: string;
  type: SelectWithEmptyValue<WorkPackageType>;
  leaders: Array<UserOptionType>;
  contributors: Array<UserOptionType>;
  userPersonalization: Array<WorkPackageNewUserDataForm>;
  onboardingDocument: string;
};

export type WorkPackageForm = WorkSubPackageForm & {
  subpackages?: Array<WorkPackageForm>;
};

export type WorkPackageNewUserData = {
  email: string;
  name: string;
  companyId: string;
};

export type WorkPackageUserData = {
  id: string;
  name: string;
};

export type GetWorkPackage = {
  report: any;
  id: string;
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
  userPersonalization: Array<WorkPackageNewUserData>;
  onboardingDocument: string;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  updatedAt: string;
  updatedBy: {
    id: string;
    name: string;
  };
};

export type WorkPackageReport = {
  highlights: string;
  roadblocks: string;
  nextSteps: string;
};

export type GetNewWorkPackage = {
  id: string;
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  updatedAt: string;
  updatedBy: {
    id: string;
    name: string;
  };
  report: WorkPackageReport | null;
};

export type CreateWorkPackage = {
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
  userPersonalization: Array<WorkPackageNewUserData>;
  onboardingDocument: string;
};

export type CreateNewWorkPackage = {
  projectId: number;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
};

export type GetWorkPackageParams = {
  workPackageId: string;
};

export type UpdateWorkPackage = {
  status: {
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  };
  onboardingDocument?: string;
};

export type UpdateNewWorkPackage = {
  projectId: number;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
};

export type GetWeekProgress = { current: Nullable<string>; previous: Nullable<string>; createdAt: Nullable<string> };

export type GetWeekProgressParams = { workPackageId: string };

export type GetArchiveWpParams = { workPackageId: string };

export type GetUnarchiveWpParams = { workPackageId: string };

export type GetSnapshot = {
  id: string;
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
  userPersonalization: Array<WorkPackageNewUserData>;
  onboardingDocument: string;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  updatedAt: string;
  updatedBy: {
    id: string;
    name: string;
  };
};

export type GetSnapshotParams = {
  workPackageId: string;
};

export type GetExport = {
  id: string;
  projectId: string;
  name: string;
  number: string;
  type: WorkPackageType;
  leaders: Array<WorkPackageInviteUser>;
  contributors: Array<WorkPackageInviteUser>;
  userPersonalization: Array<WorkPackageNewUserData>;
  onboardingDocument: string;
  status: Nullable<{
    previous: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    current: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
    prognosis: Nullable<ObjectValues<typeof WORK_PACKAGE_STATE_STATUS>>;
  }>;
  updatedAt: string;
  updatedBy: {
    id: string;
    name: string;
  };
};

export type GetExportParams = {
  workPackageId: string;
};

export type GenerateExport = {
  workPackageId: string;
};

export type GetWpUsers = {
  leaders: Array<WorkPackageUserData>;
  contributors: Array<WorkPackageUserData>;
};

export type GetWpUsersParams = {
  workPackageId: string;
};
