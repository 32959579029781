import { useEffect, useRef } from 'react';
import markerSDK, { MarkerSdk } from '@marker.io/browser';

import { useAppSelector } from '@/core/store/store';
import { selectIsLoggedIn, useGetMyProfileQuery } from '@/core/store/authSlice';

export const MarkerIO = () => {
  const markerRef = useRef<MarkerSdk>();
  const markerIOProjectId = '64cb8c1317a4831f1b12ad38';

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const { data: userProfileData, isSuccess } = useGetMyProfileQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    const hasInitializedWidget = document.querySelector('.marker-app');

    const loadMarkerIO = async () => {
      markerRef.current = await markerSDK.loadWidget({ project: markerIOProjectId });
    };

    if (!hasInitializedWidget) {
      loadMarkerIO();
    }
  }, [markerRef.current]);

  useEffect(() => {
    if (isSuccess) {
      const { email, name } = userProfileData;

      markerRef.current?.setReporter({
        email,
        fullName: name,
      });
    }
  }, [isSuccess, userProfileData]);

  return null;
};
