const UserActiveIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0008 14.1667C13.0528 14.1667 15.7217 15.4793 17.1734 17.4373L15.6384 18.1634C14.4569 16.7632 12.3737 15.8334 10.0008 15.8334C7.62797 15.8334 5.54467 16.7632 4.36322 18.1634L2.82886 17.4366C4.28069 15.479 6.9492 14.1667 10.0008 14.1667ZM10.0008 1.66675C12.302 1.66675 14.1675 3.53223 14.1675 5.83341V8.33341C14.1675 10.6346 12.302 12.5001 10.0008 12.5001C7.69962 12.5001 5.83414 10.6346 5.83414 8.33341V5.83341C5.83414 3.53223 7.69962 1.66675 10.0008 1.66675Z"
          fill="#1063FF"
        />
      </svg>
    </div>
  );
};

export default UserActiveIcon;
