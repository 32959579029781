const UserIcon = (props: any) => {
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0008 14.1667C13.0528 14.1667 15.7217 15.4792 17.1734 17.4372L15.6384 18.1633C14.4569 16.7631 12.3737 15.8333 10.0008 15.8333C7.62797 15.8333 5.54467 16.7631 4.36322 18.1633L2.82886 17.4365C4.28069 15.4789 6.9492 14.1667 10.0008 14.1667ZM10.0008 1.66666C12.302 1.66666 14.1675 3.53214 14.1675 5.83332V8.33332C14.1675 10.574 12.3989 12.4015 10.1816 12.4962L10.0008 12.5C7.69962 12.5 5.83414 10.6345 5.83414 8.33332V5.83332C5.83414 3.5927 7.60273 1.76514 9.82008 1.67051L10.0008 1.66666ZM10.0008 3.33332C8.66942 3.33332 7.58109 4.37409 7.50505 5.68643L7.50081 5.83332V8.33332C7.50081 9.71407 8.62008 10.8333 10.0008 10.8333C11.3322 10.8333 12.4205 9.79257 12.4966 8.48024L12.5008 8.33332V5.83332C12.5008 4.45261 11.3815 3.33332 10.0008 3.33332Z"
          fill="#7F8185"
        />
      </svg>
    </div>
  );
};

export default UserIcon;
