import React, { forwardRef, useId } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';

type SelectProps = MuiSelectProps & {
  children: React.ReactNode;
  label?: React.ReactNode;
  helperText?: string;
  fullWidth?: boolean;
  maxSelection?: number;
};

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  ({ children, helperText, label, fullWidth = true, maxSelection = Infinity, ...selectProps }, ref) => {
    const id = useId();

    const isOptionDisabled = (value: string) => {
      const selectedOptions = Array.isArray(selectProps.value) ? selectProps.value : [selectProps.value];
      return selectedOptions.length >= maxSelection && !selectedOptions.includes(value);
    };

    return (
      <FormControl fullWidth={fullWidth} style={{ margin: '0' }}>
        {label && <InputLabel id={id}>{label}</InputLabel>}
        <MuiSelect {...selectProps} labelId={id} ref={ref} style={{ margin: '0' }}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                disabled: isOptionDisabled(child.props.value as string),
              } as React.Attributes & { disabled?: boolean });
            }

            return child;
          })}
        </MuiSelect>
        <FormHelperText error={selectProps.error} style={{ margin: '0' }}>
          {helperText}
        </FormHelperText>
      </FormControl>
    );
  },
);

Select.defaultProps = {
  label: undefined,
  helperText: undefined,
  fullWidth: true,
  maxSelection: Infinity,
};

Select.displayName = 'Select';