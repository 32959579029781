import { combineReducers } from '@reduxjs/toolkit';

import { apiSlice } from '@/core/store/apiSlice';
import { metaSlice } from '@/core/store/metaSlice';
import { authSlice } from '@/core/store/authSlice';
import { projectsSlice } from '@/core/store/projectsSlice';
import { kpisSlice } from '@/core/store/kpisSlice';
import { workPackageAddedSlice } from '@/core/store/workPackagesSlice';
import { calendarWeekSlice } from '@/core/store/calendarWeekSlice';

export const rootReducer = combineReducers({
  [metaSlice.name]: metaSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [projectsSlice.name]: projectsSlice.reducer,
  [kpisSlice.name]: kpisSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [workPackageAddedSlice.name]: workPackageAddedSlice.reducer,
  [calendarWeekSlice.name]: calendarWeekSlice.reducer,
});
