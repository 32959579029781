import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/core/store/store';

interface CalenderWeekState {
  calendarWeek: number;
}

const initialState: CalenderWeekState = {
  calendarWeek: 0,
};

export const calendarWeekSlice = createSlice({
  name: 'calendarWeek',
  initialState,
  reducers: {
    setCalendarWeek(state, action: PayloadAction<number>) {
      state.calendarWeek = action.payload;
    },
  },
});

export const { setCalendarWeek } = calendarWeekSlice.actions;

const calendarWeekSelector = (state: RootState): CalenderWeekState => state[calendarWeekSlice.name];

export const selectCalendarWeek = createSelector(calendarWeekSelector, (state) => state.calendarWeek);

export default calendarWeekSlice.reducer;
