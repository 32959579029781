import { lazy } from 'react';

export const SignInPage = lazy(() => import('./SignInPage/SignInPage'));
export const SignUpPage = lazy(() => import('./SignUpPage/SignUpPage'));
export const ResetPassword = lazy(() => import('./ResetPassword'));
export const ComingSoonPage = lazy(() => import('./ComingSoonPage/ComingSoonPage'));
export const EmailVerificationPage = lazy(() => import('./EmailVerificationPage/EmailVerificationPage'));
export const FAQPage = lazy(() => import('./FAQPage/FAQPage'));
export const NotFoundPage = lazy(() => import('./404/NotFoundPage'));
export const UnauthorizedPage = lazy(() => import('./401/UnauthorizedPage'));
export const CompaniesListPage = lazy(() => import('./CompaniesListPage'));
export const ProjectsListPage = lazy(() => import('./ProjectsListPage'));
export const UsersListPage = lazy(() => import('./UsersListPage'));

export const ProjectTabs = lazy(() => import('./ProjectTabs'));
export const ProjectOverviewPage = lazy(() => import('./ProjectOverviewPage'));
export const ProjectRolesAndResponsibilitiesPage = lazy(() => import('./ProjectRolesAndResponsibilitiesPage'));
export const ProjectAboutPage = lazy(() => import('./ProjectAboutPage'));
export const ProjectPreviousReportsPage = lazy(() => import('./ProjectPreviousReportsPage'));
export const ProjectDeEscalatedWpPage = lazy(() => import('./ProjectDeEscalatedWpPage'));
export const ProjectAttachmentsPage = lazy(() => import('./ProjectAttachmentsPage'));
export const CreateProjectPage = lazy(() => import('./Projects/CreateProjectPage'));

export const WpTabs = lazy(() => import('./WpTabs'));
export const WpOverviewPage = lazy(() => import('./WpOverviewPage'));
export const WpTargetsPage = lazy(() => import('./WpTargetsPage'));
export const WpKpisPage = lazy(() => import('./WpKpisPage'));
export const WpMeasuresPage = lazy(() => import('./WpMeasuresPage'));
export const WpAboutPage = lazy(() => import('./WpAboutPage'));
export const WpAttachmentsPage = lazy(() => import('./WpAttachmentsPage'));

export const CreateKPIPage = lazy(() => import('./KPIWizardPage/CreateKPIPage'));
export const EditKPIPage = lazy(() => import('./KPIWizardPage/EditKPIPage'));
export const Onboading = lazy(() => import('./OnboardingPage'));

export const Kanban = lazy(() => import('./KanbanPage/KanbanPage'));
