import { Route, Routes } from 'react-router-dom';
import React from 'react';

import { PageLayout } from '@/layouts';
import { ComingSoonPage, FAQPage, Kanban, NotFoundPage, Onboading, ProjectTabs, WpTabs } from '@/pages';

import { paths } from '../router/paths';
import { mainRoutes, projectTabsRoutes, wpTabsRoutes } from '../router/routes';

export const RoutePaths = {
  profile: '/profile',
  settings: '/settings',
  emailVerification: '/verify',
  faq: '/faq',
  support: '/support',
  onboarding: '/onboarding',
  kanban: '/kanban',
  error: '/404/NotFoundPage',
  ...paths,
};

export const RoutesProvider = () => (
  <Routes>
    <Route
      path={RoutePaths.profile}
      element={
        <PageLayout>
          <ComingSoonPage />
        </PageLayout>
      }
    />
    <Route
      path={RoutePaths.settings}
      element={
        <PageLayout>
          <ComingSoonPage />
        </PageLayout>
      }
    />
    <Route
      path={RoutePaths.emailVerification}
      element={
        <PageLayout>
          <ComingSoonPage />
        </PageLayout>
      }
    />
    <Route
      path={RoutePaths.faq}
      element={
        <PageLayout>
          <FAQPage />
        </PageLayout>
      }
    />
    <Route
      path={RoutePaths.support}
      element={
        <PageLayout>
          <ComingSoonPage />
        </PageLayout>
      }
    />

    <Route
      path={RoutePaths.project.index}
      element={
        <PageLayout>
          <ProjectTabs />
        </PageLayout>
      }
    >
      {projectTabsRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Route>

    <Route
      path={RoutePaths.workPackages.index}
      element={
        <PageLayout>
          <WpTabs />
        </PageLayout>
      }
    >
      {wpTabsRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Route>

    <Route path={RoutePaths.onboarding} element={<Onboading />} />
    <Route
      path={RoutePaths.kanban}
      element={
        <PageLayout>
          <Kanban />
        </PageLayout>
      }
    />

    {mainRoutes.map(({ path, element }) => (
      <Route key={path} path={path} element={<PageLayout>{element}</PageLayout>} />
    ))}

    <Route
      path="*"
      element={
        <PageLayout>
          <NotFoundPage />
        </PageLayout>
      }
    />
  </Routes>
);
