// Badge.styles.ts
import styled from 'styled-components';

import { colors } from '@/themes/colorsV2';

export const ListElement = styled.li<{ hasIcon?: boolean | 'undefined'; active?: boolean | 'undefined' }>`
  padding: ${({ hasIcon }) => (hasIcon ? '5px 10px' : '5px 5px')};
  display: flex;
  padding: 10px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ active }) =>
    active &&
    `
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    background-color: ${colors.core.white};
    order: -1;
  `};

  &:hover {
    background: ${({ active }) => (!active ? colors.grey.light : colors.core.white)};
  }
`;

export const ListImage = styled.img<{ src?: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
