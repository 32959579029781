import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/core/store/store';

interface MetaState {
  isDrawerOpen: boolean;
}

const initialState: MetaState = {
  isDrawerOpen: true,
};

export const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setIsDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { setIsDrawerOpen } = metaSlice.actions;

const metaSelector = (state: RootState): MetaState => state.meta;

export const selectIsDrawerOpen = createSelector(metaSelector, (state) => state.isDrawerOpen);

export default metaSlice.reducer;
