import styled, { css } from 'styled-components';

import { colors } from '@/themes/colorsV2';

export const TagContainer = styled.div<{ $size?: 'small' | 'big'; $color?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ $color }) => ($color ? `${$color}` : 'var(--color-grey-extraLight)')};
  padding: 6px 7px;
  border-radius: var(--radius-s);

  ${({ $size }) =>
    $size === 'big' &&
    css`
      padding: 11px;
      border-radius: var(--radius-m);
    `}

  ${({ $size }) =>
    ($size === 'small' || typeof $size === undefined) &&
    css`
      padding: 6px 7px;
      border-radius: var(--radius-s);
    `}
`;
