import styled, { css } from 'styled-components';

export type StyledTrafficLightProps = {
  size?: number;
  withBorder?: boolean;
  mask?: string | boolean | null;
  backgroundColor: string;
  borderColor: string;
};

const BORDER_WIDTH = 4;

export const TrafficLight = styled.div<StyledTrafficLightProps>`
  --traffic-light-size: ${({ withBorder, size = 16 }) => `${withBorder ? size + BORDER_WIDTH : size}px`};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: var(--traffic-light-size);
  height: var(--traffic-light-size);
  min-width: var(--traffic-light-size);
  min-height: var(--traffic-light-size);

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ mask }) => (mask ? '0' : '50%')};

  ${({ mask }) =>
    typeof mask !== 'boolean' &&
    mask &&
    css`
      mask-image: url('/assets/svg/${mask}.svg');
      mask-repeat: no-repeat;
    `}

  ${({ mask }) =>
    typeof mask !== 'boolean' &&
    mask?.includes('rotated') &&
    css`
      width: calc(var(--traffic-light-size) + 2px);
      height: calc(var(--traffic-light-size) + 2px);
      min-width: calc(var(--traffic-light-size) + 2px);
      min-height: calc(var(--traffic-light-size) + 2px);
    `}

  ${({ withBorder, borderColor }) =>
    withBorder &&
    css`
      border: ${`${BORDER_WIDTH}px solid ${borderColor}`};
    `}
`;
