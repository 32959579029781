type PrimaryColors = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type NeutralColors = 25 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'white' | 'black';

type SemanticColors = 'warning' | 'success' | 'danger' | 'info' | 'trafficLight';

type SemanticColorValues = 50 | 200 | 500 | 'alpha40';

type SemanticColor = {
  [value in SemanticColorValues]: string;
};

type TransparenciesColors = 'white' | 'black';

type TransparenciesColorValues = 100 | 200 | 300 | 400 | 500;

type TransparencyColor = {
  [value in TransparenciesColorValues]: string;
};

type Colors = {
  primary: { [key in PrimaryColors]: string };
  semantic: { [key in SemanticColors]: SemanticColor };
  neutral: { [key in NeutralColors]: string };
  transparencies: { [key in TransparenciesColors]: TransparencyColor };
};

export const colors: Colors = {
  primary: {
    50: '#E6E6FB',
    100: '#BFBFF6',
    200: '#9999F1',
    300: '#7373EB',
    400: '#4D4DE6',
    500: '#0000DB',
    600: '#0000B6',
    700: '#000091',
    800: '#00006B',
    900: '#000046',
  },
  semantic: {
    warning: {
      50: '#FFF7DA',
      200: '#FFE58D',
      500: '#FFC700',
      alpha40: 'rgba(255, 199, 0, 0.4)',
    },
    success: {
      50: '#E6FAE9',
      200: '#A6DBAE',
      500: '#67A270',
      alpha40: 'rgba(103, 162, 112, 0.4)',
    },
    danger: {
      50: '#FFE0DA',
      200: '#FF9581',
      500: '#E10600',
      alpha40: 'rgba(255, 117, 90, 0.4)',
    },
    info: {
      50: '#E0F2FE',
      200: '#9FDFFA',
      500: '#36BFFA',
      alpha40: 'rgba(54, 191, 250, 0.4)',
    },
    trafficLight: {
      50: '#F2FBF6',
      200: '#CCEDD9',
      500: '#00A541',
      alpha40: 'rgba(0, 165, 65, 0.4)',
    },
  },
  neutral: {
    25: '#FAFBFC',
    50: '#F8F9FA',
    100: '#F2F3F5',
    200: '#E4E7EC',
    300: '#BCC3CF',
    400: '#98A1AF',
    500: '#7F8898',
    600: '#646E80',
    700: '#4B5566',
    800: '#333C4A',
    900: '#1F2631',
    white: '#FFFFFF',
    black: '#000021',
  },
  transparencies: {
    black: {
      100: 'rgba(20, 25, 33, 0.16)',
      200: 'rgba(20, 25, 33, 0.24)',
      300: 'rgba(20, 25, 33, 0.32)',
      400: 'rgba(20, 25, 33, 0.5)',
      500: 'rgba(20, 25, 33, 0.7)',
    },
    white: {
      100: 'rgba(255, 255, 255, 0.08)',
      200: 'rgba(255, 255, 255, 0.16)',
      300: 'rgba(255, 255, 255, 0.24)',
      400: 'rgba(255, 255, 255, 0.32)',
      500: 'rgba(255, 255, 255, 0.4)',
    },
  },
};

export const chartColorPallete = [
  '#03033C',
  '#7A7AEC',
  '#ABABFD',
  '#CBCBF4',
  '#A2EEC5',
  '#80C2E8',
  '#4985CB',
  '#00000F',
];
export const chartLinesColorPallete = ['#E1E1E1', '#F2B544', '#FA5351', '#DA014F', '#8C00BD'];
