import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCase } from 'change-case';

import { RootState } from '@/core/store/store';
import { apiSlice } from '@/core/store/apiSlice';
import {
  CreateKpi,
  GetKpi,
  GetKpisList,
  GetKpisListParams,
  GetKpiValues,
  GlidepathMeasureItem,
  KpiMeasureTag,
  KpisTag,
  KpiValuesChartPreview,
  KpiWizardForm,
  UpdateKpi,
  UpdateKpiValues,
  GetKpisSnapshotParams,
  GetKpisSnapshot,
  DuplicateKpi,
  UpdateKpiStatus,
} from '@/core/interfaces/Kpis';
import { GetMeasuresParams } from '@/core/interfaces/Measures';

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKpisList: builder.query<GetKpisList, { wpId: string; filters?: GetKpisListParams }>({
      query: ({ wpId, filters }) => ({
        url: `/v2/work-packages/${wpId}/kpis/?order_by=-pinned`,
        params: filters,
      }),
      providesTags: (result) =>
        result?.items ? [...result.items.map(({ id }) => ({ type: KpisTag, id })), KpisTag] : [KpisTag],
    }),
    getKpiMeasuresList: builder.query<
      Array<GlidepathMeasureItem>,
      { kpiId: string; filters?: Partial<Omit<GetMeasuresParams, 'workPackageId'>> }
    >({
      query: ({ kpiId, filters }) => ({
        url: `/v2/kpis/${kpiId}/measures/`,
        params: {
          ...filters,
          ...(filters?.orderBy && { orderBy: snakeCase(filters.orderBy) }),
        },
      }),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: KpiMeasureTag, id })), KpiMeasureTag] : [KpiMeasureTag],
    }),
    getKpiSettings: builder.query<GetKpi, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/${uuid}/`,
      }),
      forceRefetch: () => true,
    }),
    getKpiValues: builder.query<GetKpiValues, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/${uuid}/data/`,
        params: { format: 'form' },
      }),
      forceRefetch: () => true,
    }),
    getKpiTemplate: builder.query<GetKpiValues, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/{${uuid}/template/`,
        responseHandler: async (response) => {
          const hiddenElement = document.createElement('a');
          const file = window.URL.createObjectURL(await response.blob());

          hiddenElement.href = file;
          hiddenElement.target = '_blank';
          hiddenElement.download = `template_${Date.now()}.xlsx`;
          hiddenElement.click();

          return null;
        },
        cache: 'no-cache',
      }),
      forceRefetch: () => true,
    }),
    createKpi: builder.mutation<GetKpi, CreateKpi>({
      query: (body) => ({
        url: `/v2/kpis/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [KpisTag],
    }),
    updateKpiSettings: builder.mutation<GetKpi, { uuid: string; body: UpdateKpi }>({
      query: ({ uuid, body }) => ({
        url: `/v2/kpis/${uuid}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [KpisTag],
    }),
    updateKpiValues: builder.mutation<GetKpiValues, { uuid: string; body: UpdateKpiValues }>({
      query: ({ uuid, body }) => ({
        url: `/v2/kpis/${uuid}/data/`,
        method: 'PATCH',
        body,
      }),
    }),
    uploadKpiValuesFile: builder.mutation<any, { uuid: string; body: FormData }>({
      query: ({ uuid, body }) => ({
        url: `/v2/kpis/${uuid}/data/`,
        method: 'PUT',
        body,
      }),
    }),
    updateKpiGlidepathMeasures: builder.mutation<GetKpi, { uuid: string; body: Array<GlidepathMeasureItem> }>({
      query: ({ uuid, body }) => ({
        url: `/v2/kpis/${uuid}/measures/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [KpisTag, KpiMeasureTag],
    }),
    archiveKpi: builder.mutation<GetKpi, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/${uuid}/archive/`,
        method: 'POST',
      }),
      invalidatesTags: [KpisTag],
    }),
    unarchiveKpi: builder.mutation<GetKpi, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/${uuid}/unarchive/`,
        method: 'POST',
      }),
      invalidatesTags: [KpisTag],
    }),
    getKpisSnapshot: builder.query<GetKpisSnapshot, { wpId: string; kpiId: string; filters?: GetKpisSnapshotParams }>({
      query: ({ wpId, kpiId, filters }) => ({
        url: `/v2/work-packages/${wpId}/kpis/${kpiId}`,
        params: filters,
      }),
      providesTags: (result) =>
        result?.items ? [...result.items.map(({ id }) => ({ type: KpisTag, id })), KpisTag] : [KpisTag],
    }),
    duplicateKpi: builder.mutation<DuplicateKpi, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/duplicate_kpi/${uuid}`,
        method: 'POST',
      }),
      invalidatesTags: [KpisTag],
    }),
    UpdateKpiStatus: builder.mutation<UpdateKpi, { uuid: string; body: UpdateKpiStatus }>({
      query: ({ uuid, body }) => ({
        url: `/v2/kpis/${uuid}/status/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [KpisTag],
    }),
    DeleteKpi: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/v2/kpis/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: [KpisTag],
    }),
  }),
});

export const {
  useGetKpisListQuery,
  useGetKpiMeasuresListQuery,
  useGetKpiSettingsQuery,
  useGetKpiValuesQuery,
  useGetKpiTemplateQuery,
  useCreateKpiMutation,
  useUpdateKpiSettingsMutation,
  useUpdateKpiValuesMutation,
  useUploadKpiValuesFileMutation,
  useUpdateKpiGlidepathMeasuresMutation,
  useArchiveKpiMutation,
  useGetKpisSnapshotQuery,
  useDuplicateKpiMutation,
  useUpdateKpiStatusMutation,
  useUnarchiveKpiMutation,
  useDeleteKpiMutation,
} = extendedApi;
export const { getKpiTemplate, getKpiValues } = extendedApi.endpoints;

interface KPIsState {
  wizardKpiSettingsForm: Partial<KpiWizardForm>;
  wizardKpiValuesPreview?: KpiValuesChartPreview;
}

const initialState: KPIsState = {
  wizardKpiSettingsForm: {},
  wizardKpiValuesPreview: undefined,
};

export const kpisSlice = createSlice({
  name: 'KPIs',
  initialState,
  reducers: {
    resetKpiForm(state) {
      state.wizardKpiSettingsForm = {};
    },
    setKpiSettings(state, action: PayloadAction<Partial<KpiWizardForm>>) {
      state.wizardKpiSettingsForm = action.payload;
    },
    setKpiValuesPreview(state, action: PayloadAction<KpiValuesChartPreview>) {
      state.wizardKpiValuesPreview = action.payload;
    },
  },
});

export const { resetKpiForm, setKpiSettings, setKpiValuesPreview } = kpisSlice.actions;

const kpisSelector = (state: RootState): KPIsState => state.KPIs;

export const selectWizardKpiSettingsForm = createSelector(kpisSelector, (state) => state.wizardKpiSettingsForm);
export const selectWizardKpiValuesPreview = createSelector(kpisSelector, (state) => state.wizardKpiValuesPreview);

export default kpisSlice.reducer;
