import React, { useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { Trans, useTranslation } from 'react-i18next';

import { formatBytes } from '@/utils/utils';
import { colors } from '@/themes/colors';

import * as S from './FileUpload.styles';

export type FileUploadProps = {
  fileTypes: Array<string>;
  maxFileSize: number;
  uploadProgress: number;
  onChange: (file: File) => Promise<boolean>;
  onTypeError?: () => void;
  onSizeError?: () => void;
  error?: boolean;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  uploadProgress,
  fileTypes,
  maxFileSize,
  onChange,
  onTypeError,
  onSizeError,
  error,
}) => {
  const { t } = useTranslation('Common');

  const [uploadedFile, setUploadedFile] = useState<File>();

  const handleChange = async (file: File) => {
    setUploadedFile(file);

    const result = await onChange(file);

    if (!result) {
      setUploadedFile(undefined);
    }
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      maxSize={maxFileSize}
      onTypeError={onTypeError}
      onSizeError={onSizeError}
    >
      {uploadedFile ? (
        <S.UploadWrapper>
          <S.FileInfoRow>
            <S.ImageOutlinedIcon />
            <S.FileNameColumn>
              <Typography variant="body2" color={colors.neutral.black}>
                {uploadedFile.name}
              </Typography>
              <S.FileMetadata>
                <Typography variant="body2" color={colors.neutral[600]}>
                  {formatBytes(uploadedFile.size)}
                </Typography>
                <Typography variant="body2" color={colors.neutral[600]}>{`${uploadProgress}%`}</Typography>
              </S.FileMetadata>
            </S.FileNameColumn>
          </S.FileInfoRow>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </S.UploadWrapper>
      ) : (
        <S.Wrapper error={error}>
          <Typography variant="body2">
            <Trans i18nKey="fileUpload.uploadTitle" ns="Common">
              Drag & drop or <S.BlueStrong>Choose file</S.BlueStrong> to upload
            </Trans>
          </Typography>
          <Typography variant="body2" color={colors.neutral[600]}>
            {t('fileUpload.format')}
            {fileTypes.map(
              (format, idx) => ` .${format} ${fileTypes.length !== idx + 1 ? t('fileUpload.formatSeparator') : ''}`,
            )}
          </Typography>
        </S.Wrapper>
      )}
    </FileUploader>
  );
};

FileUpload.defaultProps = {
  onTypeError: undefined,
  onSizeError: undefined,
  error: undefined,
};
