import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';

import { apiSlice } from '@/core/store/apiSlice';
import { rootReducer } from '@/core/store/rootReducer';

const storeConfig = {
  devTools: process.env.NODE_ENV === 'development',
  reducer: rootReducer,
  middleware: (gDM: CurriedGetDefaultMiddleware) => gDM().concat(apiSlice.middleware),
};

export const createStore = () => configureStore(storeConfig);

export const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const createStoreWithPreloadedState = (preloadedState: RootState) =>
  configureStore({
    ...storeConfig,
    preloadedState,
  });

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
