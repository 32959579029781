/* eslint-disable */
import React, { useState } from 'react';
import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
  ArrowDownward,
  GetAppOutlined as GetAppOutlinedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { FileUploader } from 'react-drag-drop-files';
import { Trans, useTranslation } from 'react-i18next';

import { formatBytes } from '@/utils/utils';
import { colors } from '@/themes/colors';
import { colors as colorsv2 } from '@/themes/colorsV2';
import { UploadType } from '@/core/componentsv2/BackendFileUpload/BackendFileUpload';
import { UPLOAD_TYPE } from '@/core/components/FirebaseFileUpload';
import FigmaFileIcon from '@/assets/svg/figma_file';
import FigmaFilledDeleteIcon from '@/assets/svg/figma_filled_delete';

import { BorderLinearProgress, FileDropStyle } from './FileUpload.styles';
import * as S from './FileUpload.styles';

export type FileUploadProps = {
  fileTypes: Array<string>;
  maxFileSize: number;
  uploadProgress: number;
  uploadType: UploadType;
  onChange: (file: File) => Promise<boolean>;
  onTypeError?: () => void;
  onSizeError?: () => void;
  error?: boolean;
  completed: boolean;
  onDeleted: () => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
                                                        uploadProgress,
                                                        fileTypes,
                                                        maxFileSize,
                                                        uploadType,
                                                        onChange,
                                                        onTypeError,
                                                        onSizeError,
                                                        error,
                                                        completed,
                                                        onDeleted,
                                                      }) => {
  const { t } = useTranslation('Common');

  const [uploadedFile, setUploadedFile] = useState<Array<File>>([]);

  const handleChange = async (newFile: File) => {
    // Check if the file already exists in the list
    if (uploadedFile.some((uploaded) => uploaded.name === newFile.name && uploaded.size === newFile.size)) {
      console.error('File already uploaded.');
      return;
    }

    // Validate the file (size, type, etc.)
    if (newFile.size > maxFileSize) {
      if (onSizeError) onSizeError();
      return;
    }

    // Add the file to the state
    const newList = [...uploadedFile, newFile];
    setUploadedFile(newList);

    try {
      // Create object URL for the file
      const downloadUrl = URL.createObjectURL(newFile);

      // Call onChange with the single File object
      const result = await onChange(newFile);

      if (typeof result === 'undefined') {
        // Remove file if upload fails (optional)
        setUploadedFile((prevFiles) => prevFiles.filter((f) => f !== newFile));
      }
    } catch (error) {
      console.error('Upload failed', error);
      // Handle upload error (optional)
    }
  };

  //download

  const handleDownload = (file: File) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      maxSize={maxFileSize}
      onTypeError={onTypeError}
      onSizeError={onSizeError}
      dropMessageStyle={FileDropStyle}
      hoverTitle={
        <Box>
          <ArrowDownward />
          {' ' + t('fileUpload.hoverTitle')}
        </Box>
      }
    >
      {uploadedFile.length > 0 ? (
        uploadedFile.map((file: File, idx: number) => {
          if (uploadType === UPLOAD_TYPE.WP_ONBOARDING_DOCUMENT) {
            return (
              <S.SupportingDocument key={idx}>
                <Stack flexDirection="row" alignItems="center">
                  <FigmaFileIcon />
                  <Typography
                    variant="bodyS-bold"
                    sx={{ marginRight: '8px', marginLeft: '8px' }}
                    color={colorsv2.core.black}
                  >
                    {file.name}
                  </Typography>
                  <Typography variant="bodyS" color={colorsv2.core.black}>
                    {formatBytes(file.size)}
                  </Typography>
                </Stack>
                <Box>
                  <IconButton onClick={() => handleDownload(file)}>
                    <GetAppOutlinedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setUploadedFile([]);
                      onDeleted();
                    }}
                  >
                    <FigmaFilledDeleteIcon />
                  </IconButton>
                </Box>
              </S.SupportingDocument>
            );
          } else {
            return (
              <S.UploadWrapper key={idx}>
                <S.FileInfoRow>
                  <S.ImageOutlinedIcon />
                  <S.FileNameColumn>
                    <Typography variant="body2" color={colors.neutral[600]}>
                      {file.name + ' ' + formatBytes(file.size)}
                    </Typography>
                  </S.FileNameColumn>
                  {completed ? (
                    <>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent event from bubbling to parent elements
                          handleDownload(file);
                        }}
                      >
                        <GetAppOutlinedIcon />
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent event from bubbling to parent elements
                          setUploadedFile([]);
                          onDeleted();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Box style={{ width: '50%' }}>
                      <BorderLinearProgress variant="determinate" value={uploadProgress} />
                    </Box>
                  )}
                </S.FileInfoRow>
              </S.UploadWrapper>
            );
          }
        })
      ) : (
        <S.Wrapper error={error}>
          <Typography variant="body2">
            <Trans i18nKey="fileUpload.uploadTitle" ns="Common">
              Drag & drop or <strong style={{ color: colorsv2.blue.default }}>manual file</strong> upload
            </Trans>
          </Typography>
          <Typography variant="bodyS" color={colorsv2.grey.default}>
            {uploadType === UPLOAD_TYPE.WP_ONBOARDING_DOCUMENT ? (
              <>
                {fileTypes.map(
                  (format, idx) =>
                    `${format}${fileTypes.length !== idx + 1 ? ', ' : ` ${t('fileUpload.formatSeparator')} `}`,
                )}
                {t('fileUpload.maxSize')}
                {' ' + formatBytes(maxFileSize)}
              </>
            ) : (
              <>
                {t('fileUpload.format')}
                {fileTypes.map(
                  (format, idx) => ` .${format} ${fileTypes.length !== idx + 1 ? t('fileUpload.formatSeparator') : ''}`,
                )}
                {t('fileUpload.maxSize')}
                {' ' + formatBytes(maxFileSize)}
              </>
            )}
          </Typography>
        </S.Wrapper>
      )}
    </FileUploader>
  );
};
