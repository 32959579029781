import {
  Check as CheckIcon,
  Remove as RemoveIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  ReportGmailerrorredOutlined as ReportGmailerrorredOutlinedIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material';
import React from 'react';

import { typography } from '@/themes/typography';
import { colors as colorsv2 } from '@/themes/colorsV2';

import { TypographyV2 } from './typographyV2';
import { colors } from './colors';

export const MuiButton = {
  defaultProps: {
    color: 'primary',
    variant: 'contained',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      fontFamily: 'Inter',
      borderRadius: 4,
      boxShadow: 'none',
      textTransform: 'none',
      color: colors.neutral.black,
      ':hover': {
        backgroundColor: colors.neutral[100],
        boxShadow: 'none',
      },
      ':disabled': {
        color: colors.neutral[300],
        boxShadow: 'none',
      },
      ':focus-visible': {
        color: colors.primary[600],
      },
    },
    sizeSmall: {
      padding: '6px 10px',
      fontWeight: '700',
      fontSize: '1.4rem',
      lineHeight: '2.0rem',
    },
    sizeMedium: {
      padding: '8px 16px',
      fontWeight: '700',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    sizeLarge: {
      padding: '12px 22px',
      fontWeight: '700',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    containedPrimary: {
      backgroundColor: colors.primary[500],
      color: colors.neutral.white,
      boxShadow: 'none',
      ':hover': {
        backgroundColor: colors.primary[600],
        color: colors.neutral.white,
        boxShadow: 'none',
      },
      ':disabled': {
        backgroundColor: colors.neutral[300],
        color: colors.neutral.white,
        boxShadow: 'none',
      },
      ':focus-visible': {
        backgroundColor: colors.primary[500],
        color: colors.neutral.white,
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 219, 0.2)',
      },
    },
    containedSecondary: {
      backgroundColor: colors.neutral.white,
      color: colors.primary[500],
      border: `1px solid ${colors.primary[500]}`,
      boxShadow: 'none',
      ':hover': {
        backgroundColor: colors.neutral[100],
        color: colors.primary[600],
        boxShadow: 'none',
      },
      ':disabled': {
        backgroundColor: colors.neutral[300],
        color: colors.neutral.white,
        boxShadow: 'none',
      },
      ':focus-visible': {
        backgroundColor: colors.neutral.white,
        color: colors.primary[500],
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 219, 0.2)',
      },
    },
    outlined: {
      backgroundColor: colors.neutral.white,
      color: colors.primary[500],
      border: `1px solid ${colors.primary[500]}`,
      boxShadow: 'none',
      ':hover': {
        border: `1px solid ${colors.primary[600]}`,
        backgroundColor: colors.neutral.white,
        color: colors.primary[600],
        boxShadow: 'none',
      },
      ':disabled': {
        border: `1px solid ${colors.neutral[300]}`,
        backgroundColor: colors.neutral[300],
        color: colors.neutral.white,
        boxShadow: 'none',
      },
      ':focus-visible': {
        backgroundColor: colors.neutral.white,
        color: colors.primary[500],
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 219, 0.2)',
      },
    },
    text: {
      height: 'auto',
    },
    textPrimary: {
      borderRadius: 0,
      padding: 0,
      color: colors.primary[500],
    },
    textSecondary: {
      color: '#04044B',
      border: 'none',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
  },
} as const;

export const MuiInputBase = {
  variants: [
    {
      props: { size: 'large' as const },
      style: {
        height: '48px',
      },
    },
  ],
  styleOverrides: {
    root: {
      borderRadius: '4px',
    },
    input: {
      height: '21px',
      padding: '8.5px 14px',
      color: colors.neutral.black,
      '&::placeholder': {
        color: colors.neutral[500],
        opacity: 1,
      },
    },
    sizeSmall: {
      input: {
        height: '13px',
        padding: '8.5px 14px',
      },
    },
    multiline: {
      textarea: {
        padding: '0',
      },
    },
  },
};

export const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      backgroundColor: colors.neutral.white,
      borderRadius: '4px',
      margin: 0, // onlick for calendar in nav bar
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.neutral[200],
        borderWidth: '1px',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.neutral[400],
        borderWidth: '1px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary[600],
        borderWidth: '1px',
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 219, 0.2)',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.semantic.danger[500],
        borderWidth: '1px',
      },
      '& .MuiInputAdornment-root': {
        margin: '0 0 0 16px',
      },
    },
    input: {
      height: '23px',
      padding: '8.5px 14px',
    },
  },
};

export const MuiFormHelperText = {
  styleOverrides: {
    root: {
      position: 'relative',
      top: '4px',
      fontWeight: '400',
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      color: colors.neutral[500],
      margin: 0,
      '&.Mui-error': {
        color: colors.semantic.danger[500],
        height: 0,
      },
    },
  },
} as const;

export const MuiCardContent = {
  styleOverrides: {
    root: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
};

export const MuiAvatar = {
  styleOverrides: {
    root: {
      width: '32px',
      height: '32px',
      backgroundColor: colors.primary[600],
      color: colors.neutral.white,
    },
  },
};

export const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      alignItems: 'center',
      margin: 0,
      '& .MuiTypography-root': {
        fontSize: '1.4rem',
        fontWeight: '500',
        lineHeight: '2rem',
        marginLeft: '12px',
      },
    },
  },
} as const;

export const MuiFormLabel = {
  styleOverrides: {
    root: {
      textAlign: 'left',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '2rem',
    },
  },
} as const;

export const MuiFormGroup = {
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
};

export const MuiInputLabel = {
  styleOverrides: {
    root: {
      position: 'relative',
      transform: 'none',
      color: colors.neutral.black,
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '2rem',
      marginBottom: '4px',
    },
  },
} as const;

export const MuiRadio = {
  defaultProps: {
    color: 'primary',
    checkedIcon: <CheckIcon />,
  },
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      margin: '-9px',
      '& svg': {
        color: 'transparent',
        fontSize: '1.6rem',
        border: `solid 1px ${colors.neutral[300]}`,
        borderRadius: '1.6rem',
      },
      '&.Mui-checked': {
        '& svg': {
          '&:not(&:focus)': {
            backgroundColor: 'white'[800],
            // border: `solid 1px ${colors.neutral[900]}`,
            color: 'green',
          },
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
} as const;

export const MuiCheckbox = {
  defaultProps: {
    color: 'primary',
    checkedIcon: <CheckIcon />,
    indeterminateIcon: <RemoveIcon />,
  },
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: 0,
      '& svg': {
        color: 'transparent',
        fontSize: '1.6rem',
        border: `solid 1px ${colors.neutral[300]}`,
        borderRadius: '2px',
      },
      '&.Mui-checked': {
        '& svg': {
          '&:not(&:focus-within)': {
            backgroundColor: colors.primary[50],
            border: `solid 1px ${colors.primary[600]}`,
            borderRadius: '2px',
            color: colors.primary[600],
          },
        },
      },
      '&.Mui-checked.Mui-disabled': {
        '& svg': {
          '&:not(&:focus-within)': {
            backgroundColor: colors.neutral[50],
            border: `solid 1px ${colors.neutral[300]}`,
            borderRadius: '2px',
            color: colors.neutral[300],
          },
        },
      },
      '&:focus-within': {
        borderRadius: '2px',
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 182, 0.2)',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
} as const;

export const MuiTypography = {
  defaultProps: {
    // variant: 'body2',
    variantMapping: {
      h1: 'h1',
      'h1-bold': 'h1',
      h3: 'h3',
      'h3-bold': 'h3',
      subtitle1: 'h4',
      'subtitle1-bold': 'h4',
      'subtitle2-bold': 'h5',
      body1: 'p',
      'body1-bold': 'p',
      body2: 'p',
      'body2-bold': 'p',
      caption1: 'span',
      'caption1-bold': 'span',
      'caption1-capital': 'span',
      'tag1-capital': 'span',
    },
  },
};

export const MuiSvgIcon = {
  styleOverrides: {
    root: {
      fontSize: '2rem',
    },
  },
};

export const MuiDialog = {
  styleOverrides: {
    container: {
      backgroundColor: alpha('#737386', 0.75),
    },
    paper: {
      padding: '40px',
      borderRadius: '4px',
    },
  },
};

export const MuiDialogTitle = {
  styleOverrides: {
    root: {
      fontWeight: '700',
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
      color: colors.neutral.black,
      padding: 0,
      marginBottom: '16px',
    },
  },
};

export const MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};

export const MuiDialogContentText = {
  styleOverrides: {
    root: {
      fontWeight: '400',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      color: colors.neutral[800],
    },
  },
};

export const MuiDialogActions = {
  styleOverrides: {
    root: {
      padding: 0,
      marginTop: '16px',
    },
  },
};

export const MuiSwitch = {
  styleOverrides: {
    root: {
      padding: 7,
    },
    switchBase: {
      color: colors.neutral.white,
    },
    colorPrimary: {
      '&.Mui-checked': {
        color: colors.neutral.white,
      },
    },
    track: {
      opacity: 1,
      backgroundColor: colors.neutral[300],
      height: 24,
      width: 44,
      borderRadius: 12,
      '.Mui-checked.Mui-checked + &': {
        opacity: 1,
        backgroundColor: '#04044B',
        height: 24,
        width: 44,
        borderRadius: 12,
      },
    },
  },
};

export const MuiAlert = {
  defaultProps: {
    iconMapping: {
      error: <ReportGmailerrorredOutlinedIcon />,
      warning: <ErrorOutlineOutlinedIcon />,
      success: <CheckCircleOutlineOutlinedIcon />,
    },
  },
  styleOverrides: {
    root: {
      padding: '11px 16px',
      margin: '16px 0',
      borderRadius: '8px',
      lineHeight: '20px',
      boxShadow: `
        0px 0px 2px rgba(27, 29, 34, 0.05),
        0px 1px 2px rgba(27, 29, 34, 0.02),
        0px 2px 4px rgba(27, 29, 34, 0.04),
        0px 6px 8px rgba(27, 29, 34, 0.02),
        0px 16px 16px rgba(27, 29, 34, 0.02)
      `,
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    message: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    standardError: {
      backgroundColor: colors.semantic.danger[50],
      color: colors.neutral[800],
      '& .MuiAlert-icon': {
        color: colors.semantic.danger[500],
      },
    },
    standardWarning: {
      backgroundColor: colors.semantic.warning[50],
      color: colors.neutral[800],
      '& .MuiAlert-icon': {
        color: colors.semantic.warning[500],
      },
    },
    action: {
      padding: 0,
    },
  },
};

export const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      fontSize: '1.2rem',
      color: colors.neutral.white,
      backgroundColor: colors.neutral[800],
      padding: '16px',
    },
    arrow: {
      color: '#000000',
    },
  },
};

export const MuiTableRow = {
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${colors.neutral[100]}`,
    },
  },
};

export const MuiTableCell = {
  styleOverrides: {
    sizeMedium: {
      padding: '28px 8px',
    },
    head: {
      fontSize: '12px',
      lineHeight: '16px',
      color: colors.neutral[600],
      '&.MuiTableCell-sizeMedium': {
        padding: '15px 8px',
      },
    },
  },
};

export const MuiChip = {
  styleOverrides: {
    root: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      '& .MuiChip-label': {
        padding: 0,
      },
    },
    filledDefault: {
      padding: '8px',
      borderRadius: '4px',
      backgroundColor: colors.neutral[200],
      color: colors.neutral[800],
    },
    outlinedPrimary: {
      padding: '4px 12px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '16px',
      borderColor: colors.semantic.info[500],
      color: '#145977',
    },
  },
};

export const MuiPaginationItem = {
  styleOverrides: {
    root: {
      color: colors.neutral[300],
      '&.MuiButtonBase-root': {
        border: `1px solid ${colors.primary[500]}`,
        borderRadius: '4px',
        color: colors.primary[500],
        '&:focus': {
          backgroundColor: colors.neutral.white,
          boxShadow: '0px 0px 0px 3px rgba(0, 0, 219, 0.2)',
        },
        '&.Mui-selected': {
          background: colors.neutral[300],
          border: `1px solid ${colors.neutral[300]}`,
          color: colors.neutral.white,
        },
      },
      '&.MuiPaginationItem-previousNext': {
        border: 0,
        color: colors.primary[500],
        '& > svg': { fontSize: '2rem' },
      },
      '&.MuiPaginationItem-firstLast': {
        border: 0,
        color: colors.primary[500],
        '& > svg': { fontSize: '2rem' },
      },
    },
  },
};

export const MuiTabs = {
  styleOverrides: {
    indicator: {
      backgroundColor: '#1063FF', // Animation for MUI tab select
      borderBottom: '4px solid #1063FF',
    },
  },
};

export const MuiTab = {
  styleOverrides: {
    root: {
      ...typography.body2,
      color: '#1063FF',

      '&.MuiButtonBase-root': {
        textTransform: 'none',
      },
      '&.Mui-selected': {
        color: 'black',
        // borderBottom: '4px solid #1063FF',
      },
    },
  },
};

export const MuiLinearProgress = {
  styleOverrides: {
    root: {
      height: '8px',
      borderRadius: '4px',
    },
    bar: {
      backgroundColor: colors.primary[500],
    },
  },
};

export const MuiPickersPopper = {
  styleOverrides: {
    root: {
      '& .MuiPaper-elevation': {
        boxShadow:
          '0px 4px 8px rgba(27, 29, 34, 0.05), 0px 8px 8px rgba(27, 29, 34, 0.02), 0px 16px 16px rgba(27, 29, 34, 0.04), 0px 32px 32px rgba(27, 29, 34, 0.02), 0px 48px 60px rgba(27, 29, 34, 0.02)',
      },
      '& .MuiDialogActions-root': {
        margin: 16,
      },
    },
  },
};

export const MuiPickersLayout = {
  styleOverrides: {
    root: {
      '& .MuiPickersLayout-root': {
        margin: '5px',
      },
    },
  },
};

export const MuiDateCalendar = {
  styleOverrides: {
    root: {
      padding: '4px',
      margin: 0,
      width: '100%',
      '& .MuiDayCalendar-header': {
        padding: 0,
        margin: 0,
        marginBottom: '14px',
        justifyContent: 'space-between',
      },
      '& .MuiDayCalendar-weekNumberLabel': {
        width: '46px',
        height: '32px',
        padding: 0,
        margin: 0,
        color: '#04044B',
        fontSize: '14px',
        fontWeight: 700,
        justifyContent: 'flex-start',
      },
      '& .MuiPickersLayout-root': {
        marginTop: '5px',
        marginBottom: '5px',
      },
      '& .MuiDayCalendar-slideTransition': {
        minHeight: '180px',
        overflow: 'visible',
      },
      '& .MuiDayCalendar-monthContainer': {
        padding: 0,
        margin: 0,
        overflow: 'visible',
      },
      '& .MuiDayCalendar-weekDayLabel': {
        width: '48px',
        height: '20px',
        padding: 0,
        margin: 0,
        color: colorsv2.grey.default,
        ...TypographyV2['bodyM'],
      },
      '& .MuiDayCalendar-weekContainer': {
        padding: 0,
        margin: 0,
        marginBottom: '4px',
        justifyContent: 'space-between',
      },
      '& .MuiDayCalendar-weekNumber': {
        width: '46px',
        height: '32px',
        padding: 0,
        margin: 0,
        color: '#04044b',
        fontSize: '12px',
        fontWeight: 700,
        justifyContent: 'flex-start',
      },
      '&& .MuiDateCalendar-root': {
        padding: '6px',
        gap: '10px',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: colorsv2.grey.light,
        backgroundColor: colorsv2.core.white,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
        width: '416px',
        height: '272px',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.12px',
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        display: 'none',
      },
      '& .MuiPickersDay-root': {
        width: '32px',
        height: '32px',
        padding: 0,
        margin: 0,
        ...TypographyV2['bodyM-bold'],
        borderRadius: '0px',
        '&.Mui-selected': {
          backgroundColor: colorsv2.blue.default,
          color: colorsv2.core.white,
          borderRadius: '100%',
          '&:hover, &:focus': {
            backgroundColor: colorsv2.blue.default,
          },
        },
        '&.MuiPickersDay-dayOutsideMonth': {
          color: colors.neutral[300],
        },
        '&.MuiPickersDay-today': {
          borderColor: colorsv2.blue.default,
          borderWidth: '2px',
          borderRadius: '100%',
        },
      },
    },
  },
};

export const MuiPickersCalendarHeader = {
  styleOverrides: {
    root: {
      padding: 0,
      margin: 0,
      position: 'relative',
      marginBottom: '20px',
      '& .MuiPickersCalendarHeader-labelContainer': {
        zIndex: 1,
        position: 'absolute',
        height: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        overflow: 'visible',
      },
      '& .MuiPickersCalendarHeader-label': {
        ...TypographyV2['bodyL-bold'],
        letterSpacing: '-0.16px',
        fontSize: '15px',
      },
      '& .MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewIcon': {
        display: 'none',
      },
      '& .MuiPickersArrowSwitcher-root': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .MuiPickersArrowSwitcher-button': {
        padding: '6px',
        gap: '10px',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: colorsv2.grey.light,
        backgroundColor: colorsv2.core.white,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
      },
    },
  },
};

export const MuiAutocomplete = {
  variants: [
    {
      props: { size: 'large' as const },
      style: {
        '& .MuiInputBase-root': {
          minHeight: '48px',
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        padding: 0,
        height: 'auto',
      },
      '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
        padding: '8.5px 14px',
      },
    },
  },
};

export const MuiStep = {
  styleOverrides: {
    root: {
      '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
        color: '#f2f3ff',
        width: '30px',
        height: '30px',
        border: '4px solid transparent',
        borderRadius: '50%',
      },
      '& .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-active': {
        color: '#04044B',
        border: '4px solid #F2F3FF',
      },
      '& .MuiStepIcon-text': {
        ...typography.caption1,
        fill: '#000000',
      },
      '& .Mui-active .MuiStepIcon-text': {
        ...typography.caption1,
        fill: '#F2F3FF',
      },
      '& .MuiSvgIcon-root.Mui-completed': {
        color: '#04044B',
        border: '4px solid #F2F3FF',
      },
      '& .MuiStepConnector-root': {
        top: '15px',
      },
      '& .MuiStepConnector-line': {
        borderColor: '#efeffc',
      },
    },
  },
};
export const MuiAccordion = {
  styleOverrides: {
    root: {
      margin: '0px 0px',
      '& .Mui-expanded': {
        margin: '0px 0px',
      },
    },
  },
};
