import React, { FC } from 'react';

type Props = {
  fill?: string;
};

const FigmaFileIcon: FC<Props> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M18 6.66675V17.4944C18 17.9585 17.6293 18.3334 17.1722 18.3334H3.82783C3.37079 18.3334 3 17.9634 3 17.5069V2.49325C3 2.04617 3.37392 1.66675 3.83518 1.66675H12.9973L18 6.66675ZM16.3333 7.50008H12.1667V3.33341H4.66667V16.6667H16.3333V7.50008Z"
        fill={fill}
      />
    </svg>
  );
};

FigmaFileIcon.defaultProps = {
  fill: 'black',
};

export default FigmaFileIcon;
