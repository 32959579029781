import { FormControl, InputLabel, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import React, { forwardRef, useId } from 'react';

export type TextFieldProps = MuiTextFieldProps;

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ label, fullWidth, InputLabelProps, ...props }, ref) => {
    const id = useId();

    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <InputLabel shrink htmlFor={id} {...InputLabelProps}>
            {label}
          </InputLabel>
        )}
        <MuiTextField id={id} ref={ref} fullWidth={fullWidth} {...props} />
      </FormControl>
    );
  },
);

TextField.displayName = 'TextField';