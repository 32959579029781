import React, { FC, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '@/assets/logo.svg';
import { ReactComponent as Background } from '@/assets/bg.svg';
import { ReactComponent as BeryllsLogo } from '@/assets/berylls-logo.svg';
import { TypographyV2 } from '@/themes/typographyV2';
import { getLinearInterpolation } from '@/utils/utils';

import * as S from './SignInLayout.styles';

interface SignInLayoutProps {
  children?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  illustration?: FC<SVGProps<SVGSVGElement>>;
  hideSecondColumn?: boolean;
}

export const SignInLayout = ({ children, hideSecondColumn = false }: SignInLayoutProps) => {
  const { t } = useTranslation('SignIn');
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [area, setArea] = useState(window.innerWidth * window.innerHeight);

  const splitRef = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setArea(window.innerWidth * window.innerHeight);

      // console.log('width: ', window.innerWidth);
      // console.log('height: ', window.innerHeight);
      // console.log('aspect ratio: ', window.innerWidth / window.innerHeight);
      // console.log('area: ', window.innerWidth * window.innerHeight);

      const splitElement = splitRef.current;

      if (splitElement) {
        const hasScroll = splitElement.scrollHeight > window.innerHeight;
        // console.log('splitElement.scrollHeight: ', splitElement.scrollHeight);
        // console.log('height: ', height);
        setHasScrollbar(hasScroll);
        // console.log('hasScroll: ', hasScroll);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const minArea = 768 * 565;
  const maxArea = 1920 * 919;

  const getTitlePadding = () => {
    const minPadding = 10;
    const maxPadding = 130;

    return getLinearInterpolation(area, minArea, maxArea, minPadding, maxPadding);
  };

  const getTitleSize = () => {
    const minSize = 24;
    const maxSize = 64;

    return getLinearInterpolation(area, minArea, maxArea, minSize, maxSize);
  };

  const getContainerRightWidth = () => {
    const minLeftContainerWidth = 642.5;

    if (width > minLeftContainerWidth * 2) {
      return width / 2;
    }

    return width - minLeftContainerWidth;
  };

  const minWindowHeight = 300;
  const maxWindowHeight = 919;

  const getBottomHeight = () => {
    const minBottomHeight = 95;
    const maxBottomHeight = 690;

    return getLinearInterpolation(height, minWindowHeight, maxWindowHeight, minBottomHeight, maxBottomHeight);
  };

  const getBackgroundColumnWidth = () => {
    const minWindowWidth = 1024;
    const maxWindowWidth = 1920;

    const minBackgroundWidth = 228;
    const maxBackgroundWidth = 800;

    const getWidthByHeight = getLinearInterpolation(
      height,
      minWindowHeight,
      maxWindowHeight,
      minBackgroundWidth,
      maxBackgroundWidth,
    );
    const getWidthByWidth = getLinearInterpolation(
      width,
      minWindowWidth,
      maxWindowWidth,
      minBackgroundWidth,
      maxBackgroundWidth,
    );

    return Math.min(getWidthByHeight, getWidthByWidth);
  };

  return (
    // <S.Wrapper>
    //   <S.Column>
    //     <S.Container>
    // <S.LogoWrapper>
    //   <S.LogoColumn>
    //     <Logo />
    //   </S.LogoColumn>
    // </S.LogoWrapper>
    // <S.ContentWrapper>{children}</S.ContentWrapper>
    //     </S.Container>
    //   </S.Column>
    //   {!hideSecondColumn && <S.Column withBackground />}
    // </S.Wrapper>
    <S.Split ref={splitRef}>
      <S.ContainerLeft>
        <S.Container>
          {/* <S.LogoWrapper>
            <S.LogoColumn>
            </S.LogoColumn>
          </S.LogoWrapper> */}
          <S.ContentWrapper>
            <S.ElyvateContainer>
              <S.ElyvateWrapper>
                <Logo />
              </S.ElyvateWrapper>
            </S.ElyvateContainer>
            {children}
          </S.ContentWrapper>
        </S.Container>
      </S.ContainerLeft>

      <S.ContainerRight style={{ width: getContainerRightWidth(), right: hasScrollbar ? '17px' : 0 }}>
        <S.Copy>
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Degular, Inter',
              fontSize: `${getTitleSize()}px`,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: `${getTitleSize()}px`,
              paddingInline: `${getTitlePadding()}px`,
              zIndex: 3,
            }}
          >
            {t('logo.title')}
          </Typography>
          <Typography
            sx={{
              ...TypographyV2['body-s-normal'],
              color: '#FFF',
              textAlign: 'center',
              fontFeatureSettings: 'salt on',
              // fontFamily: 'Inter',
              // fontSize: '24px',
              // fontStyle: 'normal',
              // fontWeight: 400,
              // lineHeight: 'normal',
              zIndex: 3,
            }}
          >
            {t('logo.subtitle')}
          </Typography>
        </S.Copy>

        <S.Bottom
          style={{
            // width: hasScrollbar ? 'calc(50% - 8.5px)' : '50%',
            width: '100%',
            height: '100%',
            // height: `${getBottomHeight()}px`,
            // backgroundColor: 'green',
          }}
        >
          <S.BeryllsContainer>
            <BeryllsLogo />
          </S.BeryllsContainer>
          <S.BackgroundColumn
            style={{
              width: `${getBackgroundColumnWidth()}px`,
            }}
          >
            <S.BackgroundRow>
              <Background style={{ objectFit: 'none', width: '100%', height: '100%', zIndex: 4 }} />
            </S.BackgroundRow>
          </S.BackgroundColumn>
        </S.Bottom>
      </S.ContainerRight>
    </S.Split>
  );
};
