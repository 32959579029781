export const TypographyV2 = {
  fontFamily: ['"Inter"', '"Faktum"', 'sans-serif'].join(','),
  fontSize: 16,
  h1: {
    fontFamily: 'Inter',
    fontSize: '56px',
    fontStyle: 'Normal',
    fontWeight: 700,
    lineHeight: '64px',
    letterSpacing: '-0.56px',
  },
  h2: {
    fontFamily: 'Inter',
    fontSize: '40px',
    fontStyle: 'Normal',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: '-0.4px',
  },
  h3: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontStyle: 'Normal',
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: '-0.32px',
  },
  h4: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'Normal',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '-0.24px',
  },
  h5: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'Normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  h6: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'Normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.18px',
  },

  bodyXl: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'Normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4',
  },
  'body-s-normal': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'Normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.14px',
  },

  'bodyL-Soft-bold': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'Normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
  },
  'bodyL-medium-bold': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'Normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
  },
  'bodyL-bold': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'Normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
  },
  'bodyM-bold': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'Normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  bodyM: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'Normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },
  'bodyS-bold': {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.12px',
  },
  bodyS: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'Normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.24px',
  },
  'bodyXS-bold': {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'Normal',
    fontWeight: 700,
    lineHeight: '16px',
  },
  bodyXS: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'Normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  'bodyXS-Caps': {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'Normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    textTransform: 'uppercase' as const, // Fix: Ensure it's of type 'TextTransform'
  },
};
